import Axios, { Canceler } from 'axios'
import { Button, ModalDialog } from '../UI'

//Standard libraries
import React, { useEffect } from 'react'

import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../redux/actions/user'
//UI
//Custom libraries
import getDisplayName from 'react-display-name'
import personContactService from '../../foundation/apis/transaction/personContact.service'
import { sendSuccessMessage } from '../../features/success/slice'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
//Foundation libraries
import { useTranslation } from 'next-i18next'
import { getSite } from '@foundation/hooks/useSite'
import styles from './styles/RemoveAddressDialog.module.scss'

const RemoveAddressDialogLayout: React.FC<{
  handleClose: () => void
  userAddress
}> = ({ handleClose, userAddress }) => {
  const { isDesktop } = useBreakpoints()

  const widgetName = getDisplayName(RemoveAddressDialogLayout)
  const [removeAddressDialog, setRemoveAddressDialogState] = React.useState<boolean>(true)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const mySite = getSite()
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payload: any = {
    widget: widgetName,
    storeId: mySite?.storeID,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const deleteAddress = (nickName: string | undefined) => {
    const parameters: any = {
      nickName: nickName,
      ...payload,
    }
    personContactService.deletePersonContact(parameters).then(() => {
      dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION(payload))
      handleClose()

      dispatch(
        sendSuccessMessage({
          key: 'success-message.DELETE_ADDRESS_SUCCESS',
          messageParameters: {
            '0': nickName,
          },
        })
      )
    })
  }

  const handleRemoveAddressDialogState = () => setRemoveAddressDialogState(false)

  useEffect(() => {
    if (removeAddressDialog) handleRemoveAddressDialogState()
  }, [])

  useEffect(() => {
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [])

  return (
    <ModalDialog
      open={true}
      onClose={handleClose}
      onCloseButton={isDesktop ? handleClose : undefined}
      widthSize={'sm'}
      roundBorders
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <div className={styles.headingContainer}>{t('RemoveAddressDialog.Title')}</div>
        <div className={styles.buttonContainer}>
          <Button onClick={() => deleteAddress(userAddress.nickName)}>{t('RemoveAddressDialog.YesRemove')}</Button>

          <Button filltype={'outline'} onClick={handleClose}>
            {t('RemoveAddressDialog.NoClose')}
          </Button>
        </div>
      </>
    </ModalDialog>
  )
}

export { RemoveAddressDialogLayout }
