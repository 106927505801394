import React, { useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CheckoutName } from '../../../../../constants/paymentMethods'
import { setCreditCardNumber } from '../../../../../features/order/slice'
import { useStoredPaymentDetails } from '../../../../../hooks/useStoredPaymentDetails'
import PaymentMethodsService from '../../../../../services/PaymentMethodsService'
import { CreditCardFormDataType } from '@typesApp/checkout'
import { Wallet } from '@typesApp/user'
import PaymentMethod from './PaymentMethod'
import { StoredCreditCard } from './StoredCCard/StoredCCard'
import { userDetailsSelector } from '@redux/selectors/user'

export interface WalletPaymentMethodsProps {
  selectedPayment: string
  setSelectedPayment: (id: string) => void
  paymentProcessing: boolean
  onSubmit: (formData: CreditCardFormDataType | null, isUsingWallet?: boolean) => void
  togglePayOption: (paymentMethodId: string, walletId?: string) => void
}

export const WalletPaymentMethods: React.FC<WalletPaymentMethodsProps> = ({
  selectedPayment,
  setSelectedPayment,
  onSubmit,
  togglePayOption,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const wallet = useStoredPaymentDetails(WalletPaymentMethods)
  const userDetails = useSelector(userDetailsSelector)
  const contactList = userDetails?.contact || []
  const cardHasAddress = creditCard => contactList.find(cl => cl.addressId == creditCard['addressId'])

  useEffect(() => {
    if (wallet && wallet.length) {
      const defaultPaymentOption = wallet.find(walletItem => walletItem.isDefault)

      if (defaultPaymentOption) {
        togglePayOption(defaultPaymentOption.payMethodId, defaultPaymentOption?.identifier.toString())
        setSelectedPayment(defaultPaymentOption?.identifier.toString() || '')
      }
    }
  }, [wallet])

  const handleSubmit = (wallet: Wallet) => {
    togglePayOption(wallet.payMethodId, wallet.identifier.toString())
    const { creditCardBin, protectedCCNumber } = wallet
    dispatch(setCreditCardNumber(`${creditCardBin}${protectedCCNumber}`))
    onSubmit &&
      onSubmit(
        {
          walletId: '' + wallet.identifier,
        },
        true
      )
  }

  return (
    <>
      {wallet
        .filter(payment => !!cardHasAddress(payment))
        .map(paymentItem => {
          const currentItemId = paymentItem.identifier.toString()
          const cardIcon = PaymentMethodsService.getIconByChekoutName(paymentItem?.payMethodId as CheckoutName)
          return (
            <PaymentMethod
              key={currentItemId}
              paymentMethodName={paymentItem.payMethodId as CheckoutName}
              paymentDescription={t('PaymentMethodSelection.Labels.SavedCreditCard')}
              expanded={currentItemId === selectedPayment}
              onPaymentMethodSelected={() => {
                togglePayOption(paymentItem.payMethodId)
                setSelectedPayment(currentItemId)
              }}
              content={<StoredCreditCard key={currentItemId} cardDetails={paymentItem} onSubmit={handleSubmit} />}
              paymentIcon={cardIcon}
              storedCardDetails={paymentItem}
            />
          )
        })}
    </>
  )
}
