import { Button, StyledCheckbox, StyledFormControlLabel } from '../../../../../components/UI'

import styled from '@mui/material/styles/styled'

export const ShippingFooterWrapper = styled('div', {
  name: 'ShippingFooter',
  slot: 'Wrapper',
})(({ theme }) => ({
  margin: `${theme.spacing(6)} 0`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}))

export const ShippingFooterContentButton = styled('div', {
  name: 'ShippingFooter',
  slot: 'ContentButton',
})(() => ({}))

export const StyledShippingFooterFormControlLabel = styled(
  StyledFormControlLabel,
  {
    name: 'ShippingFooter',
    slot: 'FormControlLabel',
  }
)(() => ({
  alignItems: 'flex-start',
  a: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}))

export const ShippingFooterCheckbox = styled(StyledCheckbox, {
  name: 'Shipping',
  slot: 'footerCheckbox'
})(({}) => ({
  paddingLeft: 0
}))

export const ShippingNextCTA = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: `0 ${theme.spacing(11)}`,
  backgroundColor: theme.palette.custom.light1.green,
  borderColor: theme.palette.custom.green,
  marginLeft: 0,
  '& svg ': {
    position: 'absolute',
    left: '20px',
  },
}))
