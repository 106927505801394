import {
  doneRemovingCartItem,
  doneUndoRemoveCartItem,
  lastRemovedCartItemSelector,
  startRemovingCartItem,
  startUndoRemoveCartItem,
} from '@features/cartui/cartuiSlice'
import {
  useLazyGetCartQuery,
  useLazyGetOneClickReorderQuery,
  useRemoveOrderItemMutation,
  useUpdateOrderItemMutation,
} from '@features/order/query'
import { catentriesSelector, orderItemsSelector, orderSelector } from '@features/order/selector'
import { useSite } from '@foundation/hooks/useSite'
import Log from '@services/Log'
import { OrderItem, OrderUnavailableItem } from '@typesApp/order'
import { IProduct, IProductUnavailableRaw } from '@typesApp/product'
import {
  computeUpdateQuantityOnGroupedOrderItems,
  getOrderItemContactLensesData,
  getParsedOrderRecapItems,
  isClOrderItem,
  isValidOrderItemsInReorder,
} from '@utils/order'
import { CartRecap } from '@views/Cart/components/CartRecap/CartRecap'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReorder } from '../hooks/useReorder'
import { isItemSubscribed, getSubscribedItems } from '@views/Subscription/helpers/subscriptionHelpers'
import { useRouter } from 'next/navigation'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { CART } from '@constants/routes'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { REORDER_OLD_ORDER_ID, REORDER_ORDER_ID } from '@foundation/constants/common'
import { fetchOrderItemDetailsByIds } from '../../../redux/sagas/workers/orderDetails'
import { getPartNumberQuantities, sendOrderSummaryData } from '../../../foundation/analytics/tealium/lib'
import { useAnalyticsData, ANALYTICS_PAGE_TYPE } from '@foundation/hooks/useAnalyticsData'
import { userEmailSelector } from '../../../redux/selectors/user'
import { getCatEntriesAlgoliaPrices } from '@foundation/algolia/algoliaPrice'
import { values } from 'lodash-es'
import { CartInterceptListener } from '../../../components/DirectBilling'
import { CommerceEnvironment } from '../../../constants/common'
import { addItem, addLens } from '@features/order/thunks'
import addContactLenses, { ContactLensesAddItemArgs } from '@features/order/thunks/addContactLenses'
import { RemovedItemNotificationContainer } from '@views/Cart/Cart.style'
import RemovedItemNotification from '@views/Cart/components/RemovedItemNotification'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

/**
 * Reorder summary section
 * Displays products for reorder
 * @param props
 */
const ReorderSummary: React.FC<{
  setReorderBusy?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setReorderBusy }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { mySite } = useSite()
  const { langCode, langId } = useStoreIdentity()
  const { reorderInfo } = useReorder(setReorderBusy)
  const [isOrderSummaryDataSent, setIsOrderSummaryDataSent] = useState(false)
  const reorderOrderId = localStorageUtil.get(REORDER_ORDER_ID)
  const reorderOldOrderId = localStorageUtil.get(REORDER_OLD_ORDER_ID)
  const analyticsDataForReorderSummary = useAnalyticsData(ANALYTICS_PAGE_TYPE.ORDER_SUMMARY)
  const email = useSelector(userEmailSelector)
  const order = useSelector(orderSelector)
  const catEntries = useSelector(catentriesSelector)
  const customerSegment = useCustomerSegmentsUtil()
  const algoliaPrices = getCatEntriesAlgoliaPrices(customerSegment, catEntries)
  const [getOneClickReorderQuery] = useLazyGetOneClickReorderQuery()
  const orderItems = useSelector(orderItemsSelector)
  const [unavailableProductItems, setUnavailableProductItems] = useState<OrderUnavailableItem[]>([])
  const hasSubscribedItemsInCart = !!getSubscribedItems(orderItems).length
  const lastRemoved = useSelector(lastRemovedCartItemSelector)

  const subscribedOrderItem = orderItems?.find(orderItem => isItemSubscribed(orderItem))
  if (subscribedOrderItem) {
    // Cart with items that have subscription activated cannot be checked-out using reorder summary page, yet!
    router.replace(`/${langCode}/${CART}`)
  }

  const [getCart, { isFetching: cartUpdating }] = useLazyGetCartQuery()
  const [updateOrderItem] = useUpdateOrderItemMutation()
  const [removeOrderItem] = useRemoveOrderItemMutation()

  const [orderItemIdToUpdate, setOrderItemIdToUpdate] = useState<string>('')
  const [quantityUpdatedOrder, setQuantityUpdatedOrder] = useState<string>('')

  const parsedOrderItems = useMemo<OrderItem[] | null>(
    () => (orderItems ? getParsedOrderRecapItems(orderItems, orderItemIdToUpdate, quantityUpdatedOrder) : null),
    [orderItems]
  )

  useEffect(() => {
    if (reorderInfo) {
      const unavailableProducts = reorderInfo?.unavailableSKUs ?? []
      if (unavailableProducts.length > 0) {
        const ids = [...new Set(unavailableProducts.map(uSKU => uSKU.productId))]
        fetchOrderItemDetailsByIds({ id: ids })
          .then((unavailableProductsRaw: IProductUnavailableRaw[]) => {
            const completeUnavailableProductItems: OrderUnavailableItem[] = []
            unavailableProducts.forEach(unavailableProduct => {
              const unavailableProductRaw: IProductUnavailableRaw | undefined = unavailableProductsRaw.find(
                item => item.id === unavailableProduct.productId
              )
              if (!!unavailableProductRaw) {
                completeUnavailableProductItems.push({ ...unavailableProduct, ...unavailableProductRaw })
              }
            })
            setUnavailableProductItems(completeUnavailableProductItems)
          })
          .catch(error => {
            Log.error('Error fetching unavailable product details: ' + error)
          })
      }
    }
  }, [reorderInfo])

  useEffect(() => {
    if (reorderInfo) {
      const unavailableProducts = reorderInfo?.unavailableSKUs ?? []
      if (orderItems?.length === 0 && unavailableProducts?.length === 0 && !lastRemoved) {
        router.replace(`/${langCode}/${CART}`)
      }
    }
  }, [reorderInfo, orderItems, lastRemoved, router, langCode])

  useEffect(() => {
    !reorderInfo && reorderOrderId && getOneClickReorderQuery({ orderId: reorderOrderId })
    !reorderInfo &&
      !reorderOrderId &&
      reorderOldOrderId &&
      getOneClickReorderQuery({ orderId: reorderOldOrderId, isOldOrderId: true })
      dispatch(doneRemovingCartItem(undefined))
  }, [])

  useEffect(() => {
    if (!!order && order.orderDetails && !isOrderSummaryDataSent) {
      const partNumberQuantities = getPartNumberQuantities(parsedOrderItems || [])
      const products = values(catEntries) as IProduct[]

      sendOrderSummaryData({
        common: analyticsDataForReorderSummary,
        products,
        email,
        order,
        partNumberQuantities,
        algoliaPrices,
      })

      setIsOrderSummaryDataSent(true)
    }
  }, [order])

  const removeItem = (item: OrderItem) => {
    setReorderBusy && setReorderBusy(true)
    dispatch(startRemovingCartItem(item.orderItemId))
    removeOrderItem({
      orderItem: item,
    })
      .then(() => {
        getCart({ storeId: mySite.storeID }).finally(() => {
          dispatch(doneRemovingCartItem(item))
        })
      })
      .catch(error => {
        Log.error('Error removing order item from cart: ' + error)
        dispatch(doneRemovingCartItem())
      })
      .finally(() => setReorderBusy && setReorderBusy(false))
  }

  const updateItem = useCallback(
    (quantity: string, orderItemId: string) => {
      const computedQuantity = computeUpdateQuantityOnGroupedOrderItems({ parsedOrderItems, orderItemId, quantity })

      setReorderBusy && setReorderBusy(true)
      updateOrderItem({
        storeId: mySite.storeID,
        body: {
          orderItem: [
            {
              quantity: computedQuantity,
              orderItemId: orderItemId,
            },
          ],
        },
      })
        .then(() => getCart({ storeId: mySite.storeID }))
        .finally(() => setReorderBusy && setReorderBusy(false))
    },
    [orderItemIdToUpdate, parsedOrderItems]
  )

  const afterUndo = () => {
    getCart({ storeId: mySite.storeID }).finally(() => dispatch(doneUndoRemoveCartItem()))
  }

  const onUndo = async () => {
    if (!lastRemoved) return

    dispatch(startUndoRemoveCartItem())

    if (lastRemoved.roxableServices?.length) {
      const params = {
        quantity: ['1'],
        contractId: lastRemoved.contractId,
        langId,
        catentryId: lastRemoved.roxableServices[0].productId,
        partnumber: lastRemoved.partNumber,
        product: lastRemoved,
      }
      await dispatch(addLens({ params, siteInfo: mySite }))
      afterUndo()
    } else if (isClOrderItem(lastRemoved.orderItemExtendAttribute)) {
      const orderItemClData = getOrderItemContactLensesData(lastRemoved)
      const clPayload: ContactLensesAddItemArgs['items'] = orderItemClData
        ? Object.keys(orderItemClData)
            .filter(eye => {
              return !!orderItemClData[eye].data
            })
            .map(eye => {
              const { data, quantity } = orderItemClData[eye] ?? { data: undefined, quantity: undefined }
              const eyeData =
                data && Object.keys(data ?? {}).length > 0
                  ? {
                      x_contactLens: { ...data },
                      quantity: quantity ?? 0,
                    }
                  : {}
              return eyeData
            })
        : null
      const payload = {
        items: clPayload,
        langId: langId,
      }
      await dispatch(addContactLenses(payload))
      afterUndo()
    } else {
      const params = {
        quantity: ['1'],
        partnumber: lastRemoved.partNumber,
        contractId: lastRemoved.contractId,
        langId,
      }
      //TODO we should pass add item logic to RTK
      await dispatch(addItem(params))
      afterUndo()
    }
  }

  const isDirectBillingEnabled =
    !!CommerceEnvironment.directInsuranceBilling[mySite.locale?.toLowerCase()]?.enabled ?? false

  return (
    <>
      {isValidOrderItemsInReorder(reorderInfo, orderItems) && (
        <CartRecap
          cartUpdating={cartUpdating}
          cartItems={parsedOrderItems}
          onRemove={item => {
            removeItem(item)
          }}
          onUpdate={async (quantity, orderItemId) => {
            setOrderItemIdToUpdate(orderItemId)
            setQuantityUpdatedOrder(quantity)
            updateItem(quantity, orderItemId)
          }}
        />
      )}
      {reorderInfo?.unavailableSKUs &&
        reorderInfo?.unavailableSKUs.length > 0 &&
        unavailableProductItems?.length > 0 && (
          <CartRecap
            cartUpdating={cartUpdating}
            unavailableItems={unavailableProductItems}
            cartItems={null}
            onRemove={item => {
              removeItem(item)
            }}
            onUpdate={async (quantity, orderItemId) => {
              setOrderItemIdToUpdate(orderItemId)
              setQuantityUpdatedOrder(quantity)
              updateItem(quantity, orderItemId)
            }}
          />
        )}
      {lastRemoved && (
        <RemovedItemNotificationContainer>
          <RemovedItemNotification product={lastRemoved} onUndo={onUndo} />
        </RemovedItemNotificationContainer>
      )}

      {!hasSubscribedItemsInCart && isDirectBillingEnabled && <CartInterceptListener />}
    </>
  )
}

export default ReorderSummary
