import React, { FC } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import {
  ShippingFooterCheckbox,
  ShippingFooterContentButton,
  ShippingFooterWrapper,
  ShippingNextCTA,
  StyledShippingFooterFormControlLabel,
} from './ShippingFooter.style'
import { PreLoader, StyledAnchor } from '../../../../../components/UI'
import { storeCfgServiceLinksSelector } from '../../../../../redux/selectors/site'
import { useDispatch, useSelector } from 'react-redux'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { LookOutNotice } from '../../../components/LookOutNotice'
import { isNewsletterSubscriptionRequestedSelector } from '@features/checkout/selector'
import { setIsNewsletterSubscriptionRequested } from '@features/checkout/slice'

export interface ShippingFooterProps {
  hasNewsletterSubscriptionCheckmark: boolean
  isProceedButtonDisabled: boolean
  shippingLoading: boolean
  isValidAddressSelected: boolean
  isAddressNotEligible?: boolean
  onButtonClick(): void
}

const ShippingFooter: FC<ShippingFooterProps> = ({
  hasNewsletterSubscriptionCheckmark,
  shippingLoading,
  isValidAddressSelected,
  onButtonClick,
  isProceedButtonDisabled,
  isAddressNotEligible = true,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const serviceLinksString = useSelector(storeCfgServiceLinksSelector)
  const serviceLinks = serviceLinksString ?? null
  const { langCode } = useStoreIdentity()
  const linkToPrivacyPage = `/${langCode}${serviceLinks?.privacy}`
  const linkToTermsOfUse = `/${langCode}${serviceLinks?.terms}`
  const isSubscriptionRequested = useSelector(isNewsletterSubscriptionRequestedSelector)

  return (
    <ShippingFooterWrapper>
      {hasNewsletterSubscriptionCheckmark && (
        <StyledShippingFooterFormControlLabel
          control={
            <ShippingFooterCheckbox
              value={isSubscriptionRequested}
              checked={isSubscriptionRequested}
              onChange={() => dispatch(setIsNewsletterSubscriptionRequested(!isSubscriptionRequested))}
            />
          }
          label={
            <Trans i18nKey="Shipping.Actions.ReceiveOffers">
              <StyledAnchor href={linkToPrivacyPage} />
              <StyledAnchor href={linkToTermsOfUse} />
            </Trans>
          }
          style={{ margin: 0 }}
        />
      )}

      {isAddressNotEligible && <LookOutNotice page={'shipping'} />}

      <ShippingFooterContentButton>
        <ShippingNextCTA
          className="shipping-footer__button"
          data-element-id="X_X_Cart_ProceedToPayment"
          data-testid="shipping-can-continue"
          loading={shippingLoading}
          type="button"
          variant="primary"
          onClick={onButtonClick}
          disabled={isProceedButtonDisabled || shippingLoading || !isValidAddressSelected}
        >
          {shippingLoading && <PreLoader withButton fill={'light'} />}
          {t('Shipping.Actions.Next')}
        </ShippingNextCTA>
      </ShippingFooterContentButton>
    </ShippingFooterWrapper>
  )
}

export default ShippingFooter
