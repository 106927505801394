import {
  CartRecapBottom,
  CartRecapBrand,
  CartRecapContentContainer,
  CartRecapCutLabel,
  CartRecapData,
  CartRecapDataContent,
  CartRecapDataRow,
  CartRecapDataRowLabel,
  CartRecapDataRowPrice,
  CartRecapDataRowProductModel,
  CartRecapImageContainer,
  CartRecapItemWrapper,
  CartRecapModelName,
  CartRecapModelPriceDataRow,
  CartRecapRightColumn,
  CartRecapTop,
  CartRecapTopLeft,
  CartRecapTopRight,
  CartRecapTotalDataRowWrapper,
  ContactLensPricePerBoxContainer,
  ContactLensQuantitySelectContainer,
  ContactLensQuantitySelectLabel,
  ContactLensQuantitySelectWrapper,
  SoldOutMessage,
  StyledCartRecapDivider,
  StyledRemoveButtonAsLink,
  StyledRemoveButtonContainer,
  ViewDetailsRowLabel,
} from './CartRecap.style'

import React, { FC, ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getBrand,
  getCLBrand,
  getFrontColor,
  getLensesColor,
  getModelName,
  getNormalizedProductType,
  getSize,
  getModelCode,
  getIsRoxable,
} from '../../../../utils/productAttributes'
import { isAccessories, isCLAccessories, isContactLenses, isOptical, isSun } from '../../../../utils/product'

import CountryBaseAnchor from '../../../../components/CountryBaseAnchor'
import FormattedPriceDisplay from '../../../../components/formatted-price-display'
import { MenuItem } from '@mui/material'
import ProductImage from '../../../../components/ProductImage/ProductImage'
import { EyeContanctLensOption, ProductSoldOutStatus, IProduct } from '@typesApp/product'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { Trans, useTranslation } from 'next-i18next'
import { OrderItem } from '@typesApp/order'
import { PreLoader, StyledAnchor, TextField } from '../../../../components/UI'
import { removeCartItemIdSelector, removingCartItemSelector } from '../../../../features/cartui/cartuiSlice'
import { PRODUCT_SOLDOUT_STATUS } from '../../../../constants/product'
import { ProductPriceAlgolia } from '@views/ProductDetails/components/ProductPriceAlgolia'
import { AlgoliaPrice, determineAlgoliaPrice, getProductPrice } from '@foundation/algolia/algoliaPrice'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
export interface ProductSummaryProps {
  bottomChildren?: ReactNode
  alignBottomChildren?: 'start' | 'center' | 'end'
  product: IProduct
  productQuantity?: string[]
  soldOutStatus: ProductSoldOutStatus
  topChildren?: ReactNode
  hideProductTotal?: boolean
  isWishListPage?: boolean
  orderItem?: OrderItem
  totalBoxes?: number
  quantityOptions?: EyeContanctLensOption[]
  onItemUpdated?: (quantity: string, orderItemId: string) => void
  onRemoveLabelClick(): void
  isDisabledRemoveClick?: boolean
  imageWidth?: number
}

const ProductSummary: FC<ProductSummaryProps> = ({
  bottomChildren,
  alignBottomChildren,
  product,
  productQuantity,
  topChildren,
  hideProductTotal,
  isWishListPage,
  orderItem,
  totalBoxes,
  quantityOptions,
  onItemUpdated,
  onRemoveLabelClick: onRemoveLabelClickProp,
  isDisabledRemoveClick,
  imageWidth = 600,
  soldOutStatus,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const { isDesktop } = useBreakpoints()
  const productBrandName = getBrand(product) || 'BRAND NAME'
  const productClBrandName = getCLBrand(product) || 'BRAND NAME'
  const productModelName = getModelName(product)
  const productModelCode = getModelCode(product)
  const productLensColor = getLensesColor(product)
  const productFrontColor = getFrontColor(product)
  const productBaseModelSize = getSize(product, t)
  const productType = getNormalizedProductType(product)
  const siteName = process.env.NEXT_PUBLIC_STORENAME
  const isRemovingCartItem = useSelector(removingCartItemSelector)
  const removeOrderItemId = useSelector(removeCartItemIdSelector)
  const isRemovingCurrentItem = isRemovingCartItem && removeOrderItemId === orderItem?.orderItemId
  const isSoldout = soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT
  const isComingBackSoon = soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON
  const isRoxable = useMemo(() => product && getIsRoxable(product), [product])
  const customerSegments = useCustomerSegmentsUtil()

  const onRemoveLabelClick = () => {
    if (isDisabledRemoveClick) return
    onRemoveLabelClickProp()
  }

  //TODO check algoliaPrice logic and updatePrice logic
  const algoliaPrice = product?.x_price
    ? determineAlgoliaPrice(product?.x_price, customerSegments, productType, isRoxable)
    : {
        listPrice: product?.x_price?.[0].listPrice || '',
        offerPrice: product?.x_price?.[0].offerPrice || '',
        percentageDiscount: 0,
        startDate: '',
        endDate: '',
        precedence: 0,
        priceListPrecedence: 0,
      }

  const { initialPrice, offerPrice } = getProductPrice(algoliaPrice as AlgoliaPrice)

  return (
    <CartRecapItemWrapper>
      {topChildren}

      <CartRecapContentContainer>
        <CartRecapImageContainer>
          {isMobile && (
            <StyledRemoveButtonContainer>
              <StyledRemoveButtonAsLink
                data-element-id="X_X_Prods_Remove"
                disabled={isDisabledRemoveClick || isRemovingCurrentItem}
                onClick={onRemoveLabelClick}
              >
                {isRemovingCurrentItem && <PreLoader fill={'dark'} withButton />}
                {t('CartRecap.Actions.Delete')}
              </StyledRemoveButtonAsLink>
            </StyledRemoveButtonContainer>
          )}
          {(isWishListPage && isSoldout && (
            <ProductImage
              attachments={product.attachments}
              data-element-id="X_X_Prods_NoLink"
              width={imageWidth}
              usage={'PDP'}
              partialProduct={product as IProduct}
            />
          )) || (
            <CountryBaseAnchor
              href={product.seo?.href || ''}
              variant="wrapper"
              data-description={`${siteName}_${productModelName}_${productModelCode}`}
              data-element-id="X_X_Prods_ProdLink"
            >
              <ProductImage
                attachments={product.attachments}
                data-element-id="X_X_Prods_ProdLink"
                width={imageWidth}
                usage={'PDP'}
                partialProduct={product as IProduct}
              />
            </CountryBaseAnchor>
          )}
        </CartRecapImageContainer>
        <CartRecapRightColumn isClOrCla={isCLAccessories(productType) || isContactLenses(productType)}>
          <CartRecapData>
            {!isMobile && (
              <CartRecapBottom>
                <StyledRemoveButtonAsLink
                  data-element-id="X_X_Prods_Remove"
                  disabled={isDisabledRemoveClick || isRemovingCurrentItem}
                  onClick={onRemoveLabelClick}
                >
                  {isRemovingCurrentItem && <PreLoader fill={'dark'} withButton />}
                  {t('CartRecap.Actions.Delete')}
                </StyledRemoveButtonAsLink>
              </CartRecapBottom>
            )}

            {!isCLAccessories(productType) ? (
              <CartRecapTop>
                <CartRecapTopLeft>
                  <CartRecapBrand>{productBrandName}</CartRecapBrand>
                  <CartRecapModelPriceDataRow>
                    <CartRecapDataRowProductModel>{productModelName}</CartRecapDataRowProductModel>
                  </CartRecapModelPriceDataRow>
                </CartRecapTopLeft>
                <CartRecapTopRight>
                  <CartRecapDataRowPrice>
                    {product && (
                      <ProductPriceAlgolia adjustments={orderItem?.adjustment ?? []} soldOutStatus={soldOutStatus} />
                    )}
                  </CartRecapDataRowPrice>
                </CartRecapTopRight>
              </CartRecapTop>
            ) : (
              <CartRecapTop>
                {' '}
                {!isMobile && <CartRecapBrand>{productModelName}</CartRecapBrand>}
                <CartRecapModelName>{productClBrandName}</CartRecapModelName>{' '}
              </CartRecapTop>
            )}

            <CartRecapDataContent isClAccessories={isCLAccessories(productType)}>
              <>
                {!isAccessories(productType) && !isCLAccessories(productType) && (
                  <>
                    <CartRecapDataRow>
                      <CartRecapDataRowLabel>
                        <span>{t('CartRecap.Labels.Frame')}:</span>
                        <span>{productFrontColor}</span>
                      </CartRecapDataRowLabel>
                    </CartRecapDataRow>
                    <CartRecapDataRow>
                      <CartRecapDataRowLabel>
                        <span>{t('CartRecap.Labels.Size')}:</span>
                        <span>{productBaseModelSize}</span>
                      </CartRecapDataRowLabel>
                    </CartRecapDataRow>
                  </>
                )}

                {isSun(productType) && (
                  <CartRecapDataRow>
                    <CartRecapDataRowLabel>
                      <span>{t('CartRecap.Labels.Lenses')}:</span>
                      <span>{productLensColor}</span>
                    </CartRecapDataRowLabel>
                  </CartRecapDataRow>
                )}
                {isWishListPage && !isSoldout && (
                  <CartRecapDataRow>
                    <ViewDetailsRowLabel>
                      <Trans i18nKey="CartRecap.Links.ViewDetails">
                        {{ productLink: t('CartRecap.Actions.ViewDetails') }}
                        <StyledAnchor href={`${product.seo?.href}`} />
                      </Trans>
                    </ViewDetailsRowLabel>
                  </CartRecapDataRow>
                )}
              </>

              <StyledCartRecapDivider />

              {isCLAccessories(productType) && (
                <ContactLensQuantitySelectContainer>
                  <ContactLensQuantitySelectWrapper>
                    <ContactLensQuantitySelectLabel>
                      {t('CartRecap.Labels.Title_Boxes')}{' '}
                    </ContactLensQuantitySelectLabel>
                    <TextField
                      defaultValue={totalBoxes}
                      style={{ height: 33, flex: '0 1 100px' }}
                      select
                      onChange={e => {
                        onItemUpdated && onItemUpdated(e.target.value || '', orderItem?.orderItemId || '')
                      }}
                    >
                      {quantityOptions &&
                        quantityOptions?.map(q => (
                          <MenuItem key={q.value} value={q.value || ''}>
                            {q.text}
                          </MenuItem>
                        ))}
                    </TextField>
                  </ContactLensQuantitySelectWrapper>

                  <ContactLensPricePerBoxContainer>
                    <span>{isDesktop ? t('CartRecap.Labels.PerBox') : t('CartRecap.Labels.PricePerBox')}</span>
                    <>
                      <div>
                        {initialPrice && (
                          <CartRecapCutLabel withDiscount={offerPrice !== undefined}>
                            <FormattedPriceDisplay min={initialPrice} currency={orderItem?.currency} />
                          </CartRecapCutLabel>
                        )}
                        {offerPrice && <FormattedPriceDisplay min={offerPrice} currency={orderItem?.currency} />}
                      </div>
                    </>
                  </ContactLensPricePerBoxContainer>
                </ContactLensQuantitySelectContainer>
              )}

              {(isContactLenses(productType) || (isCLAccessories(productType) && isMobile)) && (
                <StyledCartRecapDivider />
              )}

              {!hideProductTotal && (
                <CartRecapTotalDataRowWrapper isContactLenses={isContactLenses(productType)}>
                  {isSun(productType) || isOptical(productType) ? (
                    <ProductPriceAlgolia
                      isCompact
                      soldOutStatus={soldOutStatus}
                      isTotal
                      productQuantity={productQuantity}
                      totalBoxes={totalBoxes}
                      adjustments={orderItem?.adjustment ?? []}
                    />
                  ) : (
                    !hideProductTotal && (
                      <ProductPriceAlgolia
                        isCompact
                        soldOutStatus={soldOutStatus}
                        productQuantity={productQuantity}
                        totalBoxes={totalBoxes}
                        adjustments={orderItem?.adjustment ?? []}
                      />
                    )
                  )}
                </CartRecapTotalDataRowWrapper>
              )}
              {(isWishListPage && (isSoldout || isComingBackSoon) && (
                <SoldOutMessage>
                  {isSoldout ? t('SoldOut.SoldOutMessage') : t('ComingBackSoon.ComingBackSoonText')}
                </SoldOutMessage>
              )) || (
                <CartRecapDataRow style={{ display: 'flex', justifyContent: alignBottomChildren || 'center' }}>
                  {bottomChildren}
                </CartRecapDataRow>
              )}
            </CartRecapDataContent>
          </CartRecapData>
        </CartRecapRightColumn>
      </CartRecapContentContainer>
    </CartRecapItemWrapper>
  )
}

export default ProductSummary
