import styled from '@mui/material/styles/styled'
import { StyledFormControlLabel, StyledRadio, StyledTypography } from '../../../../../components/UI'

export const AddressSelectionContainer = styled('div', {
  name: 'AddressSelection',
  slot: 'container',
  shouldForwardProp: prop => prop !== 'addressChanging',
})<{
  addressChanging?: boolean
}>(({ addressChanging }) => ({
  pointerEvents: addressChanging ? 'none' : 'initial',
}))

export const AddressSelectionLabel = styled(StyledFormControlLabel, {
  name: 'AddressSelection',
  slot: 'label',
  shouldForwardProp: prop => prop !== 'checked',
})<{ checked: boolean }>(({ theme, checked }) => ({
  position: 'relative',
  border: checked ? `1px solid ${theme.palette.custom.blue}` : `1px solid ${theme.palette.custom.light2.grey}`,
  padding: `${theme.spacing(4)} !important`,
  margin: `0 0 ${theme.spacing(10)} 0`,
  backgroundColor: theme.palette.background.light.primary,
}))

export const AddressSelectionNameAndSurname = styled(StyledTypography, {
  name: 'AddressSelection',
  slot: 'nameAndSurname',
})(() => ({
  fontWeight: 'bold',
}))

export const AddressSelectionRadioButton = styled(StyledRadio, {
  name: 'AddressSelection',
  slot: 'radioButton',
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>(({ theme, hidden }) => ({
  paddingBottom: theme.spacing(7.5),
  display: hidden ? 'none' : '',
}))

export const AddressSelectionNewWrapper = styled(StyledFormControlLabel, {
  name: 'AddressSelection',
  slot: 'newWrapper',
})(({ theme }) => ({
  margin: `0 0 ${theme.spacing(1.5)} 0`,
}))

export const AddressSelectionNewLabel = styled(StyledTypography, {
  name: 'AddressSelection',
  label: 'newLabel',
})(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.dark.primary,
  fontSize: 12,
  textDecoration: 'underline',
}))

export const AddressSelectionWrapper = styled('div', {
  name: 'AddressSelection',
  slot: 'wrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const AddressSelectionEditLinkButton = styled('div', {
  name: 'AddressSelection',
  slot: 'editLinkButton',
})(({ theme }) => ({
  cursor: 'pointer',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'bold',
  color: theme.palette.custom.blue,
  textDecoration: 'underline',
  position: 'absolute',
  top: `${theme.spacing(4)}`,
  right: `${theme.spacing(4)}`,
  '&.disabled ': {
    color: theme.palette.custom.grey,
  },
}))

export const AddressSelectionError = styled('div', {
  name: 'AddressSelection',
  slot: 'error',
})(({ theme }) => ({
  color: theme.palette.text.dark.senary,
  fontSize: `${theme.spacing(3)}`,
  textWrap: 'balance',
  marginRight: `${theme.spacing(12)}`,
}))
