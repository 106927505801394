import { Icon, PAYMENT_METHODS } from '../constants/paymentMethods'
import * as PaymentMethod from '../constants/paymentMethods'
import Log from '../services/Log'
import { IOrderDetails } from '../types/order'
const { CHECKOUT_NAMES, ICONS, FOOTER_NAMES, CHECKOUT_CC_NAMES } = PAYMENT_METHODS

type PaymentMethodName = PaymentMethod.CheckoutName | PaymentMethod.FooterName

/**
 * Check weather ApplePay is enabled for the used device
 */

export const canUseApplePay = () => {
  try {
    return !!window.ApplePaySession?.canMakePayments()
  } catch (e) {
    return false
  }
}

export const getIcon = (
  NAMES: typeof CHECKOUT_NAMES | typeof FOOTER_NAMES,
  methodName: PaymentMethodName
): PaymentMethod.Icon | null => {
  try {
    let paymentMethodKey: PaymentMethodName | null = null

    for (const key in NAMES) {
      if (methodName === NAMES[key]) {
        paymentMethodKey = key as PaymentMethodName
        break
      }
    }

    return paymentMethodKey ? ICONS[paymentMethodKey] : null
  } catch (e: any) {
    Log.error(`Could not get payment icon: ${e}`)
    return null
  }
}

export const getIconByChekoutName = (checkoutName: PaymentMethod.CheckoutName): Icon | null => {
  try {
    return getIcon(CHECKOUT_NAMES, checkoutName)
  } catch (e: any) {
    Log.error(`Could not get payment icon: ${e}`)
    return null
  }
}

export const getIconByFooterName = (footerName: PaymentMethod.FooterName): PaymentMethod.Icon | null => {
  try {
    return getIcon(FOOTER_NAMES, footerName)
  } catch (e: any) {
    Log.error(`Could not get payment icon: ${e}`)
    return null
  }
}

export const isCCardOrder = (payMethodId: PaymentMethod.CheckoutName | null): boolean => {
  try {
    return (payMethodId && CHECKOUT_CC_NAMES.includes(payMethodId)) || false
  } catch (e) {
    return false
  }
}

export const isPaypalExpressOrder = (orderDetails: IOrderDetails | null): boolean => {
  try {
    return (
      !!orderDetails?.paymentInstruction.find(pi => !!pi)?.protocolData.find(el => el.name === 'isPayPalExpress')
        ?.value || false
    )
  } catch (e) {
    return false
  }
}

export const isKlarnaOrder = (payMethodId: PaymentMethod.CheckoutName | null): boolean => {
  try {
    return payMethodId === CHECKOUT_NAMES.KLARNA
  } catch (e) {
    return false
  }
}

export const isPaypalOrder = (payMethodId: PaymentMethod.CheckoutName | null): boolean => {
  try {
    return payMethodId === CHECKOUT_NAMES.PAYPAL
  } catch (e) {
    return false
  }
}

export const isApplePayOrder = (payMethodId: PaymentMethod.CheckoutName | null): boolean => {
  try {
    return payMethodId === CHECKOUT_NAMES.APPLE_PAY
  } catch (e) {
    return false
  }
}

export const getOrderPayMethodId = (orderDetails?: IOrderDetails) => {
  if (!orderDetails) return ''
  const paymentIstruction = orderDetails.paymentInstruction && orderDetails.paymentInstruction[0]
  const orderPaymentMethodId = paymentIstruction?.payMethodId
  return orderPaymentMethodId
}

export const getPaymentMethodByCheckoutName = (
  checkoutPaymentMethodName: PaymentMethod.CheckoutName
): PaymentMethod.FooterName | null => {
  switch (checkoutPaymentMethodName) {
    case CHECKOUT_NAMES.AMEX:
      return FOOTER_NAMES.AMEX
    case CHECKOUT_NAMES.APPLE_PAY:
      return FOOTER_NAMES.APPLE_PAY
    case CHECKOUT_NAMES.MASTERCARD:
      return FOOTER_NAMES.MASTERCARD
    case CHECKOUT_NAMES.PAYPAL:
      return FOOTER_NAMES.PAYPAL
    case CHECKOUT_NAMES.VISA:
      return FOOTER_NAMES.VISA
    default:
      return null
  }
}
