import { useContext, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { cloneDeep, first, isEmpty } from 'lodash-es'
import { useSelector } from 'react-redux'

import config from '@configs/index'
import { PRODUCT_TYPES_MAP } from '@constants/product'
import { setLastSelectedProduct } from '@features/ui/action'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useAppDispatch } from '@hooks/redux'
import useBreakpoints from '@hooks/useBreakpoints'
import { plpBadgesSelector } from '@redux/selectors/site'
// utils
import { isAccessories, isCLAccessories, isContactLenses } from '@utils/product'
import { getProductImageAltLabel } from '@utils/productImage'
import { getBadges, getBrand, getCLBrand, getModelCode, getModelName, getProductType } from '@utils/productAttributes'
import { getDataElementIdByModule } from './helpers'
// components
import { Anchor } from '@components/UI-CSS/Anchor'
import ProductImage, {
  PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE,
  PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
  ProductImageProps,
} from '@components/ProductImage/ProductImage'
import { ProductBadges } from '@components/features/ProductBadges'
import { ProductPriceAlgolia } from '@views/ProductDetails/components/ProductPriceAlgolia'
import { ContextWrapperData, PlacementContext } from '../../PlacementContextWrapper/PlacementContextWrapper'
// types
import { IProduct, ProductImageUsage, isProduct } from '@typesApp/product'
import { isCMExternalProduct } from '@typesApp/teaser'
import { PlacementContextType } from '../../PlacementContextWrapper/types/PlacementContextWrapper.types'
// styles
import { CSSModule } from '@styles/types'
import styles from './styles/index.module.scss'

interface CmsCarouselProductTileProps {
  isClustered?: boolean
  isPDP?: boolean
  onClick?: () => void
  product: object
  styleOverride?: CSSModule
  tileIndex: number
  variant?: string
  hidePriceFromLabel?: boolean
  verticalPriceLayout?: boolean
  alignPriceCenter?: boolean
}

// TODO: refactor to use product context
const CmsCarouselProductTile = (props: CmsCarouselProductTileProps) => {
  const {
    isClustered,
    isPDP,
    onClick,
    product,
    styleOverride,
    tileIndex,
    variant,
    hidePriceFromLabel,
    verticalPriceLayout,
    alignPriceCenter,
  } = props

  const baseProduct: IProduct = useMemo(() => {
    if (isCMExternalProduct(product)) return product.productData
    if (isProduct(product)) return product
    return {} as IProduct
  }, [product])

  const productUrl = isCMExternalProduct(product)
    ? product.formattedUrl
    : isProduct(product)
      ? product.seo?.href || ''
      : ''

  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data: contextData } = context as ContextWrapperData<PlacementContextType>
  const placement = contextData?.placement
  const viewType = 'items' in placement ? placement.items?.[0]?.viewtype : ''
  const siteName = config.name

  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { isRXEnabled } = useStoreIdentity()
  const { isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440 } = useBreakpoints()
  const badgeSelector = useSelector(plpBadgesSelector)

  const clusters = useMemo(
    () =>
      cloneDeep<IProduct[]>(
        (baseProduct?.cluster ?? (isClustered ? baseProduct?.cluster : baseProduct?.cluster?.slice(0, 1))) || []
      ),
    [baseProduct?.cluster, isClustered]
  )

  const selectedViewCluster = useMemo(() => (clusters?.length ? clusters[0] : baseProduct), [clusters, baseProduct])

  const imgSequenceProps = {
    sequence: PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
    usage: 'PLP' as ProductImageUsage,
  }
  const imgHoverSequenceProps = {
    sequence: PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE,
    usage: 'PLP' as ProductImageUsage,
  }

  const [hoverImage, setHoverImage] = useState<ProductImageProps>(imgSequenceProps)
  const productType =
    (!isEmpty(baseProduct) && PRODUCT_TYPES_MAP[getProductType(baseProduct).toLowerCase()]) || 'frames'

  const clusterLength = clusters?.length ?? 0
  const name = getModelName(baseProduct)
  const brand = getBrand(baseProduct)
  const clBrand = getCLBrand(baseProduct)
  const modelCode = getModelCode(baseProduct)

  const productImageWidth = useMemo<number>(() => {
    const isCMS = variant === 'cms-products-module'
    switch (true) {
      case isCMS:
        return 400
      case isViewportWidthUnder426:
        return 213
      case isMobile:
        return 296
      case isTablet:
      case isViewportWidthAbove1440:
      case isDesktop:
        return 600
      default:
        return 260
    }
  }, [isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440, variant])

  const productImageProps = useMemo(
    () => ({
      alt: getProductImageAltLabel(baseProduct, true),
      draggable: false,
      sequence: PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
      usage: 'PLP' as ProductImageUsage,
      width: productImageWidth,
      onClick: () => {
        dispatch(setLastSelectedProduct(baseProduct?.id || ''))
      },
    }),
    [baseProduct, dispatch, productImageWidth]
  )

  const badgeList = useMemo(() => {
    let currentSku: IProduct | undefined = { ...baseProduct }
    if (clusterLength) {
      const items = selectedViewCluster?.sKUs != null ? selectedViewCluster?.sKUs : selectedViewCluster?.items
      if (items) {
        currentSku = first(items)
      } else {
        currentSku = baseProduct
      }
    }

    if (!currentSku) {
      return []
    }

    const badges = getBadges(currentSku, translate, badgeSelector, isRXEnabled)
    return [badges.primaryBadge, badges.secondaryBadges]
  }, [badgeSelector, baseProduct, clusterLength, isRXEnabled, selectedViewCluster, translate])

  const [primaryBadge, secondaryBadge] = badgeList

  const ariaLabel = !isEmpty(primaryBadge) ? primaryBadge : `${name}_${modelCode}`
  const tileDataElementId = getDataElementIdByModule({ tileIndex, viewType })
  const tileDataDescription = `${siteName}_${name}_${modelCode}`

  const shouldZoomOnHover = isContactLenses(productType) || isCLAccessories(productType) || isAccessories(productType)

  const onProductTileMouseEnter = () => {
    if (isViewportWidthUnder426 || isMobile || isTablet) return
    if (selectedViewCluster && !shouldZoomOnHover) {
      setHoverImage(imgHoverSequenceProps)
    }
  }

  const onProductTileMouseLeave = () => {
    if (isViewportWidthUnder426 || isMobile || isTablet) return
    if (selectedViewCluster) {
      setHoverImage(imgSequenceProps)
    }
  }

  if (isEmpty(baseProduct) || product == null) return null

  return (
    <div
      className={clsx(styles.wrapper, styleOverride && styleOverride.wrapper)}
      onMouseEnter={onProductTileMouseEnter}
      onMouseLeave={onProductTileMouseLeave}
      aria-label={ariaLabel}
      data-description={tileDataDescription}
      data-element-id={tileDataElementId}
    >
      <div className={clsx(styleOverride && styleOverride.subwrapper, styles.subwrapper)} onClick={onClick}>
        <ProductBadges
          primaryBadge={primaryBadge}
          secondaryBadges={secondaryBadge}
          styleOverride={{ ...styles, ...(styleOverride ?? {}) }}
        />

        <div className={clsx(styleOverride && styleOverride.imageContainer, styles.imageContainer)}>
          <Anchor
            aria-label={ariaLabel}
            className={styles.imageAnchor}
            data-description={tileDataDescription}
            data-element-id={tileDataElementId}
            href={productUrl}
          >
            <div
              className={clsx(styleOverride && styleOverride.imageWrapper, styles.imageWrapper, {
                [styles.imageZoomOnHover]: shouldZoomOnHover,
              })}
            >
              <ProductImage attachments={selectedViewCluster.attachments} {...productImageProps} {...hoverImage} />
            </div>
          </Anchor>
        </div>
      </div>
      <div
        className={clsx(styleOverride && styleOverride.footerWrapper, styles.footerWrapper)}
        product-type={productType}
      >
        <Anchor
          aria-label={ariaLabel}
          className={styles.imageAnchor}
          data-description={tileDataDescription}
          data-element-id={tileDataElementId}
          href={productUrl}
        >
          <div className={clsx(styleOverride && styleOverride.footer, styles.footer)}>
            {!isContactLenses(productType) && !isCLAccessories(productType) ? (
              <div className={clsx(styleOverride && styleOverride.description, styles.description, !!isPDP && 'isPDP')}>
                <>
                  <div
                    className={clsx(styleOverride && styleOverride.productName, styles.productName)}
                    product-type={productType}
                  >
                    {name ? name : '\u00A0'}
                  </div>
                  <div className={clsx(styleOverride && styleOverride.brandName, styles.brandName)}>{brand}</div>
                </>
                <ProductPriceAlgolia
                  isCompact
                  isVerticalLayout={!!isMobile && !verticalPriceLayout}
                  ignorePageType
                  hideFromLabel={hidePriceFromLabel}
                  alignCenter={alignPriceCenter}
                />
              </div>
            ) : (
              <div className={clsx(styleOverride && styleOverride.description, styles.description, !!isPDP && 'isPDP')}>
                <div
                  className={clsx(styleOverride && styleOverride.productName, styles.productName)}
                  product-type={productType}
                >
                  {clBrand}
                </div>

                <div className={clsx(styleOverride && styleOverride.brandName, styles.brandName)}>
                  {name ? name : '\u00A0'}
                </div>

                {selectedViewCluster && 'price' in selectedViewCluster && (
                  <ProductPriceAlgolia
                    isCompact
                    isVerticalLayout={!!isMobile && !verticalPriceLayout}
                    ignorePageType
                    alignCenter={alignPriceCenter}
                  />
                )}
              </div>
            )}
          </div>
        </Anchor>
      </div>
    </div>
  )
}

CmsCarouselProductTile.defaultProps = {
  isClustered: true,
}

export { CmsCarouselProductTile }
