import { EARLY_ACCESS_PROMO, PROMO_URL_PARAM } from '@constants/common'
import {
  useLazyGetCampaignAccessQuery,
  useGetCustomerSegmentsQuery,
  useLazyGetPersonalizationIdQuery,
} from '@features/customerSegments/query'
import { PERSONALIZATION_ID } from '@foundation/constants/user'
import { useSite } from '@foundation/hooks/useSite'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { userDetailsResponseStatusSelector } from '@redux/selectors/user'
import { isEarlyAccesFeatureEnabled } from '@utils/common'
import { useCookies } from '@utils/Cookies'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// NOTE: to read 'userg' cookie value set by requests made from this hook, use `useCustomerSegmentsUtil`
export function useCustomerSegment() {
  const searchParams = useSearchParams()
  const { mySite } = useSite()
  const isEarlyAccessEnabled = isEarlyAccesFeatureEnabled()
  const isUserDetailsResponseStatusOk = useSelector(userDetailsResponseStatusSelector)
  const promo = searchParams.get(PROMO_URL_PARAM) ?? ''

  const [personalizationId, setPersonalizationId] = useState(localStorageUtil.get(PERSONALIZATION_ID) ?? '')

  const [triggerGetPersonalizationId, { data: personalization }] = useLazyGetPersonalizationIdQuery()

  useGetCustomerSegmentsQuery(
    {
      personalizationId,
      storeId: mySite.storeId,
    },
    { skip: !personalizationId || !mySite?.storeId || (!isEarlyAccessEnabled && isUserDetailsResponseStatusOk) }
  )

  const [triggerGetCampaignAccess] = useLazyGetCampaignAccessQuery()
  const { refetchCookies } = useCookies()

  useEffect(() => {
    if (!personalizationId)
      triggerGetPersonalizationId({
        storeId: mySite.storeId,
      })
  }, [mySite.storeId, personalizationId, triggerGetPersonalizationId])

  useEffect(() => {
    if (personalization) {
      setPersonalizationId(personalization.personalizationID)
      localStorageUtil.set(PERSONALIZATION_ID, personalization.personalizationID)
    }
  }, [personalization])

  useEffect(() => {
    if (isEarlyAccessEnabled && promo === EARLY_ACCESS_PROMO && personalizationId) {
      triggerGetCampaignAccess({
        personalizationId,
        storeId: mySite.storeId,
        campaignName: EARLY_ACCESS_PROMO,
      }).unwrap()
      .finally(() => refetchCookies())
    }
  }, [isEarlyAccessEnabled, promo, personalizationId, triggerGetCampaignAccess, mySite.storeId])
}
