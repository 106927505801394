import React, { useState } from 'react'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useTheme } from '@mui/material/styles'
import { useCheckoutPaths } from '@foundation/hooks/useCheckoutPaths'
import OrderRecapCartEditConfirmDialog from '@components/order-recap/OrderRecapEditBag'
import { ReducedHeaderMobileBack } from './reducedheader.style'
import { Link } from '@components/common/Link/Link'
import { useRouter } from 'next/router'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import * as ROUTES from '../../constants/routes'

export const CheckoutMobileBack: React.FC = () => {
  const { basePath, langCode } = useStoreIdentity()
  const router = useRouter()
  const checkoutPaths = useCheckoutPaths(langCode)
  const isShippingPage = router.asPath.includes(ROUTES.CHECKOUT_SHIPPING)
  const isPaymentPage = router.asPath.includes(ROUTES.CHECKOUT_PAYMENT)
  const theme = useTheme()

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const handleOpen = (): void => setIsEditOpen(true)
  const handleClose = (): void => setIsEditOpen(false)

  return (
    <>
      <ReducedHeaderMobileBack>
        <SVGIcon
          library="arrow"
          name="arrow-left"
          color={theme.palette.text.dark.primary}
          onClick={!isShippingPage && !isPaymentPage ? undefined : handleOpen}
        />
      </ReducedHeaderMobileBack>
      <OrderRecapCartEditConfirmDialog route={ROUTES.CART} open={isEditOpen} handleClose={handleClose} />
    </>
  )
}
