import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV3 } from '../../../../constants/ui'
import { ContactLensesSelectProps } from './ContactLensesSelect'

export const ContactLensesSelectWrapper = styled('div', {
  name: 'ContactLensesSelect',
  slot: 'Wrapper',
})(() => ({
  display: 'grid',
  gridTemplateColumns: '8fr 9fr 9fr',
}))

export const DropDownContainer = styled('div', {
  name: 'MultiFieldsDropDown',
  slot: 'Container',
  shouldForwardProp: props => props !== 'open' && props !== 'error' && props !== 'disabled',
})<{
  open: boolean
  error: boolean
  disabled: boolean
}>(({ open, error, disabled, theme }) => ({
  position: 'relative',
  fontSize: '0.75rem',
  border: !!error ? `1px solid ${theme.palette.color.error}` : `1px solid ${theme.palette.custom.boulder}`,
  borderRadius: '4px',
  borderBottomLeftRadius: open ? '0' : '4px',
  borderBottomRightRadius: open ? '0' : '4px',
  opacity: disabled ? '0.5' : '1',
  height: '33px',
}))

export const ContactLensesSelectValueField = styled('button', {
  name: 'ContactLensesSelect',
  slot: 'ValueField',
  shouldForwardProp: props => props !== 'disabled',
})<{ disabled: boolean }>(({ disabled }) => ({
  cursor: disabled ? 'default' : 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  height: '100%',
  width: '100%',
  minWidth: '100px',
  textAlign: 'left',
  fontSize: 14,
  padding: '1px 6px',
}))

export const DropDownPopper = styled('div', {
  name: 'MultiFieldsDropDown',
  slot: 'Popper',
  shouldForwardProp: props => props !== 'open',
})<{ open: ContactLensesSelectProps['open'] }>(({ theme, open }) => ({
  zIndex: Z_INDEX_LV3,
  top: '33px',
  right: '-1px',
  position: 'absolute',
  width: 'calc(100% + 2px)',
  display: open ? 'block' : 'none',
  borderBottom: `1px solid ${theme.palette.background.light.primary}`,
  borderRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  boxShadow: 'rgba(96, 97, 112, 0.16) 0px 5.9px 11.8px 0px, rgba(40, 41, 61, 0.04) 0px 0px 2.9px 0px',
}))

export const MultiDropDownContent = styled('ul', {
  name: 'MultiFieldsDropDown',
  slot: 'Content',
  shouldForwardProp: props => props !== 'multifield',
})<{ multifield: ContactLensesSelectProps['multifield'] }>(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  display: 'flex',
  position: 'relative',
  width: '100%',
  flexWrap: 'wrap',
  margin: '0',
  padding: '0',
  textAlign: 'center',
  maxHeight: '200px',
  height: '100%',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.background.light.quaternary} ${theme.palette.background.light.tertiary}`,
  '&::-webkit-scrollbar': {
    width: '4px',
    backgroundColor: theme.palette.background.light.tertiary,
  },
  '&::-webkit-scrollbar-thumb': {
    height: '50%',
    backgroundColor: theme.palette.background.light.quaternary,
    borderRadius: '50vmax',
  },
}))

export const DropDownListItem = styled('li', {
  name: 'MultiFieldsDropDown',
  slot: 'ListItem',
  shouldForwardProp: props => props === 'onClick' || props === 'children',
})<{
  multifield: ContactLensesSelectProps['multifield']
  onClick?: (e: React.MouseEvent<HTMLLIElement>, val: string | null) => void
}>(({ theme, multifield }) => ({
  '&:nth-of-type(odd) span': {
    borderRight: multifield ? `1px solid ${theme.palette.background.light.tertiary}` : 'none',
  },
  flex: multifield ? '1 0 50%' : '1 0 100%',
  width: multifield ? '50%' : '100%',
  listStyle: 'none',

  cursor: 'pointer',
}))

export const DropDownListItemButton = styled('span', {
  name: 'MultiFieldsDropDown',
  slot: 'ListItemButton',
  shouldForwardProp: props => props !== 'notAvailable',
})<{
  notAvailable?: boolean
}>(({ theme, notAvailable }) => ({
  color: notAvailable ? theme.palette.text.light.secondary : theme.palette.text.dark.primary,
  display: 'block',
  padding: '4px',
  ':hover': {
    cursor: notAvailable ? 'not-allowed' : 'inherit',
  },
}))
