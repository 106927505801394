import React from 'react'
import { StyledTypography } from '../../UI'
import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV1 } from '../../../constants/ui'
import {
  TeaserBackGround,
  TeaserOverlayStyle,
  TOverlaySettings,
  TOverlayTextAlign,
} from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import {
  bannerOverlayStyleToColor,
  setMargin,
  setMarginMobile,
  teaserOverlaySettingsToCSS,
  teaserOverlaySettingsToCSSMobile,
  teaserOverlayStyleToColor,
} from '../../../utils/placements'
import { boardsCtaInternalFlex } from '../../../utils/boardsWithFields'
import { Link } from '@components/common/Link/Link'

export const StyledTextModuleWrapper = styled('div', {
  name: 'TextModule',
  slot: 'Wrapper',
  shouldForwardProp: propName =>
    propName !== 'istextoverlay' &&
    propName !== 'termsconditionvisible' &&
    propName !== 'overlaytxtalign' &&
    propName !== 'overlaysettings' &&
    propName !== 'teaseroverlaystyle' &&
    propName !== 'teaserbackground' &&
    propName !== 'iscountdownvisible' &&
    propName !== 'viewtype',
})<{
  istextoverlay?: 1 | 0
  termsconditionvisible?: 1 | 0
  overlaytxtalign?: TOverlayTextAlign
  overlaysettings?: TOverlaySettings
  teaseroverlaystyle?: TeaserOverlayStyle
  teaserbackground?: TeaserBackGround
  iscountdownvisible?: boolean
  viewtype?: IViewType
}>(({ overlaysettings, istextoverlay, teaseroverlaystyle, viewtype, theme }) => ({
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  width: '100%',
  position: istextoverlay === 1 ? 'absolute' : 'static',
  zIndex: istextoverlay === 1 ? Z_INDEX_LV1 : 'unset',
  padding: `0 ${theme.spacing(4)}`,
  flexDirection: 'row',
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(8, 0),
  },
  [theme.breakpoints.up(769)]: {
    ...teaserOverlaySettingsToCSS(
      istextoverlay === 1 ? overlaysettings || '' : '',
      istextoverlay,
      '0',
      '50%',
      '0 32px',
      viewtype
    ),
  },
  [theme.breakpoints.down(769)]: {
    ...bannerOverlayStyleToColor(theme, viewtype, teaseroverlaystyle),
    ...teaserOverlaySettingsToCSSMobile(
      viewtype !== 'squat-banner' && istextoverlay === 1 ? overlaysettings || '' : '',
      viewtype === 'squat-banner' ? 0 : istextoverlay,
      '18%',
      '36%',
      '64px'
    ),
    top: viewtype === 'squat-banner' ? theme.spacing(26) : 'unset',
    margin: 'unset',
    padding: `0 ${theme.spacing(4)}`,
    width: '100%',
  },
}))

export const StyledTextModuleFullWidthWrapper = styled(StyledTextModuleWrapper, {
  name: 'TextModule',
  slot: 'FullWidthWrapper',
  shouldForwardProp: propName => propName !== 'termsconditionvisible',
})(({ theme, termsconditionvisible }) => ({
  height: 'fit-content',
  padding: `0 ${theme.spacing(11)} ${theme.spacing(termsconditionvisible === 0 ? 8 : 12)} ${theme.spacing(11)}`,
  bottom: 0,
  [theme.breakpoints.up(769)]: {
    padding: '0',
  },
  [theme.breakpoints.down(769)]: {
    padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  },
}))

export const StyledTextModuleFullWidthBannerWrapper = styled(StyledTextModuleFullWidthWrapper, {
  name: 'TextModule',
  slot: 'FullWidthBannerWrapper',
  shouldForwardProp: propName => propName !== 'iscountdownvisible',
})(({ theme, iscountdownvisible }) => ({
  height: theme.spacing(27),
  zIndex: '2',
  pointerEvents: 'none',
  [theme.breakpoints.up(0)]: {
    transform: `translate(0, -${iscountdownvisible ? theme.spacing(38) : theme.spacing(33)})`,
  },
  [theme.breakpoints.up(321)]: {
    transform: `translate(0, -${iscountdownvisible ? theme.spacing(39) : theme.spacing(34)})`,
  },
  [theme.breakpoints.up(583)]: {
    transform: `translate(0, -${iscountdownvisible ? theme.spacing(36) : theme.spacing(31)})`,
  },
  [theme.breakpoints.up(769)]: {
    height: 'auto',
    transform: 'none',
  },
}))

export const StyledTextModuleContainer = styled('article', {
  name: 'TextModule',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'textModuleType',
})(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignContent: 'left',
  [theme.breakpoints.down(769)]: {
    'h1:nth-of-type(2)': {
      fontWeight: 700,
      fontSize: theme.typography.h3.fontSize,
      lineHeight: 1.25,
    },
  },
  [theme.breakpoints.down('sm')]: {
    'h1:nth-of-type(2)': {
      margin: 0,
    },
  },
  [theme.breakpoints.up(769)]: {
    flexWrap: 'wrap',
    alignContent: 'center',
    marginTop: '64',
    padding: `0 ${theme.spacing(8)}`,
    'h1:nth-of-type(2)': {
      fontWeight: 700,
      fontSize: theme.typography.h2.fontSize,
      lineHeight: 1.25,
      margin: '0 auto',
    },
  },
  h2: {
    width: '100%',
  },
}))

export const StyledTextModuleLearnMoreContainer = styled('div', {
  name: 'TextModule',
  slot: 'LearnMoreContainer',
})(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down(769)]: {
    'h1:nth-of-type(2)': {
      fontWeight: 700,
      fontSize: theme.typography.h3.fontSize,
      lineHeight: 1.25,
      margin: '0 auto',
    },
  },
  '.media-container-wrap': {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.up(769)]: {
    'h1:nth-of-type(2)': {
      fontWeight: 700,
      fontSize: theme.typography.h2.fontSize,
      lineHeight: 1.25,
      margin: '0 auto',
    },
  },
  ul: {
    listStyleImage:
      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAARCAYAAADDjbwNAAABXGlDQ1BJQ0MgUHJvZmlsZQAAKJF1kD1LQmEYhi/T0sKooSHCwSFasi8ralWDChzMPqi249E00OPhaETU0FC7EA0tEdbWXtHUDwgagoaI1n5A1FDK6TlamUXvy8NzcXNz83BDg0vR9bQDyGh5IzoZ9C4uLXudT9jx0EovXYqa0wORSFgsfO3693qHzdq3fVZW8+lA6fCtKRy93M5uuiL7f/11ryWeyKmySzKDqm7kweYTjqzndYu3hDsMOUq4YHGyyicWx6p8UfHMRUPC18LtakqJCz8I+2I/9OQPzqTX1M8brOvdCW1+1sqR8bBAAD9jDDFKHzNMMfGPf6TiD5FFZwODVZKkyOOVBF1+moTwNBoq/fiE/QzKDFs9/+6vpmWLMP4C9kJNix3A+S503te07iNo24GzG10xlO9Wba+O3Mqwv8ruIDQ+muZzDzj3oFwwzfeiaZaPJV86utI+AA7HY0vrmV2VAAAAVmVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAADkoYABwAAABIAAABEoAIABAAAAAEAAAAaoAMABAAAAAEAAAARAAAAAEFTQ0lJAAAAU2NyZWVuc2hvdCJyMuEAAAHUaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJYTVAgQ29yZSA2LjAuMCI+CiAgIDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CiAgICAgIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiCiAgICAgICAgICAgIHhtbG5zOmV4aWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vZXhpZi8xLjAvIj4KICAgICAgICAgPGV4aWY6UGl4ZWxZRGltZW5zaW9uPjE3PC9leGlmOlBpeGVsWURpbWVuc2lvbj4KICAgICAgICAgPGV4aWY6UGl4ZWxYRGltZW5zaW9uPjI2PC9leGlmOlBpeGVsWERpbWVuc2lvbj4KICAgICAgICAgPGV4aWY6VXNlckNvbW1lbnQ+U2NyZWVuc2hvdDwvZXhpZjpVc2VyQ29tbWVudD4KICAgICAgPC9yZGY6RGVzY3JpcHRpb24+CiAgIDwvcmRmOlJERj4KPC94OnhtcG1ldGE+CmuOGjoAAAEASURBVDgRY/wPBAx0AEx0sANsBdUtOn/zNYN96mqGB88/ofiBBYVHIQdkSXjZZgYeLjZMk0BxRA1w7sar/6p+c/8bRiz+f//ZRwwjGTBEyBAgZAnISAyL3n/6QZJVxFiCYRHIy0aRi/+nte4hyjJiLQEZhpLqFCT5GEx0JBm27L/NkN62FzNCkUSQI35dnx8DSC9egM3pIB9JuUzH6TNSfAIzHyOOYBK4LCPHEpCZOC0CSaJbRq4lILMYQQS+sAXFFSjO7MzkGc5eeQ7OjETFCZqhBC0CqYdZJi7Cw0COJWA7QT4iBjTNOYU1xxOjF6SGKB+hhQJZXJR8RJYJRGoCAIkXP0qPtC0kAAAAAElFTkSuQmCC')",
    li: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(8),
    },
  },
  img: {
    width: '100%',
  },
  h2: {
    width: '100%',
  },
}))

export const StyledTitle = styled(StyledTypography, {
  name: 'TextModule',
  slot: 'Title',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'external' && prop !== 'sectionTitle',
})<{
  textAlign: React.CSSProperties['textAlign']
  external?: boolean
  sectionTitle?: boolean
}>(({ theme, textAlign, external, sectionTitle }) => ({
  fontWeight: '700',
  lineHeight: theme.spacing(7.5),
  textAlign,
  [theme.breakpoints.up('md')]: {
    textAlign,
  },
  [theme.breakpoints.up(769)]: {
    fontSize: sectionTitle ? theme.typography.h3.fontSize : theme.typography.h4.fontSize,
    marginTop: external ? theme.spacing(16) : theme.spacing(2),
  },
  [theme.breakpoints.down(769)]: {
    fontSize: sectionTitle ? theme.typography.h4.fontSize : theme.typography.htmlFontSize,
    marginTop: external ? theme.spacing(10) : 0,
  },
}))

export const StyledPreTitle = styled(StyledTypography, {
  name: 'TextModule',
  slot: 'PreTitle',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'viewtype',
})<{
  textAlign: React.CSSProperties['textAlign']
  viewtype?: IViewType
}>(({ textAlign, theme, viewtype }) => ({
  fontSize: theme.typography.body1.fontSize,
  ...setMargin(theme, 'pretitle', viewtype),
  textAlign: 'center',

  [theme.breakpoints.up('md')]: {
    textAlign,
  },

  [theme.breakpoints.down(769)]: {
    marginBottom: theme.spacing(2),
  },
}))

export const StyledCTAContainer = styled('div', {
  name: 'TextModule',
  slot: 'CTAContainer',
  shouldForwardProp: prop =>
    prop !== 'overlayTextAlign' && prop !== 'viewType' && prop !== 'autoWidth' && prop !== 'noTopMargin',
})<{
  overlayTextAlign: React.CSSProperties['justifyContent']
  viewType?: IViewType
  autoWidth?: boolean
  noTopMargin?: boolean
}>(({ theme, overlayTextAlign, viewType, noTopMargin = false }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: overlayTextAlign === 'left' ? 'flex-start' : 'center',
  justifyContent: overlayTextAlign == 'left' ? 'flex-start' : 'center',
  flexDirection: 'column',
  marginTop: theme.spacing(noTopMargin ? 0 : 6),
  marginBottom: theme.spacing(4),

  '& > a': {
    height: theme.spacing(10),
    boxSizing: 'border-box',
    pointerEvents: 'auto',
    overflow: 'visible',
  },

  '& > button': {
    overflow: 'visible',
  },

  [theme.breakpoints.up(769)]: {
    ...boardsCtaInternalFlex(viewType, true, overlayTextAlign),
    '& > a': {
      width: 'auto',
    },
  },

  [theme.breakpoints.down(769)]: {
    marginTop: theme.spacing(noTopMargin ? 0 : 6),
    alignContent: 'center',
    '& > a': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > a, & > button': {
      width: '80vw',
    },
  },
  [theme.breakpoints.down(321)]: {
    marginTop: 0,
    marginBottom: 0,
    '& > a': {
      marginInline: 0,
    },
  },
}))

export const TextContainer = styled('div', {
  name: 'TextModule',
  slot: 'TextContainer',
  shouldForwardProp: prop =>
    prop !== 'textAlign' && prop !== 'viewtype' && prop !== 'external' && prop !== 'teaserBackground',
})<{
  textAlign?: React.CSSProperties['textAlign']
  external?: boolean
  teaserBackground?: string
  viewtype?: IViewType
}>(({ textAlign, theme, external, teaserBackground, viewtype }) => ({
  display: 'flex',
  justifyContent: textAlign ?? 'center',
  marginTop: theme.spacing(4),
  color: teaserBackground === 'bg-black' && theme.palette.custom.white,
  minHeight: 50,
  p: {
    maxWidth: 700,
    margin: '0 auto',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.spacing(6),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(769)]: {
      fontSize: theme.spacing(3.5),
    },
    '&:nth-of-type(2)': {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down(769)]: {
        marginTop: theme.spacing(2),
      },
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&.center': {
      textAlign: 'center',
    },
  },
  'a, .chat-trigger': {
    cursor: 'pointer',
    fontWeight: 600,
    color: teaserBackground === 'bg-black' ? theme.palette.custom.light.blue : theme.palette.custom.blue,
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    '&:visited': {
      color: teaserBackground === 'bg-black' ? theme.palette.custom.light.purple : theme.palette.custom.purple,
      textDecoration: 'none',
    },
    '&:hover': {
      color: teaserBackground === 'bg-black' ? theme.palette.custom.white : theme.palette.custom.black,
      textDecoration: 'none',
    },
  },
  textAlign,
  ...setMargin(theme, 'longtext', viewtype, external),
  [theme.breakpoints.up('md')]: {
    marginTop: external ? theme.spacing(4) : theme.spacing(6),
    textAlign,
    '.align--left': {
      textAlign: 'left',
    },
    '.align--center': {
      textAlign: 'center',
    },
    '.align--right': {
      textAlign: 'right',
    },
    '.align--justify': {
      textAlign: 'justify',
    },
  },
  [theme.breakpoints.down(769)]: {
    marginTop: theme.spacing(4),
    minHeight: 0,
  },
  [theme.breakpoints.down(376)]: {
    ...setMarginMobile(theme, 'longtext', viewtype, external),
    marginTop: 0,
    minHeight: 0,
  },

  h1: {
    fontSize: '3.75rem',
  },
  h2: {
    fontSize: theme.typography.h1.fontSize,
  },
  h3: {
    fontSize: theme.typography.h2.fontSize,
  },

  '.text-small': {
    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: theme.spacing(4),
    maxWidth: '100%',
  },
  '.text-large': {
    fontSize: theme.typography.h2.fontSize,
    lineHeight: 1.25,
    maxWidth: '100%',
    [theme.breakpoints.up(1281)]: {
      paddingLeft: theme.spacing(40),
      paddingRight: theme.spacing(40),
    },
    [theme.breakpoints.down(1281)]: {
      paddingLeft: theme.spacing(36.75),
      paddingRight: theme.spacing(36.75),
    },
    [theme.breakpoints.down(1025)]: {
      fontSize: theme.spacing(8),
      paddingLeft: theme.spacing(24.75),
      paddingRight: theme.spacing(24.75),
    },
    [theme.breakpoints.up(769)]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(16),
    },
    [theme.breakpoints.down(769)]: {
      fontSize: theme.spacing(5),
      padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(10)} ${theme.spacing(4)}`,
    },
    [theme.breakpoints.down(321)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  '&.table-container': {
    h2: {
      '&.center': {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: '1.9rem',
        textAlign: 'center',
        margin: 0,
        padding: 0,
      },
    },
    p: {
      '&.center': {
        textAlign: 'center',
        padding: '1rem',
      },
    },

    div: {
      div: {
        overflowX: 'scroll',
        '::-webkit-scrollbar': {
          height: theme.spacing(0),
          [theme.breakpoints.down('sm')]: {
            height: '0.5rem',
          },
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.custom.light.grey,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.custom.black,
        },
      },
    },
  },
}))

export const StyledLink = styled(Link, {
  name: 'TextModule',
  slot: 'StyledLink',
})(() => ({
  color: 'inherit',
}))
