import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { userDetailsSelector } from '../../../../redux/selectors/user'
import { SubscriptionForm } from './SubscriptionForm'
import { useSite } from '../../../../foundation/hooks/useSite'
import {
  useUpdateNewsletterPrivacyFlagMutation,
  useSubscribeNewsletterMutation,
  useUnsubscribeNewsletterMutation,
} from '../../../../features/newsletter/query'
import { NEWSLETTER } from '@foundation/constants/common'
import { convertToLocale } from '@utils/common'

export const SubscriptionSection: React.FC = () => {
  const userDetails = useSelector(userDetailsSelector)
  const { mySite } = useSite()
  const [isSubmitSuccess, setSubmitSuccess] = useState(false)
  const [subscribe, { isLoading: isSubscribing }] = useSubscribeNewsletterMutation()
  const [unsubscribe, { isLoading: isUnsubscribeLoading }] = useUnsubscribeNewsletterMutation()
  const [isSubscribed, setIsSubscribed] = useState(userDetails?.x_data?.hasNewsletter)
  const isBusy = isSubscribing || isUnsubscribeLoading
  const [updateNewsletterPrivacyFlag] = useUpdateNewsletterPrivacyFlagMutation()

  const handleSubscribe = async () => {
    setSubmitSuccess(false)
    subscribe({
      email: userDetails?.email1 || '',
      newsletter_id: mySite.newsletterType || NEWSLETTER,
      from: 'myaccount',
    })
      .unwrap()
      .then(() => {
        setSubmitSuccess(true)
        setIsSubscribed(true)
      })
      .then(() => {
        updateNewsletterPrivacyFlag({
          EmailAddress: userDetails?.email1 || '',
          customersubscribestatus: 1,
          storeId: mySite.storeId,
          locale: convertToLocale(mySite.locale),
        })
      })
  }

  const handleUnsubscribe = async () => {
    setSubmitSuccess(false)
    unsubscribe(userDetails?.email1 || '')
      .unwrap()
      .then(() => {
        setSubmitSuccess(true)
        setIsSubscribed(false)
      })
      .then(() => {
        updateNewsletterPrivacyFlag({
          EmailAddress: userDetails?.email1 || '',
          customersubscribestatus: 0,
          storeId: mySite.storeId,
          locale: convertToLocale(mySite.locale),
        })
      })
  }

  const onReset = () => {
    setSubmitSuccess(false)
  }

  return (
    <SubscriptionForm
      email={userDetails?.email1}
      subscribed={isSubscribed}
      onSubscribe={handleSubscribe}
      onUnsubscribe={handleUnsubscribe}
      onReset={onReset}
      isBusy={isBusy}
      isSubmitSuccess={isSubmitSuccess}
    />
  )
}
