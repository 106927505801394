import React from 'react'
import { compact } from 'lodash-es'
import { Box } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { getModelName } from '../../utils/productAttributes'
import { OrderItemWithRoxProps } from '../../types/order'
import ProductImage from '../ProductImage/ProductImage'
import {
  OrderRecapImageWrapper,
  PriceWrapper,
  OrderRecapSectionValue,
  OrderRecapInfoSectionWrapper,
  OrderRecapPriceSection,
  OrderAdjustmentAmount,
  OrderRecapSectionLabel,
} from './OrderRecapItem.style'
import OrderRecapItemPrice from './OrderRecapItemPrice'
import { OrderRecapRxItemLensDetails } from './OrderRecapRxItemLensDetails'
import { CHECKOUT_STEPS } from '../../constants/common'
import { InsuranceEligibleCallout } from '../../views/Cart/components/CartRecap/components/InsuranceEligibleCallout'
import FormattedPriceDisplay from '../formatted-price-display'
import { getFormattedDiscountedPrice } from '../../features/order/utils'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import styles from './styles/OrderRecapItem.module.scss'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import OrderService from '../../foundation/apis/transaction/order.service'
import { renderOrderItemStatusColor } from '@constants/order'
import { OrderRecapItemPropsBase } from './OrderRecapItem'
import clsx from 'clsx'

export type OrderRecapRxItemProps = OrderRecapItemPropsBase<OrderItemWithRoxProps>

const OrderRecapRxItem: React.FC<OrderRecapRxItemProps> = ({
  orderItem,
  showPromoCodeAppliedDiscount,
  isMyAccount = false,
  isReorderEligibleOrder,
}) => {
  const { t: translate } = useTranslation()
  const names = compact([getModelName(orderItem)])
  const frameName = names.join(' ')

  const formattedDiscountedPrice = getFormattedDiscountedPrice(orderItem)
  const isPromoCodeApplied = !!formattedDiscountedPrice
  const { activeStep } = useCheckoutSteps()
  const isConfirmationPage = CHECKOUT_STEPS.ORDER_CONFIRMATION === activeStep
  const isPostCheckout = CHECKOUT_STEPS.POST_CHECKOUT === activeStep
  const hookEstimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)
  const estimatedItemDeliveryDate =
    OrderService.getOrderItemEstimatedDeliveryDate(orderItem) || hookEstimatedDeliveryDate

  return (
    <Box style={{ width: '100%' }}>
      {!isPostCheckout && !isConfirmationPage && <InsuranceEligibleCallout orderItem={orderItem} />}
      {isConfirmationPage && estimatedItemDeliveryDate && (
        <div className={styles.containerDeliveryDateDecorated}>
          <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
          <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.containerWrapper}>
          {isMyAccount && (
            <h3
              className={clsx(
                styles.containerTitle,
                isReorderEligibleOrder && styles.containerTitleNonReorderBackground
              )}
            >
              <span>{translate('OrderRecap.Item.Status.Label')}</span>
              <span style={{ color: renderOrderItemStatusColor(orderItem.orderItemStatus?.toUpperCase()) }}>
                {translate(`OrderRecap.Item.Status.${orderItem.orderItemStatus?.toUpperCase()}`)}
              </span>
              {isReorderEligibleOrder && (
                <span className={styles.containerTitleNonReorder}>{translate('OrderRecap.Labels.NoReorder')}</span>
              )}
            </h3>
          )}
          {isMyAccount && estimatedItemDeliveryDate && (
            <div
              className={clsx(
                styles.containerDeliveryDateDecorated,
                styles.containerDeliveryDateStretched,
                styles.containerDeliveryDateNonReorder
              )}
            >
              <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
              <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
            </div>
          )}

          <div className={clsx(styles.content, !isMyAccount && !isConfirmationPage && styles.contentVertical)}>
            <OrderRecapImageWrapper>
              <ProductImage attachments={orderItem.attachments} usage="PLP" />
            </OrderRecapImageWrapper>

            <OrderRecapInfoSectionWrapper>
              <div className={styles.contentSection}>
                <h2 className={styles.contentTitle}>{frameName}</h2>
              </div>
              <div className={styles.contentSectionList}>
                <OrderRecapSectionLabel>{translate('OrderRecap.Labels.Lenses')}:</OrderRecapSectionLabel>
                <OrderRecapRxItemLensDetails orderRxItem={orderItem} />
              </div>

              {showPromoCodeAppliedDiscount && isPromoCodeApplied && (
                <div className={styles.contentSection}>
                  <OrderRecapSectionLabel>{translate('OrderRecap.Labels.PromoCodeApplied')}:</OrderRecapSectionLabel>
                  <OrderRecapSectionValue>
                    <OrderAdjustmentAmount>
                      <FormattedPriceDisplay min={formattedDiscountedPrice} currency={orderItem.currency} />
                    </OrderAdjustmentAmount>
                  </OrderRecapSectionValue>
                </div>
              )}
              <OrderRecapPriceSection>
                <OrderRecapSectionLabel>{translate('OrderRecap.Labels.Price')}:</OrderRecapSectionLabel>
                <PriceWrapper>
                  <OrderRecapItemPrice orderItem={orderItem} showDiscountPrice />
                </PriceWrapper>
              </OrderRecapPriceSection>
              {!isMyAccount && !isConfirmationPage && estimatedItemDeliveryDate && (
                <div className={styles.containerDeliveryDate}>
                  <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
                  <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
                </div>
              )}
            </OrderRecapInfoSectionWrapper>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default OrderRecapRxItem
