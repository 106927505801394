import styled from '@mui/material/styles/styled'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { StyledButtonAsLink } from '../../../../../components/UI'

export const RxLensDetailsWrapper = styled(Accordion, {
  name: 'CartRecapItem',
  slot: 'RxLensDetailsWrapper',
})(() => ({
  boxShadow: 'unset',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'unset',
  },
}))

export const RxLensDetailsTitle = styled(AccordionSummary, {
  name: 'CartRecapItem',
  slot: 'RxLensDetailsTitle ',
})(({ theme }) => ({
  flex: '1 1 50%',
  minHeight: 'unset',
  justifyContent: 'unset',
  padding: '0',
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '& > .MuiAccordionSummary-content': {
    flex: '0 0 auto',
    margin: '0',
    fontSize: '14px',
    color: theme.palette.text.dark.primary,
    fontWeight: '600',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  '& > .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: theme.spacing(),
    transform: 'rotate(180deg)',
    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  },
}))

export const RxLensDetailsContent = styled(AccordionDetails, {
  name: 'CartRecapItem',
  slot: 'RxLensDetailsContent',
})(({ theme }) => ({
  display: 'flex',
  padding: '0',
  color: theme.palette.text.dark.primary,
}))

export const RxLensDetailsViewRxWrapper = styled('div', {
  name: 'CartRecapItem',
  slot: 'RxLensDetailsViewRxWrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: theme.typography.body1.fontSize,
  fontWeight: 700,
  backgroundColor: theme.palette.custom.light.grey,
  height: theme.spacing(8),
}))

export const RxLensDetailsShowRxText = styled('div', {
  name: 'CartRecapItem',
  slot: 'ShowRxText',
})(({ theme }) => ({
  marginLeft: theme.spacing(2),
  textDecoration: 'underline',
  ':hover': {
    cursor: 'pointer',
  },
}))

export const RxLensDetailsLensEditLink = styled(StyledButtonAsLink, {
  name: 'CartRecapItem',
  slot: 'RxLensDetailsLensEditLink',
})(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.color.black,
  marginRight: theme.spacing(2),
}))
