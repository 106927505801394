import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { OrderItem } from '@typesApp/order'
import {
  RxLensDetailsLensEditLink,
  RxLensDetailsShowRxText as RxLensDetailsShowRxSection,
  RxLensDetailsViewRxWrapper,
} from './RxLensDetails.style'
import { PRODUCT_URL_SEARCH_PARAMS } from '../../../../../constants/product'
import { useContactLensCartItemData } from '../../../../../hooks/useContactLensCartItemData/useContactLensCartItemData'
import { ModalDialog } from '../../../../../components/UI/ModalDialogV2'
import { ContactLensDetails } from './ContactLensDetails'
import { RenderRXInfo } from '../../../../Prescriptions/PrescriptionInfoContainer'
import useBreakpoints from '../../../../../hooks/useBreakpoints'
import { getFormattedLensData, getRxValuesFromRxLens } from '../../../../../components/PrescriptionLenses/RxUtils'
import { getRxLensItem } from '../../../../../utils/rx'
import { getInsuranceEventModule } from '../../../../../components/DirectBilling'
import { useRouter } from 'next/router'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { RX_SAVED_LENS_DATA_KEY } from '@constants/rxConfigurator'

export const RxPrescriptionDetails: React.FC<{
  orderItem: OrderItem
  isCl?: boolean
}> = ({ orderItem, isCl }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isMobile } = useBreakpoints()
  const { href } = orderItem.seo || { href: '' }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { interceptEditCartItem } = getInsuranceEventModule()
  const { orderItemClData } = useContactLensCartItemData({ orderItem })

  const rxLensItem = getRxLensItem(orderItem)
  const editLink = `${href}?${
    isCl ? PRODUCT_URL_SEARCH_PARAMS.EDIT_CONTACT_LENS : PRODUCT_URL_SEARCH_PARAMS.EDIT_LENS
  }=1`
  const enableRxModal = rxLensItem || isCl

  const onEditClick = () => {
    interceptEditCartItem(() => {
      localStorageUtil.set(RX_SAVED_LENS_DATA_KEY, {
        lens: {
          catEntryId: rxLensItem?.productId,
        },
        _frameUpc: orderItem?.partNumber,
      })
      router.push(editLink)
    })
  }

  const buildGlassesRx = () => {
    return (
      (rxLensItem && (
        <RenderRXInfo prescription={getRxValuesFromRxLens(rxLensItem)} isMobile={isMobile} highLightHeaders={true} />
      )) ||
      null
    )
  }

  return (
    <>
      <RxLensDetailsViewRxWrapper>
        <RxLensDetailsShowRxSection onClick={() => enableRxModal && setIsModalOpen(true)}>
          {enableRxModal && t('CartRecap.Labels.PrescriptionDetails')}
        </RxLensDetailsShowRxSection>
        <RxLensDetailsLensEditLink onClick={onEditClick}>{t('CartRecap.Actions.Edit')}</RxLensDetailsLensEditLink>
      </RxLensDetailsViewRxWrapper>
      {enableRxModal && (
        <ModalDialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={t('CartRecap.Labels.RxInfoModalTitle')}
          hideFooter={true}
          fullHeightOnSmallScreens={false}
        >
          {(isCl && <ContactLensDetails orderItemClData={getFormattedLensData(orderItemClData)!} />) ||
            buildGlassesRx()}
        </ModalDialog>
      )}
    </>
  )
}
