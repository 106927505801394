import { CHECKOUT_STEPS } from '@constants/checkout'
import { IS_REORDER_SUCCESS } from '@foundation/constants/common'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { genericErrorSelector } from '@redux/selectors/error'
import { CheckoutSteps } from '@typesApp/checkout'
import { isReOrderSummary } from '@utils/routeUtils'
import { generateAccountPagePath } from '@views/Account/Account'
import { TFunction } from 'i18next'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import * as ROUTES from '../../constants/routes'
import { useCheckoutPaths } from '../../foundation/hooks/useCheckoutPaths'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

const filterStepsInfo = (
  t: TFunction,
  path = ''
): {
  stepId: string
  stepNumber: number
  stepTitle: string
  url: string
  slug?: string
}[] => {
  let steps: {
    stepId: string
    stepNumber: number
    stepTitle: string
    url: string
    slug?: string
  }[] = []
  steps = [
    {
      stepId: CHECKOUT_STEPS.SHIPPING,
      stepNumber: 0,
      stepTitle: t('Shipping.BarTitle'),
      slug: ROUTES.CHECKOUT_CHILDREN.SHIPPING,
      url: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.SHIPPING}`,
    },
    {
      stepId: CHECKOUT_STEPS.PAYMENT,
      stepNumber: 1,
      stepTitle: t('Payment.BarTitle'),
      slug: ROUTES.CHECKOUT_CHILDREN.PAYMENT,
      url: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.PAYMENT}`,
    },
    ...(isReOrderSummary(path)
      ? [
          {
            stepId: CHECKOUT_STEPS.REORDER_SUMMARY,
            stepNumber: 2,
            stepTitle: t('ReorderSummary.CheckoutStep'),
            slug: ROUTES.CHECKOUT_CHILDREN.REORDER_SUMMARY,
            url: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.REORDER_SUMMARY}`,
          },
        ]
      : []),
    {
      stepId: CHECKOUT_STEPS.ORDER_CONFIRMATION,
      stepNumber: isReOrderSummary(path) ? 3 : 2,
      stepTitle: t('OrderConfirmation.Title'),
      slug: ROUTES.CHECKOUT_CHILDREN.ORDER_CONFIRMATION,
      url: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`,
    },
  ]
  return steps
}

export const useCheckoutSteps = (step?: 'shipping' | 'payment' | 'order-confirmation' | 'reorder') => {
  const router = useRouter()
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const checkoutPaths = useCheckoutPaths(langCode)
  const allSteps = filterStepsInfo(t, router?.pathname)
  const [validCheckoutStep, setValidCheckoutStep] = useState(false)
  const error = useSelector(genericErrorSelector)

  const completeCheckoutStep = useCallback((step: CheckoutSteps | '') => {
    localStorage.setItem('checkoutCompletedStep', step)
  }, [])

  useEffect(() => {
    if (error) {
      setValidCheckoutStep(true)
      return
    }

    const stepsWithPrevious = {
      shipping: ['cart'],
      payment: ['cart', 'shipping'],
      reorder: ['start-reorder'],
      ['order-confirmation']: ['cart', 'shipping', 'payment'],
    }
    const previousSteps = stepsWithPrevious[step ?? '']

    if (previousSteps) {
      const completedStep = localStorage.getItem('checkoutCompletedStep')

      // Reorder
      if (step && completedStep === 'reorderSummary') {
        setValidCheckoutStep(true)
        return
      }

      // Special payment methods
      if (step === CHECKOUT_STEPS.ORDER_CONFIRMATION) {
        const query = router.query

        if (!!query && Object.keys(query)?.length > 0) {
          if (!!query['PayerID'] && !!query['paymentId'] && !!query['token']) {
            // Paypal payment
            setValidCheckoutStep(true)

            return
          }
        } else if (localStorage.getItem('isApplePaySuccess') === 'true') {
          // Applepay payment
          setValidCheckoutStep(true)

          return
        } else if (localStorage.getItem('isKlarnaSuccess') === 'true') {
          // Klarna payment
          setValidCheckoutStep(true)

          return
        }
      }

      const validPreviousStep = previousSteps[previousSteps.length - 1]
      if (
        Array.isArray(validPreviousStep)
          ? validPreviousStep.includes(completedStep)
          : completedStep === validPreviousStep
      ) {
        setValidCheckoutStep(true)
      } else {
        if (previousSteps.includes(completedStep ?? '')) {
          const index = previousSteps.indexOf(completedStep ?? '')

          if (index >= 0) {
            let targetStep = previousSteps[index + 1]

            if (targetStep.includes('start-reorder')) {
              if (localStorageUtil.get(IS_REORDER_SUCCESS) === true) {
                targetStep = 'start-reorder'
              } else {
                targetStep = targetStep.filter((step: string) => step !== 'start-reorder')[0]
              }
            }

            if (targetStep === 'payment') {
              const paymentUrl = localStorage.getItem('acceptedNewsletterFlagUrl') || checkoutPaths[targetStep]

              router.push(paymentUrl)
            } else {
              router.push(checkoutPaths[targetStep])
            }
          } else {
            if (step !== 'reorder') {
              router.push(checkoutPaths.cart)
            } else {
              router.push(generateAccountPagePath(langCode, ROUTES.ACCOUNT_CHILDREN.ORDER_HISTORY))
            }
          }
        } else {
          if (completedStep === 'shipping' && step === 'shipping') {
            completeCheckoutStep('cart')
            router.push(checkoutPaths.shipping)
          } else if ((step === 'payment' || step === 'order-confirmation') && completedStep === 'start-reorder') {
            router.push(checkoutPaths.shipping)
          } else {
            if (step !== 'reorder') router.push(checkoutPaths.cart)
            else router.push(generateAccountPagePath(langCode, ROUTES.ACCOUNT_CHILDREN.ORDER_HISTORY))
          }
        }
      }
    } else {
      setValidCheckoutStep(true)
    }
  }, [checkoutPaths, completeCheckoutStep, error, langCode, router, step])

  const checkoutSteps = filterStepsInfo(t, router?.pathname)

  const activeStep = useMemo(() => {
    if (router?.pathname === checkoutPaths.checkout) {
      return 'checkout'
    } else {
      // NOTE: checkout pages may have direct billing child routes,
      // so we need to ignore the catch all portion within the
      // pathname to correctly determine the active checkout step.
      //   e.g. router.pathname: checkout/shipping/[[...params]]
      //   --> checkout/shipping
      //   --> shipping
      const regexp = new RegExp(/^(.*?)\/\[/)

      return checkoutSteps.find(step => {
        const groups = regexp.exec(router.pathname)
        // for router.pathname: checkout/shipping/[[...params]]
        //   groups[0]: checkout/shipping/[
        //   groups[1]: checkout/shipping
        const pathname = groups && groups.length > 0 ? groups[1] : router.pathname
        return pathname.split('/').slice(-1).pop() === step.slug
      })?.stepId
    }
  }, [checkoutPaths.checkout, checkoutSteps, router.pathname])

  const previousSteps = useMemo(() => {
    return checkoutSteps.filter((step, i) => {
      return (
        step.stepId !== activeStep &&
        i <
          checkoutSteps.findIndex(step => {
            return step.stepId === activeStep
          })
      )
    })
  }, [checkoutSteps, activeStep])

  const nextSteps = useMemo(() => {
    return checkoutSteps.filter((step, i) => {
      return (
        step.stepId !== activeStep &&
        i >
          checkoutSteps.findIndex(step => {
            return step.stepId === activeStep
          })
      )
    })
  }, [checkoutSteps, activeStep])

  return {
    activeStep,
    previousSteps,
    nextSteps,
    checkoutSteps,
    allSteps,
    validCheckoutStep,
    completeCheckoutStep,
  }
}
