import React, { useEffect, useRef } from 'react'
import { values } from 'lodash-es'
//CONSTS
import { ACCOUNT } from '../../foundation/constants/common'
import { APPLEPAY_ORDER_ID } from '../../constants/checkout'
import { ORDER_CONFIGS } from '../../configs/order'
import { ORDER_STATUS, PO_NUMBER } from '../../constants/order'
import * as PaymentMethod from '../../constants/paymentMethods'
import { PAYMENT_METHODS } from '../../constants/paymentMethods'
//TYPES
import { CheckoutPayload } from '../../types/checkout'
import { IProduct } from '../../types/product'
import { IOrderDetails } from '../../types/order'
//APIS
import {
  useExecutePreCheckoutMutation,
  useFinalizeOrderWithCybersourceMutation,
  useFindOrderdByIdQuery,
  useLazyPaypalExpressCheckStatusQuery,
} from '../../features/order/query'
//LIBS
import Axios, { Canceler } from 'axios'
import { parseCatentriesForRX } from '../../foundation/analytics/tealium/formatters/productFormatter'
import { ANALYTICS_IS_REORDER, getPartNumberQuantities, sendTYPData } from '../../foundation/analytics/tealium/lib'
import { monetateMapProductsInCart, monetateTrackPurchase } from '../../foundation/monetate/lib'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
//UI
import { StyledOrderConfirmationLoaderWrapper } from './OrderConfirmation.style'
import { PreLoader } from '../../components/UI'
//REDUX
import { useDispatch, useSelector } from 'react-redux'
import { cartSelector, catentriesSelector, orderItemsSelector, orderSelector } from '../../features/order/selector'
import { userEmailSelector } from '../../redux/selectors/user'
//UTILS
import { localStorageUtil, sessionStorageUtil } from '../../foundation/utils/storageUtil'
import { updateUrlParameter } from '../../utils/url'
import { isRxCart, parseOrderItems } from '../../utils/isRxOrder'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import { getCheckoutPaths } from '../../utils/routeUtils'
import { resetReorderId, resetReorderInfo, togglePaypalExpress } from '../../features/order/slice'
import { getInsuranceEventModule } from '../../components/DirectBilling'
import { getCatEntriesAlgoliaPrices } from '../../foundation/algolia/algoliaPrice'
import { useRouter } from 'next/navigation'
import { hasSubscribedItemsInOrder } from '@views/Subscription/helpers/subscriptionHelpers'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import {
  SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION,
  SET_SUBSCRIPTION_ORDER_COMPLETE_ACTION,
} from '@redux/actions/subscription'
import { useSubmitSubscriptionInfoMutation } from '@features/subscription/query'
import { getOrderPayMethodId } from '@utils/payMethods'
import { clearReorderLocalStorage } from '@utils/order'
import { getProductType } from '@utils/productAttributes'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import productUtils from '@utils/ProductUtils'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

/**
 * Order Confirmation component
 * displays order confirmation info
 * @param props
 */

const OrderConfirmation: React.FC = () => {
  const { mySite } = useSite()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { langCode } = useStoreIdentity()
  const router = useRouter()
  const firstLoad = useRef(true)
  const analyticsDataForTYP = useAnalyticsData(ANALYTICS_PAGE_TYPE.CHECKOUT_SUMMARY_AND_CONFIRMATION)
  const customerSegment = useCustomerSegmentsUtil()
  const { completeCheckoutStep } = useCheckoutSteps()

  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const email = useSelector(userEmailSelector)
  const orderItems = useSelector(orderItemsSelector)
  const catEntries = useSelector(catentriesSelector)
  const order = useSelector(orderSelector)
  const cart = useSelector(cartSelector)
  const orderId = cart?.orderId || localStorageUtil.get(APPLEPAY_ORDER_ID)

  const [
    finalizeOrderWithCybersource,
    {
      isSuccess: isSuccessFinalizeOrder,
      isLoading: isLoadingFinalizeOrder,
      isError: isErrorFinalizeOrder,
      error: errorFinalizeOrder,
    },
  ] = useFinalizeOrderWithCybersourceMutation()

  const [
    executePrecheckout,
    {
      isSuccess: isSuccessPrecheckout,
      isLoading: isLoadingPrecheckout,
      isError: isErrorPrecheckout,
      error: errorPrecheckout,
    },
  ] = useExecutePreCheckoutMutation()

  const [
    submitSubscriptionInfo,
    {
      isSuccess: isSuccessSubscription,
      isLoading: isLoadingSubscription,
      isError: isErrorSubscription,
      error: errorSubscription,
    },
  ] = useSubmitSubscriptionInfoMutation()

  const [
    paypalExpressCheckStatus,
    {
      isSuccess: isSuccessPaypalCheck,
      isLoading: isLoadingPaypalCheck,
      isError: isErrorPaypalCheck,
      error: errorPaypalCheck,
    },
  ] = useLazyPaypalExpressCheckStatusQuery()

  const {
    data: orderDetails,
    isLoading: isLoadingFindOrder,
    isError: isErrorFindOrder,
    error: errorFindOrder,
  } = useFindOrderdByIdQuery({
    storeId: mySite.storeID,
    orderId,
  })

  const isBusy =
    isLoadingPrecheckout ||
    isLoadingSubscription ||
    isLoadingPaypalCheck ||
    isLoadingFindOrder ||
    isLoadingFinalizeOrder
  const isError =
    isErrorFinalizeOrder || isErrorPrecheckout || isErrorSubscription || isErrorPaypalCheck || isErrorFindOrder
  const error = errorFinalizeOrder ?? errorPrecheckout ?? errorSubscription ?? errorPaypalCheck ?? errorFindOrder

  const orderComplete = orderDetails?.orderData?.orderComplete

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''

  const checkoutPaths = getCheckoutPaths(langCode)
  const payloadBase = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const isCartWithRx = isRxCart(cart?.orderExtendAttribute)
  //TODO: use updated function for parsed orders
  const parsedOrderItemList = isCartWithRx ? parseOrderItems(orderItems) : orderItems

  const algoliaPrices = getCatEntriesAlgoliaPrices(customerSegment, catEntries)

  const isPaypalExpressOrder = (orderData?: IOrderDetails): boolean => {
    if (!orderData) return false
    return !!orderData?.paymentInstruction.find(pi => !!pi)?.protocolData.find(el => el.name === 'isPayPalExpress')
      ?.value
  }
  const orderPaymentMethodId = getOrderPayMethodId(orderDetails?.orderData)
  const isPayPal = orderPaymentMethodId === PAYMENT_METHODS.CHECKOUT_NAMES.PAYPAL
  const isPaypalExpress = isPayPal && isPaypalExpressOrder(orderDetails?.orderData)

  const payloadBaseCheckout: CheckoutPayload = {
    currency: defaultCurrencyID,
    storeId: mySite.storeID,
    responseFormat: 'application/json',
    ...payloadBase,
  }

  const orderFinalizationPayload: CheckoutPayload = {
    ...payloadBaseCheckout,
    orderId,
    ppExpress: isPaypalExpress,
    sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
    body: {
      orderId,
      notifyOrderSubmitted: '1',
    },
  }

  // #region FUNCTIONS

  const redirectToShipping = () => {
    const errorUrl = updateUrlParameter(
      isPaypalExpress ? checkoutPaths.cart : checkoutPaths.payment,
      'orderError',
      error
    )
    return router.push(errorUrl)
  }

  const getPayMethodDescription = (orderPayMethod: PaymentMethod.CheckoutName) => {
    const payMethodDescription = orderPayMethod
      ? (PAYMENT_METHODS.CHECKOUT_CC_NAMES as PaymentMethod.CheckoutCCName).includes(orderPayMethod)
        ? t('OrderConfirmation.Msgs.PayMethodCC')
        : orderPayMethod
      : ''
    return payMethodDescription
  }

  const shouldOrderBeClosed = (orderData: IOrderDetails): boolean => {
    const orderPaymentMethod =
      orderData && getPayMethodDescription(getOrderPayMethodId(orderData) as PaymentMethod.CheckoutName)
    return (
      !orderData?.orderComplete &&
      !isBusy &&
      ![ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription, ORDER_STATUS.Hold].includes(orderData.orderStatus) &&
      orderPaymentMethod !== PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY
    )
  }

  const resetOrderInfo = async () => {
    if (!cart?.orderId) {
      return
    }
    clearReorderLocalStorage()
    dispatch(resetReorderId())
    dispatch(resetReorderInfo())
    localStorageUtil.remove(ACCOUNT + '-' + PO_NUMBER + '-' + cart?.orderId)
    localStorageUtil.remove(APPLEPAY_ORDER_ID)
  }

  const cleanupOnOrderComplete = () => {
    if (isSuccessFinalizeOrder || order.orderComplete === true) {
      getInsuranceEventModule().onOrderComplete()
      resetOrderInfo()
      sessionStorageUtil.remove(ANALYTICS_IS_REORDER)
      firstLoad.current = false
      completeCheckoutStep('order-confirmation')
      localStorage.removeItem('acceptedNewsletterFlagUrl')
      localStorageUtil.set('rxFlowTypeMap', '[]')
      localStorage.removeItem('checkoutCompletedStep')

      if (catEntries && firstLoad.current) {
        const productsForMonetate = monetateMapProductsInCart(orderItems)
        const products = values(catEntries) as IProduct[]
        const partNumberQuantities = getPartNumberQuantities(parsedOrderItemList)
        if (isCartWithRx) {
          const frameCatEntries = parseCatentriesForRX(
            parsedOrderItemList,
            products.filter(y => getProductType(y) !== 'Rox_lens' && getProductType(y) !== 'Rox_service'),
            algoliaPrices
          )
          const combinedAlgoliaPrices = productUtils.combineAlgoliaOpticalPrices(frameCatEntries, algoliaPrices)

          sendTYPData({
            common: analyticsDataForTYP,
            products: frameCatEntries,
            email,
            order,
            partNumberQuantities,
            algoliaPrices: combinedAlgoliaPrices,
          })
        } else {
          sendTYPData({
            common: analyticsDataForTYP,
            products,
            email,
            order,
            partNumberQuantities,
            algoliaPrices,
          })
        }
        monetateTrackPurchase({
          purchaseId: orderDetails?.orderData?.orderId ?? '',
          productsInCart: productsForMonetate,
        })
      }
    }
  }

  const placeOrder = async (placeOrderWithSubscription, orderFinalizationPayload) => {
    if (!isSuccessPrecheckout && !isLoadingPrecheckout && !isSuccessFinalizeOrder && !isLoadingFinalizeOrder) {
      await executePrecheckout(orderFinalizationPayload)
    }

    if (placeOrderWithSubscription) {
      if (!isSuccessSubscription && !isLoadingSubscription && isSuccessPrecheckout) {
        // FIXME: better type
        const result = (await submitSubscriptionInfo({
          storeId: mySite.storeID,
          orderItemId: cart.orderId,
        })) as any
        await dispatch(SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION(result?.data?.orderId ?? cart.orderId))
        await dispatch(
          SET_SUBSCRIPTION_ORDER_COMPLETE_ACTION(!!result.data?.subscriptionId && result.data?.orderComplete)
        )
      }
    } else if (!isSuccessFinalizeOrder && !isLoadingFinalizeOrder) {
      await finalizeOrderWithCybersource(orderFinalizationPayload)
    }
  }

  const managePaypalOrder = async () => {
    if (!orderDetails || orderComplete) {
      return
    }

    try {
      const placeOrderWithSubscription = isSubscriptionEnabled && hasSubscribedItemsInOrder(cart, null)
      const pExpressStatus = !isSuccessPaypalCheck && (await paypalExpressCheckStatus(orderFinalizationPayload))
      if (
        pExpressStatus &&
        pExpressStatus?.data?.decision === 'ACCEPT' &&
        shouldOrderBeClosed(orderDetails.orderData)
      ) {
        await placeOrder(placeOrderWithSubscription, orderFinalizationPayload)
        await dispatch(togglePaypalExpress(isPayPal))
      }
    } catch (error) {
      console.error('UNABLE TO FINALIZE ORDER: ', { error }, window.location.href)
    } finally {
      cleanupOnOrderComplete()
    }
  }

  const manageOrder = async () => {
    if (!orderDetails || orderComplete) {
      return
    }
    try {
      if (shouldOrderBeClosed(orderDetails.orderData)) {
        const placeOrderWithSubscription = isSubscriptionEnabled && hasSubscribedItemsInOrder(cart, null)
        await placeOrder(placeOrderWithSubscription, orderFinalizationPayload)
      }
    } catch (e: any) {
      console.error('UNABLE TO FINALIZE ORDER: ' + e, window.location.href)
    } finally {
      cleanupOnOrderComplete()
    }
  }

  // #region EFFECTS

  useEffect(() => {
    if (!!orderDetails) {
      const manage = isPayPal ? managePaypalOrder : manageOrder
      void manage()
    }
  }, [orderDetails, isPayPal, isSuccessPrecheckout])

  if (isError && !orderComplete) {
    redirectToShipping()
  }

  return isBusy && !orderComplete ? (
    <StyledOrderConfirmationLoaderWrapper>
      <PreLoader size={20} />
    </StyledOrderConfirmationLoaderWrapper>
  ) : null
}

export default OrderConfirmation
