//Standard libraries
import Axios, { Canceler } from 'axios'
import {
  CheckoutLeftColumn,
  CheckoutRightColumn,
  CheckoutWrapper,
  OrderConfirmationEmail,
  OrderConfirmationHeader,
  OrderConfirmationLabel,
  OrderConfirmationLabelWrapper,
  OrderConfirmationThankYou,
} from './Checkout.style'
import React, { PropsWithChildren, createContext, useEffect, useState, useMemo } from 'react'
import { GridItem, PreLoader, StyledButtonAsLink } from '../../components/UI'
import {
  cartSelector,
  catentriesSelector,
  isPromoCouponAppliedSelector,
  orderCompleteSelector,
  orderDetailsSelector,
  orderItemsSelector,
} from '../../features/order/selector'
import { isRxCart, isRxProduct, parseOrderItems } from '../../utils/isRxOrder'
import { monetateMapProductsInCart, monetateTrackCart } from '../../foundation/monetate/lib'
import { setOpenModalRegistration, setOpenModalSignIn } from '../../features/ui/action'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { APPLEPAY_ORDER_ID } from '../../constants/checkout'
import CheckoutMain from './components/CheckoutMain'
import CheckoutSidebar from './components/CheckoutSidebar'
import CheckoutSummary from './components/CheckoutSummary'
import Log from '../../services/Log'
import RegistrationDialog from '../../components/RegistrationDialog'
import { IProduct } from '../../types/product'
import SignInDialog from '../../components/SignInDialog'
import { VALIDATION_ERROR_ACTION } from '../../redux/actions/error'
import { fromUrlParamsToObject } from '../../utils/url'
import guestIdentityService from '../../foundation/apis/transaction/guestIdentity.service'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
import { loginStatusSelector } from '../../redux/selectors/user'
import { parseCatentriesForRX } from '../../foundation/analytics/tealium/formatters/productFormatter'
import { getPartNumberQuantities, sendDeliveryEvent } from '../../foundation/analytics/tealium/lib'
//Foundation libraries
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import useBreakpoints from '../../hooks/useBreakpoints'
import { getCheckoutPaths } from '../../utils/routeUtils'
import { useCheckoutSteps } from '../../hooks/useCheckoutSteps'
import { useGetCartQuery, useGetShippingFsaLeadTimeMapQuery } from '../../features/order/query'
//Redux
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import PrescriptionBannerCheckout from '../../components/UI/PrescriptionBannerCheckout/PrescriptionBannerCheckout'
import { uploadRxFileOnOrderConfirmSelector } from '../../redux/selectors/site'
import { values } from 'lodash-es'
import { CHECKOUT_STEPS } from '../../constants/common'
import { getCatEntriesAlgoliaPrices } from '../../foundation/algolia/algoliaPrice'
import { useRouter, useSearchParams } from 'next/navigation'
import { Link } from '@components/common/Link/Link'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import ReorderSummaryRightColumn from './components/ReorderSummaryRightColumn'
import { SIGNIN } from '@constants/routes'
import styles from './styles/Loader.module.scss'
import { getCheckoutGridWidths, DEFAULT_CHECKOUT_COL_GRIDS } from '@utils/checkout'
import {
  isWunderkindEnabledForLocale,
  addWunderkindConversionScript,
  WunderkindConversionMultiPixel,
} from '@components/Wunderkind/Wunderkind'
import productUtils from '@utils/ProductUtils'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

export const CheckoutContext = createContext({
  shippingZipCode: '',
  setShippingZipCode: zipCode => {
    zipCode
  },
})

/**
 * Checkout component
 * displays shipping, billing, payment, review sections
 * @param props
 */
const Checkout: React.FC<PropsWithChildren> = () => {
  const searchParams = useSearchParams()
  const { ...analyticsDataForCheckout } = useAnalyticsData(ANALYTICS_PAGE_TYPE.DELIVERY)
  const cart = useSelector(cartSelector)
  const orderDetails = useSelector(orderDetailsSelector)
  const { lastSubscribedOrderId } = useSelector(subscriptionConfigSelector)
  const orderComplete =
    useSelector(orderCompleteSelector) || (!!lastSubscribedOrderId && lastSubscribedOrderId === orderDetails?.orderId)
  const userLoggedIn = useSelector(loginStatusSelector)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { mySite } = useSite()
  const router = useRouter()
  const { langCode } = useStoreIdentity()
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const checkoutPaths = getCheckoutPaths(langCode)
  const [userIsRegistered, setUserIsRegistered] = useState<boolean>(false)
  const [identityServiceLoading, setIdentityServiceLoading] = useState<boolean>(false)
  const [shippingZipCode, setShippingZipCode] = useState('')
  const isApplePayOrder = !!localStorageUtil.get(APPLEPAY_ORDER_ID)
  const timestampRef = React.useRef(Date.now()).current
  const [hasSentDeliveryEvent, setHasSentDeliveryEvent] = useState(false)
  const [isReorderBusy, setReorderBusy] = useState(false)
  const customerSegment = useCustomerSegmentsUtil()
  useGetShippingFsaLeadTimeMapQuery({})
  const { activeStep } = useCheckoutSteps()
  const { isDesktop, isViewportWidthAbove1240, isViewportWidthAbove1440, isViewportWidthAbove1950 } = useBreakpoints()
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const isPromoCouponApplied = useSelector(isPromoCouponAppliedSelector)

  const payloadBase: any = {
    currency: defaultCurrencyID,
    storeId: mySite.storeID,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const { isLoading: cartLoading } = useGetCartQuery({
    ...payloadBase,
    fetchCatentries: true,
    fetchShippingInfo: false,
    refetch: false,
    sessionId: timestampRef,
  })

  const orderItems = useSelector(orderItemsSelector)
  const catEntries = useSelector(catentriesSelector, shallowEqual)
  const isUploadRxAvailable = useSelector(uploadRxFileOnOrderConfirmSelector)

  const isCurrentCartRX = isRxCart(cart?.orderExtendAttribute)
  const parsedOrderItems = isCurrentCartRX ? parseOrderItems(orderItems) : orderItems

  const userEmail = orderItems?.find(item => !!item)?.email1

  const rxItem = orderItems?.find(i => isRxProduct(i.orderItemExtendAttribute))
  const IsPrescriptionUploaded = !!rxItem?.xitem_field2

  const isPrescriptionVisible = !IsPrescriptionUploaded && isUploadRxAvailable && isCurrentCartRX

  const getCheckoutSidebar = (shippingZipCode): JSX.Element | null => {
    switch (activeStep) {
      case CHECKOUT_STEPS.SHIPPING:
      case CHECKOUT_STEPS.PAYMENT:
        return <CheckoutSidebar shippingZipCode={shippingZipCode} />
      case CHECKOUT_STEPS.UPLOAD_PRESCRIPTION:
        return isDesktop ? <CheckoutSidebar /> : null
      default:
        return null
    }
  }

  const algoliaPrices = getCatEntriesAlgoliaPrices(customerSegment, catEntries)

  useEffect(() => {
    if (
      catEntries &&
      Object.keys(catEntries).length > 0 &&
      activeStep === CHECKOUT_STEPS.SHIPPING &&
      (!hasSentDeliveryEvent || !!isPromoCouponApplied)
    ) {
      const products = values(catEntries) as IProduct[]
      const partNumberQuantities = getPartNumberQuantities(parsedOrderItems)
      if (isCurrentCartRX) {
        const frameCartEntries = parseCatentriesForRX(parsedOrderItems, products, algoliaPrices)
        const combinedAlgoliaPrices = productUtils.combineAlgoliaOpticalPrices(frameCartEntries, algoliaPrices)

        sendDeliveryEvent({
          common: analyticsDataForCheckout,
          products: frameCartEntries,
          partNumberQuantities,
          algoliaPrices: combinedAlgoliaPrices,
        })
      } else {
        sendDeliveryEvent({
          common: analyticsDataForCheckout,
          products,
          partNumberQuantities,
          algoliaPrices,
        })
      }
      setHasSentDeliveryEvent(true)
    }
  }, [catEntries, activeStep, isPromoCouponApplied])

  useEffect(() => {
    const products = monetateMapProductsInCart(orderItems)
    activeStep !== CHECKOUT_STEPS.CHECKOUT && monetateTrackCart({ productsInCart: products, pageType: activeStep })
  }, [activeStep])

  useEffect(() => {
    if (
      activeStep === CHECKOUT_STEPS.ORDER_CONFIRMATION &&
      orderDetails &&
      orderItems &&
      orderItems.length &&
      isWunderkindEnabledForLocale(langCode)
    ) {
      addWunderkindConversionScript({ document, langCode, orderDetails, orderItem: orderItems[0] })
    }
  }, [activeStep, orderDetails, orderItems, langCode])

  useEffect(() => {
    const f = async () => {
      if (orderComplete && !userLoggedIn) {
        const payload = {
          orderId: cart?.orderId,
          email: userEmail,
        }
        try {
          setIdentityServiceLoading(true)
          const identityRes = !!userEmail
            ? await guestIdentityService.checkRegisteredEmail(payload).catch(e => {
                throw e
              })
            : null
          identityRes && setUserIsRegistered(identityRes.data?.EMAIL_ALREADY_REGISTERED || false)
          setIdentityServiceLoading(false)
        } catch (e: any) {
          setIdentityServiceLoading(false)
          Log.error('Unable to check user registration status: ' + e.message, activeStep || '')
        }
      }
    }
    f()
  }, [orderComplete])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const orderError = searchParams.get('orderError')

    const orderErrorKeyObject = fromUrlParamsToObject(searchParams.toString())
    orderErrorKeyObject?.orderError &&
      dispatch(
        VALIDATION_ERROR_ACTION({
          errorMessage: orderError,
        })
      )
  }, [searchParams])

  const showOrderConfirmationSection =
    (orderComplete || orderDetails?.orderComplete) && activeStep === CHECKOUT_STEPS.ORDER_CONFIRMATION

  const { leftColGridWidth, rightColGridWidth, dividerGridWidth } = useMemo(
    () =>
      getCheckoutGridWidths(activeStep, isViewportWidthAbove1240, isViewportWidthAbove1440, isViewportWidthAbove1950),
    [activeStep, isViewportWidthAbove1240, isViewportWidthAbove1440, isViewportWidthAbove1950]
  )

  // Reorder summary page is only for logged-in users
  if (activeStep === CHECKOUT_STEPS.REORDER_SUMMARY && !userLoggedIn) {
    router.replace(`/${langCode}/${SIGNIN}`)
  }

  // Redirect to cart if there's no pending order
  const shouldRedirectToCart =
    !cartLoading && !cart && activeStep !== CHECKOUT_STEPS.REORDER_SUMMARY && !localStorageUtil.get(APPLEPAY_ORDER_ID)
  if (shouldRedirectToCart) {
    router.push(checkoutPaths.cart)
  }

  return !isApplePayOrder && cartLoading ? (
    <div className={styles.loaderContainer}>
      <PreLoader size={50} />
    </div>
  ) : shouldRedirectToCart ? (
    <Link href={checkoutPaths.cart} />
  ) : activeStep === CHECKOUT_STEPS.CHECKOUT ? (
    <Link href={checkoutPaths.shipping} />
  ) : (
    <>
      <SignInDialog />
      <RegistrationDialog />
      {showOrderConfirmationSection && (
        <OrderConfirmationHeader>
          <OrderConfirmationThankYou>{t('OrderConfirmation.Msgs.ThankYou')}</OrderConfirmationThankYou>
          <OrderConfirmationLabelWrapper>
            <OrderConfirmationLabel>
              {`${t('OrderConfirmation.Msgs.SendInfo')}`}
              <OrderConfirmationEmail>{`${userEmail}.`}</OrderConfirmationEmail>
            </OrderConfirmationLabel>
          </OrderConfirmationLabelWrapper>
          <OrderConfirmationLabel>
            {userLoggedIn ? null : userIsRegistered ? (
              <StyledButtonAsLink onClick={() => dispatch(setOpenModalSignIn(true))}>
                {t('OrderConfirmation.Msgs.LoginCta')}
              </StyledButtonAsLink>
            ) : (
              <StyledButtonAsLink onClick={() => dispatch(setOpenModalRegistration(true))}>
                {t('OrderConfirmation.Msgs.CreateAnAccount')}
              </StyledButtonAsLink>
            )}

            {!userLoggedIn && t('OrderConfirmation.Msgs.RegistrationBenefit')}
          </OrderConfirmationLabel>
          {orderDetails && orderItems && orderItems.length && isWunderkindEnabledForLocale(langCode) && (
            <WunderkindConversionMultiPixel orderDetails={orderDetails} orderItem={orderItems[0]} langCode={langCode} />
          )}
        </OrderConfirmationHeader>
      )}
      <CheckoutContext.Provider value={{ shippingZipCode, setShippingZipCode }}>
        <CheckoutWrapper isGreyBackground={activeStep === CHECKOUT_STEPS.REORDER_SUMMARY}>
          <CheckoutLeftColumn
            isFullWidth={activeStep === CHECKOUT_STEPS.REORDER_SUMMARY}
            xs={DEFAULT_CHECKOUT_COL_GRIDS}
            sm={DEFAULT_CHECKOUT_COL_GRIDS}
            md={leftColGridWidth}
            lg={leftColGridWidth}
          >
            <CheckoutMain
              activeStep={activeStep}
              identityServiceLoading={identityServiceLoading}
              userIsRegistered={userIsRegistered}
              shippingZipCode={shippingZipCode}
              setReorderBusy={setReorderBusy}
            ></CheckoutMain>
          </CheckoutLeftColumn>

          {dividerGridWidth > 0 && (
            <GridItem
              xs={DEFAULT_CHECKOUT_COL_GRIDS}
              sm={DEFAULT_CHECKOUT_COL_GRIDS}
              md={dividerGridWidth}
              lg={dividerGridWidth}
            />
          )}

          <CheckoutRightColumn
            xs={DEFAULT_CHECKOUT_COL_GRIDS}
            sm={DEFAULT_CHECKOUT_COL_GRIDS}
            md={rightColGridWidth}
            lg={rightColGridWidth}
          >
            {getCheckoutSidebar(shippingZipCode)}
            {(orderComplete || orderDetails?.orderComplete) && activeStep === CHECKOUT_STEPS.ORDER_CONFIRMATION && (
              <CheckoutSummary />
            )}
            {activeStep === CHECKOUT_STEPS.REORDER_SUMMARY && (
              <ReorderSummaryRightColumn isReorderBusy={isReorderBusy} setReorderBusy={setReorderBusy} />
            )}
          </CheckoutRightColumn>
        </CheckoutWrapper>
      </CheckoutContext.Provider>
      {(orderComplete || orderDetails?.orderComplete) &&
        activeStep === CHECKOUT_STEPS.ORDER_CONFIRMATION &&
        isPrescriptionVisible && <PrescriptionBannerCheckout />}
    </>
  )
}

export default Checkout
