import { useSite } from '../../../foundation/hooks/useSite'

export interface CreditCardFieldValidation {
  fieldName: string
  required: boolean
  regExp: RegExp
}

export const creditCardFieldValidationConfig = () => {
  const { mySite } = useSite()
  const regExFieldMap = {}
  mySite.xStoreCfg.shippingAddressFieldsList.forEach(field => {
    regExFieldMap[field.fieldName] = field.validation
  })

  const config: Record<string, CreditCardFieldValidation> = {
    cardHolderName: {
      fieldName: 'cardHolderName',
      required: true,
      regExp: new RegExp('^[A-Za-z]+(?: [A-Za-z]+)*$'),
    },
    cardNumber: {
      fieldName: 'cardNumber',
      required: true,
      regExp: new RegExp('^[0-9]{13,19}$'),
    },
    expiryDate: {
      fieldName: 'expiryDate',
      required: true,
      regExp: new RegExp('^(0[1-9]|1[0-2])/([0-9]{2})$'),
    },
    cvv: {
      fieldName: 'cvv',
      required: true,
      regExp: new RegExp('^[0-9]{3,4}$'),
    },
    phone1: {
      fieldName: 'phone1',
      required: true,
      regExp: new RegExp('^[0-9]{6,15}$'),
    },
    billingEmail: {
      fieldName: 'billingEmail',
      required: true,
      regExp: new RegExp(regExFieldMap['email1']),
    },
    billingAddress: {
      fieldName: 'billingAddress',
      required: true,
      regExp: new RegExp(regExFieldMap['addressLine1']),
    },
    billingCity: {
      fieldName: 'billingCity',
      required: true,
      regExp: new RegExp(regExFieldMap['city']),
    },
    billingProvince: {
      fieldName: 'billingProvince',
      required: true,
      regExp: new RegExp(regExFieldMap['state']),
    },
    billingPostalCode: {
      fieldName: 'billingPostalCode',
      required: true,
      regExp: new RegExp(regExFieldMap['zipCode']),
    },
    billingCountry: {
      fieldName: 'billingCountry',
      required: false,
      regExp: new RegExp(regExFieldMap['country']),
    },
  }
  return config
}
