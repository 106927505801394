import {
  getFrameMaterialFacet,
  getFrontColor,
  getLensesColor,
  getModelName,
  getSize,
} from '../../utils/productAttributes'
import { OrderItem } from '../../types/order'
import ProductImage from '../ProductImage/ProductImage'
import React from 'react'
import {
  OrderAdjustmentAmount,
  OrderRecapImageWrapper,
  OrderRecapInfoSectionWrapper,
  OrderRecapPriceSection,
  OrderRecapSectionLabel,
  OrderRecapSectionValue,
  PriceWrapper,
} from './OrderRecapItem.style'
import { compact, toNumber } from 'lodash-es'
import { useTranslation } from 'next-i18next'
import OrderRecapItemPrice from './OrderRecapItemPrice'
import { CHECKOUT_STEPS } from '../../constants/common'
import { getFormattedDiscountedPrice } from '../../features/order/utils'
import FormattedPriceDisplay from '@components/formatted-price-display'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import styles from './styles/OrderRecapItem.module.scss'
import { renderOrderItemStatusColor } from '@constants/order'
import OrderService from '../../foundation/apis/transaction/order.service'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { Box } from '@mui/material'
import clsx from 'clsx'

export interface OrderRecapItemPropsBase<T extends OrderItem = OrderItem> {
  orderItem: T
  estimatedDeliveryDate?: string
  showPromoCodeAppliedDiscount?: boolean
  isMyAccount?: boolean
  orderId: string
  orderContainsNonReorderableItem: boolean
  isReorderEligibleOrder?: boolean
}
export type OrderRecapItemProps = OrderRecapItemPropsBase<OrderItem>

const OrderRecapItem: React.FC<OrderRecapItemProps> = ({
  orderItem,
  showPromoCodeAppliedDiscount,
  isMyAccount = false,
  isReorderEligibleOrder,
}) => {
  const { t: translate } = useTranslation()
  const names = compact([
    getModelName(orderItem),
    getSize(orderItem, translate),
    getFrameMaterialFacet(orderItem),
    getFrontColor(orderItem),
  ])
  const frameName = names.join(' ')

  const formattedDiscountedPrice = getFormattedDiscountedPrice(orderItem)
  const isPromoCodeApplied = !!formattedDiscountedPrice
  const { activeStep } = useCheckoutSteps()
  const isConfirmationPage = CHECKOUT_STEPS.ORDER_CONFIRMATION === activeStep
  const hookEstimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)
  const estimatedItemDeliveryDate =
    OrderService.getOrderItemEstimatedDeliveryDate(orderItem) || hookEstimatedDeliveryDate

  return (
    <Box style={{ width: '100%' }}>
      {isConfirmationPage && estimatedItemDeliveryDate && (
        <div className={styles.containerDeliveryDateDecorated}>
          <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
          <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.containerWrapper}>
          {isMyAccount && (
            <h3
              className={clsx(
                styles.containerTitle,
                isReorderEligibleOrder && styles.containerTitleNonReorderBackground
              )}
            >
              <span>{translate('OrderRecap.Item.Status.Label')}</span>
              <span style={{ color: renderOrderItemStatusColor(orderItem.orderItemStatus?.toUpperCase()) }}>
                {translate(`OrderRecap.Item.Status.${orderItem.orderItemStatus?.toUpperCase()}`)}
              </span>
              {isReorderEligibleOrder && (
                <span className={styles.containerTitleNonReorder}>{translate('OrderRecap.Labels.NoReorder')}</span>
              )}
            </h3>
          )}
          {isMyAccount && estimatedItemDeliveryDate && (
            <div
              className={clsx(
                styles.containerDeliveryDateDecorated,
                styles.containerDeliveryDateStretched,
                styles.containerDeliveryDateNonReorder
              )}
            >
              <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
              <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
            </div>
          )}

          <div className={clsx(styles.content, !isMyAccount && !isConfirmationPage && styles.contentVertical)}>
            <OrderRecapImageWrapper>
              <ProductImage attachments={orderItem.attachments} usage="PLP" />
            </OrderRecapImageWrapper>

            <OrderRecapInfoSectionWrapper>
              <div className={styles.contentSection}>
                <h2 className={styles.contentTitle}>{frameName}</h2>
              </div>
              <div className={styles.contentSection}>
                <OrderRecapSectionLabel>{translate('OrderRecap.Labels.Lenses')}:</OrderRecapSectionLabel>
                <OrderRecapSectionValue>{getLensesColor(orderItem)}</OrderRecapSectionValue>
              </div>
              {Number(orderItem.quantity) > 1 && (
                <div className={styles.contentSection}>
                  <OrderRecapSectionLabel>{translate('OrderRecap.Labels.Quantity')}:</OrderRecapSectionLabel>
                  <OrderRecapSectionValue>{Number(orderItem.quantity)}</OrderRecapSectionValue>
                </div>
              )}
              {showPromoCodeAppliedDiscount && isPromoCodeApplied && (
                <div className={styles.contentSection}>
                  <OrderRecapSectionLabel>{translate('OrderRecap.Labels.PromoCodeApplied')}:</OrderRecapSectionLabel>
                  <OrderRecapSectionValue>
                    <OrderAdjustmentAmount>
                      <FormattedPriceDisplay min={formattedDiscountedPrice} currency={orderItem.currency} />
                    </OrderAdjustmentAmount>
                  </OrderRecapSectionValue>
                </div>
              )}
              <OrderRecapPriceSection>
                <OrderRecapSectionLabel>{translate('OrderRecap.Labels.Price')}</OrderRecapSectionLabel>
                <PriceWrapper>
                  <OrderRecapItemPrice orderItem={orderItem} showDiscountPrice />
                </PriceWrapper>
              </OrderRecapPriceSection>
              {!isMyAccount && !isConfirmationPage && estimatedItemDeliveryDate && (
                <div className={styles.containerDeliveryDate}>
                  <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
                  <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
                </div>
              )}
            </OrderRecapInfoSectionWrapper>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default OrderRecapItem
