import React from 'react'

import { StyledButton } from '@components/UI/Button/Button'
import { useTranslation } from 'next-i18next'
import styles from '../styles/CartSubscription.module.scss'

interface DialogProps {
  message: string
  handleClose: () => void
}

export function CartSubscriptionDialog(props: DialogProps) {
  const { t: translate } = useTranslation('', { keyPrefix: 'Subscriptions' })
  const { message, handleClose } = props

  return (
    <div className={styles.dialogContainer}>
      <div className="header">
        <p className="description">{message}</p>
      </div>
      <div>
        <StyledButton variant="primary" filltype="fill" onClick={handleClose} className="cta">
          {translate('Ctas.Confirm')}
        </StyledButton>
      </div>
    </div>
  )
}
