//Standard libraries
import React from 'react'
import { ProductSoldOutStatus, IProduct } from '@typesApp/product'
import { ProductPriceAlgolia } from '../../../ProductDetails/components/ProductPriceAlgolia'
import { getIsRoxable, getProductType } from '../../../../utils/productAttributes'
import { Adjustment } from '@typesApp/order'

export interface ProductSummaryPriceProps {
  product: IProduct
  adjustments: Adjustment[]
  isRxOrder?: boolean
  soldOutStatus: ProductSoldOutStatus
}

export const ProductSummaryPrice: React.FC<ProductSummaryPriceProps> = ({
  adjustments,
  isRxOrder,
  soldOutStatus,
}: ProductSummaryPriceProps) => {
  return <ProductPriceAlgolia adjustments={adjustments} isCompact soldOutStatus={soldOutStatus} isRxOrder={isRxOrder} />
}
