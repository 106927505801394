// Standard libraries
import React, { FC } from 'react'
import { CarouselGroup, CustomDialog } from '../../UI'
import { StyledAlertHeader, StyledAlertMessage } from '../../UI/Alert'
import { ShapeVariant } from '../../UI/Carousel/Carousel.type'
import { IDialogProps } from '../../UI/CustomDialog'
import { SVGIcon, SVGIconProps } from '@components/UI-CSS/SVGIcon/SVGIcon'
import styles from './styles/Dialog.module.scss'
import clsx from 'clsx'
import styled from '@mui/material/styles/styled'
import { StyledAlert } from '../../UI'

const CUSTOM_Z_INDEX = 100002

const HighLightSection = styled(StyledAlert, {
  name: 'RXInfoModal',
  slot: 'HighLightSection',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  '> .MuiAlert-icon': {
    paddingTop: '5px',
    marginTop: 0,
  },
}))

type IMultiColumInfoDialogProps = IDialogProps & {
  content: RXInfoColumnProps[]
}
type IStyleProps = {
  color?: string
  fontSize?: string
}

type ICarouselProps = {
  items: any[]
  props?: {
    colorVariant?: string
    paginationVariant?: string
    shapeVariant?: ShapeVariant
    darkMode?: boolean
  }
}

type IDescriptionComponentProps = {
  data: any
  style?: IStyleProps
}

export type RXInfoColumnProps = {
  header?: {
    carousel: ICarouselProps
    title?: IDescriptionComponentProps
    content?: IDescriptionComponentProps
  }
  body?: {
    title?: IDescriptionComponentProps
    content?: IDescriptionComponentProps
    featuresList?: RXFeatureItemProps[]
  }
  footer?: {
    title?: IDescriptionComponentProps
    content?: IDescriptionComponentProps
  }
  highLightSection?: {
    title?: string
    message: any
    severity?: 'info' | 'warning'
  }
}

export type RXFeatureItemProps = {
  title?: IDescriptionComponentProps
  description: IDescriptionComponentProps
  icon?: {
    asset: React.FC
    style?: SVGIconProps
  }
}

export const MultiColumnInfoDialog: FC<IMultiColumInfoDialogProps> = (props: IMultiColumInfoDialogProps) => {
  const numberOfColumns = props.content?.length || 0
  const gapSize = numberOfColumns > 1 ? 20 : 0

  return (
    <CustomDialog
      {...props}
      maxWidth={props.maxWidth || (numberOfColumns > 1 ? 'md' : 'sm')}
      customZIndex={CUSTOM_Z_INDEX}
      content={
        <div className={styles.mainContainer}>
          {props.content?.map(({ header, body, footer, highLightSection }, index) => (
            <>
              <div className={clsx(styles.columnContainer, numberOfColumns > 1 && styles.twoColumns)}>
                {header && (
                  <>
                    {(header.carousel.items.length === 1 && header.carousel.items[0]) || (
                      <div className={styles.carouselContainer}>
                        <CarouselGroup
                          items={header.carousel.items}
                          itemsPerView={1}
                          itemsPerGroup={1}
                          {...header.carousel.props}
                        />
                      </div>
                    )}
                    {header.title && (
                      <div className={styles.itemTitle} style={{ ...header.title.style }}>
                        {header.title.data}
                      </div>
                    )}
                    {header.content && (
                      <div className={styles.itemDescription} style={{ ...header.content.style }}>
                        {header.content.data}
                      </div>
                    )}
                  </>
                )}
                {body && (
                  <div className={styles.bodySection}>
                    {body.title && (
                      <div className={styles.itemTitle} style={{ ...body.title.style }}>
                        {body.title.data}
                      </div>
                    )}
                    {body.content && (
                      <div className={styles.itemDescription} style={{ ...body.content.style }}>
                        {body.content.data}
                      </div>
                    )}

                    {body.featuresList?.map((feature, i) => (
                      <div className={styles.featureItemSection} key={i}>
                        <div className={styles.icon}>
                          {feature.icon ? (
                            <feature.icon.asset {...feature.icon.style} />
                          ) : (
                            <SVGIcon library="validation" name="checkmark-filled-mc" />
                          )}
                        </div>
                        <div>
                          {feature.title && (
                            <div className={styles.featureItemTitle} style={{ ...feature.title.style }}>
                              {feature.title.data}
                            </div>
                          )}
                          <div
                            className={styles.featureItemDescription}
                            {...feature.description.style}
                            dangerouslySetInnerHTML={{ __html: feature.description.data }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {footer && (
                  <>
                    {footer.title && (
                      <div className={styles.itemTitle} style={{ ...footer.title.style }}>
                        {footer.title.data}
                      </div>
                    )}
                    {footer.content && (
                      <div className={styles.itemDescription} style={{ ...footer.content.style }}>
                        {footer.content.data}
                      </div>
                    )}
                  </>
                )}

                {highLightSection && (
                  <HighLightSection severity={highLightSection.severity || 'info'} sx={{ width: '100%' }}>
                    {highLightSection.title && <StyledAlertHeader>{highLightSection.title}</StyledAlertHeader>}
                    <StyledAlertMessage fullWidth={true}> {highLightSection.message}</StyledAlertMessage>
                  </HighLightSection>
                )}
              </div>
              {index < props.content.length - 1 && (
                <div className={styles.gapColumnContainer} style={{ width: `${gapSize}px` }} />
              )}
            </>
          ))}
        </div>
      }
    />
  )
}
