import React, { useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { ADDRESS_SHIPPING_BILLING, EMPTY_STRING, SPACE } from '../../../constants/common'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useAppDispatch } from '../../../hooks/redux'
import { Card } from '../../../types/user'
import personWalletService from '../../../foundation/apis/transaction/person.wallet.service'
import { FETCH_USER_DETAILS_REQUESTED_ACTION, FETCH_USER_WALLET_REQUESTED_ACTION } from '../../../redux/actions/user'
import Log from '../../../services/Log'
import personContactService from '../../../foundation/apis/transaction/personContact.service'
import { sendSuccessMessage } from '../../../features/success/slice'
import { Stack } from '@mui/material'
import { ModalDialog } from '../../../components/UI/ModalDialogV2'
import { CreditCardFormData } from '../../../types/form'
import { Button, PreLoader, StyledAnchor } from '../../../components/UI'
import { CreditCardForm } from './CreditCardForm'
import { StyledErrorMessage } from './CreditCardForm.style'
import { useExpiryDate } from '../../Checkout/payment/useExpiryDate'
import { getSite } from '@foundation/hooks/useSite'

export interface CreditCardDialogProps {
  payloadBase: any
  onEditEnd: () => void
  isEdit?: boolean
  updateCreditCard?: (card: Card) => void
  handleResponse?: (response: boolean) => void
}
const EXISTING_WALLET_ERROR_CODE = 'wallet_ext'

const initialValues: CreditCardFormData = {
  id: EMPTY_STRING,
  cardHolderName: EMPTY_STRING,
  cardNumber: EMPTY_STRING,
  expiryDate: EMPTY_STRING,
  cvv: EMPTY_STRING,
  billingEmail: EMPTY_STRING,
  billingAddress: EMPTY_STRING,
  billingCity: EMPTY_STRING,
  billingProvince: EMPTY_STRING,
  billingPostalCode: EMPTY_STRING,
  billingCountry: EMPTY_STRING,
  phone1: EMPTY_STRING,
  isDefault: false,
}

type PatchedFormData = {
  firstName: string
  lastName: string
  expiryMonth: string
  expiryYear: string
  phone1: string
} & Partial<CreditCardFormData>

type ErrorType = { status: boolean; errorMessage: string }

export const CreditCardDialog: React.FC<CreditCardDialogProps> = ({ payloadBase, onEditEnd, handleResponse }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const [open, setOpen] = React.useState(true)
  const [isBusy, setBusy] = React.useState(false)
  const [error, setError] = React.useState<ErrorType>({ status: false, errorMessage: EMPTY_STRING })

  const { parseExpiryDate } = useExpiryDate()
  const mySite = getSite()
  const handleClose = () => {
    setOpen(false)
    onEditEnd()
  }

  const handleResult = (success: boolean, errorMessage) => {
    setBusy(false)
    setError({
      status: !success,
      errorMessage: errorMessage,
    })
    handleResponse && handleResponse(success)
  }

  const methods = useForm<CreditCardFormData>({
    defaultValues: {
      ...initialValues,
      billingCountry: storeCountry,
    },
  })

  const onSubmit = (formData: CreditCardFormData) => {
    const data = patchData(formData)

    setError({
      status: false,
      errorMessage: EMPTY_STRING,
    })

    if (hasInvalidCardData(data)) {
      setError({
        status: true,
        errorMessage: EMPTY_STRING,
      })
      return
    }

    setBusy(true)
    createCreditCard(data, payloadBase)
  }

  const createCreditCard = (data: PatchedFormData, payloadBase: any) => {
    // First add address for the card
    personContactService
      .addPersonContact({
        body: {
          firstName: data.firstName,
          lastName: data.lastName,
          addressLine1: data.billingAddress,
          zipCode: data.billingPostalCode,
          city: data.billingCity,
          state: data.billingProvince,
          country: data.billingCountry,
          addressLine: data.billingAddress,
          addressType: ADDRESS_SHIPPING_BILLING,
          email1: data.billingEmail,
          phone1: data.phone1,
        },
      })
      .then(res => res.data)
      .then(addressData => {
        if (addressData.addressId) {
          dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION({ ...payloadBase }))
          dispatch(
            sendSuccessMessage({
              key: 'success-message.ADD_ADDRESS_SUCCESS',
              messageParameters: {
                '0': data.cardHolderName,
              },
            })
          )
          // Use the saved address and add card
          personWalletService
            .addCard({
              expire_month: data.expiryMonth,
              expire_year: data.expiryYear,
              cc_account: data.cardNumber,
              cc_cvc: data.cvv,
              cc_nameoncard: data.cardHolderName,
              billingAddressId: addressData.addressId,
              shippingAddressId: addressData.addressId,
            })
            .then(res => res.data)
            .then(cardData => {
              if (cardData.success === 'ok') {
                dispatch(FETCH_USER_WALLET_REQUESTED_ACTION(payloadBase))
                handleResult(cardData.identifier, EMPTY_STRING)
                handleClose()
              }
            })
            .catch(e => {
              const errorMessage =
                EXISTING_WALLET_ERROR_CODE === e?.response?.data?.resourceName ? 'WalletExistsError' : EMPTY_STRING

              handleResult(false, errorMessage)
              //we dont want to keep the address without the proper card
              personContactService.getAllPersonContact(payloadBase).then(res => {
                const currentAddress = res.data?.contact?.find(addr => addr.addressId === addressData.addressId)
                if (!!currentAddress) {
                  personContactService.deletePersonContact({
                    storeId: mySite?.storeID,
                    nickName: currentAddress.nickName,
                  })
                }
              })

              Log.error('Could not create new card: ' + e)
            })
        }
      })
      .catch(e => {
        handleResult(false, EMPTY_STRING)
        Log.error('Could not create new address for credit card: ' + e)
      })
  }

  const patchData = (data: CreditCardFormData): PatchedFormData => {
    // Use first word as first name and the rest as last name
    const nameParts = data.cardHolderName.split(SPACE)
    const firstName = nameParts[0]
    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(SPACE) : SPACE

    const billingPostalCode = data.billingPostalCode?.replace(' ', '') ?? ''
    const billingCity = data.billingCity?.toUpperCase() ?? ''
    const billingProvince = data.billingProvince?.toUpperCase() ?? ''
    const billingCountry = data.billingCountry?.toUpperCase() ?? ''
    const phone1 = data.phone1 ?? ''
    const expiryDate = parseExpiryDate(data.expiryDate)

    return {
      ...data,
      firstName,
      lastName,
      phone1,
      billingPostalCode,
      billingCity,
      billingProvince,
      billingCountry,
      expiryDate: expiryDate.value,
      expiryMonth: expiryDate.month,
      expiryYear: expiryDate.year,
    }
  }

  const hasInvalidCardData = (data: PatchedFormData): boolean => {
    return (
      !data.cardHolderName ||
      !data.cardNumber ||
      !data.cvv ||
      !data.expiryDate ||
      data.expiryDate.length !== 5 ||
      data.phone1.length < 6
    )
  }

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={t('PaymentMethod.CardDetails')}
      footer={<DialogFooter isBusy={isBusy} error={error} />}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          id={'credit_card_form_5_newCard-payment-method'}
          name={'credit_card_form_5_newCard-payment-method'}
        >
          <CreditCardForm />
        </form>
      </FormProvider>
    </ModalDialog>
  )
}

export interface DialogFooterProps {
  isBusy: boolean
  error: ErrorType
}

const DialogFooter: React.FC<DialogFooterProps> = ({ isBusy, error }) => {
  const { t } = useTranslation()

  return (
    <Stack direction={'column'} spacing={2} alignItems={'flex-start'} justifyContent={'flex-start'} width="100%">
      <Button
        type="submit"
        form="credit_card_form_5_newCard-payment-method"
        data-element-id={'X_X_PaymentMethod_Add'}
        disabled={isBusy}
        sx={{ margin: 0 }}
      >
        {isBusy && <PreLoader fill={'light'} withButton />}
        {t('PaymentMethod.AddPaymentMethod')}
      </Button>

      {error.status && (
        <StyledErrorMessage>
          <Trans i18nKey={`PaymentMethod.CreditCardForm.Msgs.${error.errorMessage || 'SaveError'}`}>
            <StyledAnchor target={''} external href={`tel:${t('PaymentMethod.CreditCardForm.Phone')}`} />
          </Trans>
        </StyledErrorMessage>
      )}
    </Stack>
  )
}
