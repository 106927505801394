import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV4 } from '../../../../constants/ui'

export const WrapperHeader = styled('header', {
  shouldForwardProp: prop => prop !== 'isSticky',
  name: 'Header',
  slot: 'Wrapper',
})<{ isSticky: boolean | null }>(({ theme, isSticky }) => ({
  position: 'sticky',
  visibility: isSticky != false ? 'visible' : 'collapse',
  top: 0,
  left: 0,
  zIndex: Z_INDEX_LV4,
  transform: isSticky != false ? 'translateY(0%)' : 'translateY(-100%)',
  transition: '300ms ease-in-out',
}))
