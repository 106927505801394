import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Modal } from '@mui/material'
import { StyledFormControlLabel, StyledRadio, StyledTooltip } from '../../../components/UI'
import { RemoveAddressDialog } from '../../../components/RemoveAddressDialog'
import { Contact, Wallet } from '../../../types/user'

import {
  AddressBookContent,
  AddressBoxWrapper,
  AddressButtonWrapper,
  AddressCustomerName,
  AddressLineBreak,
  RemoveButtonWrapper,
  StyledSecondaryText,
  UserAddressBox,
  UserAddressWrapper,
  RemoveAddressModalContainer,
  EditButton,
  RemoveLinkButton,
  AddressBoxHeader,
} from '../AddressBook.style'
import HTMLReactParser from 'html-react-parser'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { isEmpty } from 'lodash-es'
import clsx from 'clsx'
import { SubscriptionItem } from '@typesApp/subscription'
import { getShippingAddress } from '@views/Subscription/helpers/subscriptionHelpers'
import { SUBSCRIPTION_STATES } from '@views/Subscription/constants'

export interface IProps {
  address: Contact
  updateAddress: (address: Contact) => void
  setIsEditingAddress: (isEditingAddress: boolean) => void
  setAddressSelectedToEdit: (address: Contact) => void
  addressList?: Contact[]
  cardList?: Wallet[]
  subscriptionList?: SubscriptionItem[]
}

const UserAddress: FC<IProps> = ({
  address,
  updateAddress,
  setIsEditingAddress,
  setAddressSelectedToEdit,
  addressList,
  cardList = [] as Wallet[],
  subscriptionList = [] as SubscriptionItem[],
}) => {
  const [isRemoveAddressDialogOpen, setIsRemoveAddressDialogOpen] = useState<boolean>(false)
  const handleRemoveAddressOpen = () => setIsRemoveAddressDialogOpen(true)
  const handleRemoveAddressClose = () => setIsRemoveAddressDialogOpen(false)

  const getLinkedCards = contact => {
    return cardList?.filter(card => card.addressId == contact.addressId)
  }
  const getLinkedSubscriptions = () => {
    const linkedSubscriptions: SubscriptionItem[] = []
    subscriptionList
      .filter(subscription => subscription.state !== SUBSCRIPTION_STATES.Cancelled)
      .forEach(subscription => {
        const shippingAddress = getShippingAddress(subscription.subscriptionInfo.userData?.userDataField ?? [])
        if (shippingAddress.addressId === address.addressId) {
          linkedSubscriptions.push(subscription)
        }
      })

    return linkedSubscriptions
  }
  const linkedCards = getLinkedCards(address)
  const hasLinkedCards = !isEmpty(linkedCards)
  const linkedCardsString = linkedCards?.map(card => card.issuer + ' ' + card.protectedCCNumber).join(' & ')

  const linkedSubscriptions = getLinkedSubscriptions()
  const hasLinkedSubscriptions = !isEmpty(linkedSubscriptions)
  const linkedSubscriptionsString = linkedSubscriptions
    ?.map(subscription => subscription.subscriptionIdentifier.subscriptionId)
    .join(' & ')

  const handleEditAddressOpen = () => {
    setIsEditingAddress(true)
    setAddressSelectedToEdit(address)
  }

  const { t } = useTranslation()

  const changeDefaultAddress = newDefaultAddress => {
    addressList?.forEach(address => {
      if (address.primary === 'true' && isEmpty(getLinkedCards(address))) {
        updateAddress({ ...address, primary: 'false' })
      }
    })
    const defaultAddress = { ...newDefaultAddress, primary: 'true' }
    updateAddress(defaultAddress)
  }

  const userAddressArray: string[] = useMemo(() => {
    if (!address) {
      return []
    }

    const { addressLine = [], state, zipCode, city, country, phone1 } = address

    return [`${addressLine[0] || ''}, ${city || ''} ${state} ${zipCode || ''} ${country}`, phone1 || '']
  }, [address])

  return (
    <AddressBookContent key={address.nickName}>
      <Modal
        open={isRemoveAddressDialogOpen}
        onClose={handleRemoveAddressClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {
          <RemoveAddressModalContainer>
            <RemoveAddressDialog userAddress={address} handleClose={handleRemoveAddressClose} />
          </RemoveAddressModalContainer>
        }
      </Modal>
      <AddressBoxWrapper>
        <RemoveButtonWrapper>
          <AddressBoxHeader>
            <AddressCustomerName>{`${address?.firstName} ${address?.lastName}`}</AddressCustomerName>
            <span>{t(`AddressBook.Type.${address.addressType}`)}</span>
          </AddressBoxHeader>
          <AddressButtonWrapper className={clsx(hasLinkedCards && 'disabled')}>
            {(!hasLinkedCards && (
              <>
                <EditButton
                  filltype={'outline'}
                  data-element-id={address && 'X_X_AddressBook_Edit'}
                  onClick={handleEditAddressOpen}
                >
                  {t('AddressBook.EditButton')}
                </EditButton>

                {(!hasLinkedSubscriptions && (
                  <RemoveLinkButton
                    data-element-id={address && 'X_X_AddressBook_Remove'}
                    onClick={handleRemoveAddressOpen}
                  >
                    {t('AddressBook.RemoveButton')}
                  </RemoveLinkButton>
                )) || (
                  <StyledTooltip
                    contentVariant={{
                      type: 'textonly',
                      body: `${t('AddressBook.CannotBeChangedMsg', { itemName: t('PaymentMethodSelection.Labels.Subscription'), itemList: linkedSubscriptionsString })}`,
                    }}
                    colorVariant={'white'}
                    placement={'right'}
                  >
                    <span>
                      <SVGIcon library="validation" name="info" color={'#ff6700'} />
                    </span>
                  </StyledTooltip>
                )}
              </>
            )) || (
              <StyledTooltip
                contentVariant={{
                  type: 'textonly',
                  body: `${t('AddressBook.CannotBeChangedMsg', { itemName: t('PaymentMethodSelection.Labels.CreditCard'), itemList: linkedCardsString })}`,
                }}
                colorVariant={'white'}
                placement={'right'}
              >
                <span>
                  <SVGIcon library="validation" name="info" color={'#ff6700'} />
                </span>
              </StyledTooltip>
            )}
          </AddressButtonWrapper>
        </RemoveButtonWrapper>
        <AddressLineBreak />
        <UserAddressWrapper>
          {address && (
            <UserAddressBox>
              {userAddressArray.map(text => (
                <StyledSecondaryText key={text}>{HTMLReactParser(text)}</StyledSecondaryText>
              ))}
            </UserAddressBox>
          )}
        </UserAddressWrapper>
        {!hasLinkedCards && (
          <StyledFormControlLabel
            control={<StyledRadio />}
            label={
              address?.primary === 'true' ? t('AddressBook.DefaultAddressMsg') : t('AddressBook.SetAsDefaultAddressMsg')
            }
            checked={address?.primary === 'true'}
            onChange={() => changeDefaultAddress(address)}
          />
        )}
      </AddressBoxWrapper>
    </AddressBookContent>
  )
}

export default UserAddress
