//Standard libraries
import React, { FC, useEffect, useState } from 'react'
import Axios, { Canceler } from 'axios'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import getDisplayName from 'react-display-name'
//UI
import { Button, StyledAlert } from '../../components/UI'
import UserAddress from './components/UserAddress'
import AddressDialog from './components/AddressDialog'
//Types
import { Contact } from './../../types/user'
//Redux
import { userDetailsSelector } from '../../redux/selectors/user'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../redux/actions/user'
import { sendSuccessMessage } from '../../features/success/slice'
//Foundation libraries
import { sendMyAccountEvent } from '../../foundation/analytics/tealium/lib'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

import Log from '../../services/Log'
import personContactService from '../../foundation/apis/transaction/personContact.service'

//Styles
import {
  AddressBookTitle,
  AddAddressButtonContainer,
  AddressBookWrapper,
  UserAddressContainer,
} from './AddressBook.style'
import Snackbar from '@mui/material/Snackbar'
import { StyledAlertHeader, StyledAlertMessage } from '../../components/UI/Alert/styles'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import { ADDRESS_BILLING, ADDRESS_SHIPPING_BILLING } from '@constants/common'
import { useSite } from '@foundation/hooks/useSite'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { useStoredPaymentDetails } from '@hooks/useStoredPaymentDetails'
import { useGetUserSubscriptionsQuery, useUpdateSubscriptionAddressMutation } from '@features/subscription/query'
import { getShippingAddress } from '@views/Subscription/helpers/subscriptionHelpers'

const AddressBook: FC = () => {
  const widgetName = getDisplayName(AddressBook)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { country: storeCountry } = useStoreIdentity()
  const { mySite } = useSite()
  const userDetails = useSelector(userDetailsSelector)
  const contactList = userDetails?.contact || []
  const cardList = useStoredPaymentDetails(AddressBook)

  const [triggerUpdateSubscriptionAddress] = useUpdateSubscriptionAddressMutation()
  const { data: subscriptionData } = useGetUserSubscriptionsQuery({ storeId: mySite.storeID })
  const contactListFiltered = contactList.filter(
    contact =>
      contact.country.toLowerCase() === storeCountry.toLowerCase() &&
      contact.addressType.toLowerCase() !== ADDRESS_BILLING &&
      contact.addressType.toLowerCase() !== ADDRESS_SHIPPING_BILLING
  )

  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false)
  const [isEditingAddress, setIsEditingAddress] = useState(false)
  const [addressSelectedToEdit, setAddressSelectedToEdit] = useState<Contact | null>(null)

  const payloadBase = {
    widget: widgetName,
    storeId: mySite?.storeID,
    langId: mySite?.langId || mySite?.defaultLanguageID,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false)
  const [failureSnackBarOpen, setFailureSnackBarOpen] = useState(false)
  const { ...analyticsDataForMyAccount } = useAnalyticsData(ANALYTICS_PAGE_TYPE.PROFILE)

  const handleResponse = (response: boolean) => {
    response ? setSuccessSnackBarOpen(true) : setFailureSnackBarOpen(true)
  }
  const handleSuccessSnackBarClose = () => {
    setSuccessSnackBarOpen(false)
  }

  const handleFailureSnackBarClose = () => {
    setFailureSnackBarOpen(false)
  }

  const updateAddress = (contactInfo: Contact) => {
    personContactService
      .updatePersonContact({
        body: contactInfo,
        ...payloadBase,
      })
      .then(res => res.data)
      .then(addressData => {
        if (addressData.addressId) {
          dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION({ ...payloadBase }))
          const subscriptions = subscriptionData?.resultList ?? []
          const currentAddressId = contactInfo.addressId
          subscriptions.forEach(subscription => {
            const shippingAddress = getShippingAddress(subscription.subscriptionInfo.userData?.userDataField ?? [])
            if (shippingAddress.addressId === currentAddressId) {
              triggerUpdateSubscriptionAddress({
                storeId: mySite.storeID,
                subscriptionId: `${subscription.subscriptionIdentifier.subscriptionId}`,
                body: {
                  shippingAddressId: addressData.addressId,
                },
              })
            }
          })
          dispatch(
            sendSuccessMessage({
              key: 'success-message.EDIT_ADDRESS_SUCCESS',
              messageParameters: {
                '0': contactInfo.nickName,
              },
            })
          )
          handleResponse(true)
        }
      })
      .catch(e => {
        handleResponse(false)
        Log.error(`Could not update address: ${e}`)
      })
  }

  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsDataForMyAccount,
      Page_Section2: 'Profile',
      Page_Section3: 'AddressBook',
    })

    if (mySite) {
      dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION(payloadBase))
    }

    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [mySite])

  return (
    <AddressBookWrapper>
      <AddressBookTitle>{t('AddressBook.Title')}</AddressBookTitle>
      <UserAddressContainer>
        {contactListFiltered.map(c => (
          <UserAddress
            key={c.addressId}
            cardList={cardList}
            subscriptionList={subscriptionData?.resultList || []}
            address={c}
            addressList={contactListFiltered}
            updateAddress={updateAddress}
            setIsEditingAddress={setIsEditingAddress}
            setAddressSelectedToEdit={setAddressSelectedToEdit}
          />
        ))}
        {contactListFiltered.length == 0 && <div>{t('AddressBook.NoAddressFound')}</div>}
      </UserAddressContainer>
      {!isAddingNewAddress && (
        <AddAddressButtonContainer>
          <Button onClick={() => setIsAddingNewAddress(true)}>{t('AddressBook.AddrMsg')}</Button>
        </AddAddressButtonContainer>
      )}
      {isAddingNewAddress && (
        <AddressDialog
          formData={null}
          payloadBase={payloadBase}
          onEditEnd={() => setIsAddingNewAddress(false)}
          handleResponse={handleResponse}
        />
      )}
      {isEditingAddress && (
        <AddressDialog
          formData={addressSelectedToEdit}
          payloadBase={payloadBase}
          onEditEnd={() => setIsEditingAddress(false)}
          isEdit={true}
          updateAddress={updateAddress}
          handleResponse={handleResponse}
        />
      )}

      <Snackbar open={successSnackBarOpen}>
        <StyledAlert
          onClose={handleSuccessSnackBarClose}
          severity="success"
          icon={<SVGIcon library="validation" name="validate" />}
        >
          <StyledAlertHeader>{t('AddressBook.AddressSaveSuccessHeader')}</StyledAlertHeader>
          <StyledAlertMessage>{t('AddressBook.AddressSaveSuccessMsg')}</StyledAlertMessage>
        </StyledAlert>
      </Snackbar>
      <Snackbar open={failureSnackBarOpen}>
        <StyledAlert
          onClose={handleFailureSnackBarClose}
          severity="error"
          icon={<SVGIcon library="validation" name="validate" />}
        >
          <StyledAlertHeader>{t('AddressBook.AddressSaveFailureHeader')}</StyledAlertHeader>
          <StyledAlertMessage>{t('AddressBook.AddressSaveFailureMsg')}</StyledAlertMessage>
        </StyledAlert>
      </Snackbar>
    </AddressBookWrapper>
  )
}

export default AddressBook
