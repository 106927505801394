import styled from '@mui/material/styles/styled'
import { GridItem } from '../../components/UI'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

export const PaymentMethodWrapper = styled('div', {
  name: 'PaymentMethod',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
  rowGap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(16)}`,
  },
}))

export const PaymentMethodTitle = styled(Typography, {
  name: 'PaymentMethod',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('sm')]: {},
}))

export const PaymentMethodContent = styled(GridItem, {
  name: 'PaymentMethod',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {},
}))

export const PaymentMethodFormWrapper = styled('div', {
  name: ' PaymentMethodForm',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.light.primary,
  border: `1px solid ${theme.palette.background.light.tertiary}`,
  rowGap: theme.spacing(6),
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,

  'div[class*=Tooltip]': {
    justifyContent: 'end',
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
    minHeight: theme.spacing(79),
  },
}))

export const PaymentMethodLineBreak = styled('div', {
  name: ' PaymentMethodLine',
  slot: 'Break',
})(({ theme }) => ({
  height: 1,
  alignSelf: 'stretch',
  flexGrow: 0,
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
}))

export const PaymentMethodButtonWrapper = styled('div', {
  name: ' PaymentMethodButton',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: theme.spacing(15),
}))

export const RemoveLinkButton = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'bold',
  color: theme.palette.custom.blue,
  lineHeight: theme.spacing(10),
}))

export const PaymentMethodHeaderLabel = styled('span', {
  name: 'PaymentMethod',
  slot: 'HeaderLabel',
})(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.dark.primary,
  lineHeight: 1.5,
  fontWeight: 600,
}))

export const NoPaymentMethodBoxContainer = styled('div', {
  name: 'NoPaymentMethodBox',
  slot: 'Container',
})(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(6),
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(6),
  borderStyle: 'solid',
  borderColor: theme.palette.background.light.tertiary,
}))

export const PaymentMethodBoxContainer = styled('div', {
  name: 'PaymentMethodBox',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}))

export const PaymentMethodBoxBox = styled('div', {
  name: 'PaymentMethodBox',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  width: '100%',
  '> div': {
    display: 'flex',
    flexDirection: 'column',
    ':first-of-type': {
      [theme.breakpoints.up('md')]: {
        flex: ' 0 0 50%',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
}))

export const PaymentMethodBoxWrapper = styled('div', {
  name: 'PaymentMethodBox',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

export const RemoveButtonWrapper = styled('div', {
  name: 'NameButton',
  slot: 'Wrapper',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const AddPaymentMethodButtonContainer = styled('div', {
  name: 'AddPaymentMethod',
  slot: 'ButtonContainer',
})(({ theme }) => ({
  '& > button': {
    width: 197,
    fontSize: 14,
    padding: `${theme.spacing(4)}`,
    margin: 0,
  },
}))

export const StyledSecondaryText = styled('span', {
  name: 'StyledText',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.8,
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(1),
}))

export const RemovePaymentMethodModalContainer = styled(Box, {
  name: 'StyledBoxContainer',
  slot: 'Box',
})(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: '40%',
  [theme.breakpoints.up('sm')]: {
    left: '50%',
  },
}))

export const CreditCardDisplayWrapper = styled('div', {
  name: 'StyledCreditCardWrapper',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
}))

export const StyledAddress = styled('div', {
  name: 'StyledCreditCardWrapper',
  slot: 'Address',
})(({ theme }) => ({
  lineHeight: 1.2,
  margin: theme.spacing(2, 0),
}))

export const StyledErrorMessage = styled('p', {
  name: 'StyledCreditCardWrapper',
  slot: 'ErrorMessage',
})(({ theme }) => ({
  color: theme.palette.color.error,
  lineHeight: 1.2,
  margin: theme.spacing(2, 0),
}))
