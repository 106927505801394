import Axios from 'axios'

import { localStorageUtil, storageSessionHandler } from '@foundation/utils/storageUtil'
import { PERSONALIZATION_ID } from '@foundation/constants/user'
import { site } from '@foundation/constants/site'

async function getPersonalizationID(s: any): Promise<string> {
  const currentUserSession = storageSessionHandler.getCurrentUserAndLoadAccount()
  let personalizationID = currentUserSession
    ? currentUserSession?.personalizationID
    : localStorageUtil.get(PERSONALIZATION_ID) ?? ''

  if (!personalizationID) {
    try {
      const response = await Axios.get(`${site.transactionContextUrl}/store/${s.storeID}/usercontext/personalizationId`)
      personalizationID = response?.data?.personalizationID
    } catch (error) {
      console.error(
        'unable to get personalization id',
        { error },
        window.location.href,
        'Caller: useCustomerSegment/index.ts'
      )
    }
    localStorageUtil.set(`-${PERSONALIZATION_ID}`, personalizationID)
  }
  return personalizationID
}

/*
if (promoParam) {
  await callWebActivity(s, promoParam, personalizationID)
}
*/
// TODO: call espot customization api when promo is present in url search params
async function callWebActivity(s: any, espotName: string) {
  const url = `${site.transactionContextUrl}/store/${s.storeID}/espot/${espotName}`
  const personalizationID = await getPersonalizationID(s)
  return Axios.get(url, {
    headers: { WCPersonalization: personalizationID },
  }).catch(() => {
    console.error('unable to get web activity', window.location.href, 'useCustomerSegment/index.ts')
  })
}

export async function getCustomerSegments(s: any) {
  const url = `${site.transactionContextUrl}/store/${s.storeID}/customersegments`
  const personalizationID = await getPersonalizationID(s)
  return Axios.get(url, {
    headers: { WCPersonalization: personalizationID },
  }).catch(() => {
    console.error('unable to get customer segments', window.location.href, 'useCustomerSegment/index.ts')
  })
}
