import React, { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { OrderItem } from '@typesApp/order'
import { toNumber } from 'lodash-es'
import { useSelector } from 'react-redux'
import { getFormattedSubscriptionRecurrency, getTotalAdjustments } from '../../helpers/subscriptionHelpers'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import { CHECKOUT_STEPS } from '@constants/common'
import { useSite } from '@foundation/hooks/useSite'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import CurrencyService from '@services/CurrencyService'
import { orderItemsSelector } from '@features/order/selector'
import { useOrderDetails } from '@hooks/useOrderDetails'
import { useIsAccountPage } from '@hooks/useIsAccountPage'
import clsx from 'clsx'
import styles from './styles/CartSubscription.module.scss'

export const SubscriptionSummaryMessage: React.FC<{
  orderItem: OrderItem
  orderId: string
}> = props => {
  const { t: translate } = useTranslation()
  const { orderItem, orderId } = props
  const { value, interval } = getFormattedSubscriptionRecurrency(orderItem)
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const { activeStep } = useCheckoutSteps()
  const isConfirmationPage = CHECKOUT_STEPS.ORDER_CONFIRMATION === activeStep
  const { mySite } = useSite()
  const isAccountPage = useIsAccountPage()
  const orderOrCartItems =
    isAccountPage && !!orderId ? useOrderDetails(orderId).orderItem : useSelector(orderItemsSelector)

  const [subscriptionDiscount, setSubscriptionDiscount] = useState(
    getTotalAdjustments(orderItem, orderOrCartItems, true)
  )

  useEffect(() => {
    setSubscriptionDiscount(getTotalAdjustments(orderItem, orderOrCartItems, true))
  }, [orderItem, orderOrCartItems])

  const showSubscriptionDiscountSection = !!subscriptionDiscount

  return (
    <>
      {(isSubscriptionEnabled && !!value && (
        <>
          <div className={clsx(styles.cartActiveSubscriptionSection, styles.summarySection)}>
            <span>{translate('Subscriptions.Msgs.Active.Header')}</span>
            <span>
              {translate('Subscriptions.Msgs.Active.FrequencyMessage')}
              <span style={{ fontWeight: 600 }}>
                {translate(`Subscriptions.Msgs.${interval}`, { count: toNumber(value) })}
              </span>
            </span>
          </div>
          {showSubscriptionDiscountSection && (
            <>
              {(isConfirmationPage && (
                <div className={styles.clSubscribedMsg}>
                  {translate('Subscriptions.Msgs.Active.SavingMessage', {
                    savingsAmount: CurrencyService.getFormattedPrice(
                      mySite.locale,
                      mySite.defaultCurrencyID,
                      subscriptionDiscount
                    ),
                  })}
                </div>
              )) || (
                <div className={styles.subscriptionDiscountSection}>
                  <div>{translate('Subscriptions.Msgs.Active.DiscountMessage')}</div>
                  <div>
                    -{CurrencyService.getFormattedPrice(mySite.locale, mySite.defaultCurrencyID, subscriptionDiscount)}{' '}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )) ||
        null}
    </>
  )
}
