import React, { FC, useEffect, useState } from 'react'
import Axios, { Canceler } from 'axios'
import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import getDisplayName from 'react-display-name'

import Snackbar from '@mui/material/Snackbar'

import { Button, StyledAlert } from '../../components/UI'
import { CreditCardDialog } from './components/CreditCardDialog'
import { Card, SimplifiedCard } from '../../types/user'
import { FETCH_USER_WALLET_REQUESTED_ACTION } from '../../redux/actions/user'
import { sendMyAccountEvent } from '../../foundation/analytics/tealium/lib'

import {
  PaymentMethodTitle,
  AddPaymentMethodButtonContainer,
  PaymentMethodWrapper,
  PaymentMethodBoxContainer,
  NoPaymentMethodBoxContainer,
} from './PaymentMethod.style'
import { StyledAlertHeader, StyledAlertMessage } from '../../components/UI/Alert/styles'
import PaymentMethodBox from './components/PaymentMethodBox'
import personWalletService from '../../foundation/apis/transaction/person.wallet.service'
import Log from '../../services/Log'
import { useStoredPaymentDetails } from '../../hooks/useStoredPaymentDetails'
import { useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import { AlertColor } from '@mui/material'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { SUBSCRIPTION_STATUS_CODES, WALLET_SUBSCRIPTION_STATUS_FIELD } from '@views/Subscription/constants'
import { isEmpty } from 'lodash-es'

type SnackbarType = {
  name: string
  severity: AlertColor
  onclose?: () => void
}

const PaymentMethods: FC = () => {
  const cardList = useStoredPaymentDetails(PaymentMethods)
  const widgetName = getDisplayName(PaymentMethods)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [snackBar, setSnackBar] = useState<SnackbarType>({
    name: '',
    severity: 'success',
  })
  const [isAddingNewCreditCard, setIsAddingNewCreditCard] = useState(false)
  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const analyticsData = useAnalyticsData('wallet')

  const handleResponse = (response: boolean) => {
    setSnackBar({ name: response ? 'SaveSuccess' : 'FetchFailure', severity: response ? 'success' : 'error' })
  }

  const updateCreditCard = (card: Card) => {
    personWalletService
      .setCardAsDefault(card.id)
      .then(res => res.data)
      .then(cardData => {
        if (cardData.success === 'ok') {
          dispatch(FETCH_USER_WALLET_REQUESTED_ACTION(payloadBase))
          handleResponse && handleResponse(true)
        }
      })
      .catch(e => {
        handleResponse && handleResponse(false)
        Log.error('Could not set card as default: ' + e)
      })
  }

  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsData,
      Page_Section2: 'Profile',
      Page_Section3: 'PaymentMethod',
    })
    dispatch(FETCH_USER_WALLET_REQUESTED_ACTION(payloadBase))
  }, [])

  return (
    <PaymentMethodWrapper>
      <PaymentMethodTitle>{t('PaymentMethod.Title')}</PaymentMethodTitle>
      <>
        {cardList.length == 0 && (
          <NoPaymentMethodBoxContainer>
            {t('PaymentMethod.AddACard')}
            {!isAddingNewCreditCard && (
              <AddPaymentMethodButtonContainer>
                <Button onClick={() => setIsAddingNewCreditCard(true)}>{t('PaymentMethod.AddPaymentMethod')}</Button>
              </AddPaymentMethodButtonContainer>
            )}
          </NoPaymentMethodBoxContainer>
        )}
        {cardList.length > 0 && !isAddingNewCreditCard && (
          <AddPaymentMethodButtonContainer>
            <Button onClick={() => setIsAddingNewCreditCard(true)}>{t('PaymentMethod.AddPaymentMethod')}</Button>
          </AddPaymentMethodButtonContainer>
        )}
        <PaymentMethodBoxContainer>
          {cardList.map(c => {
            const activeSubscriptionList =
              (c.subscriptions &&
                c.subscriptions?.filter(
                  sub => sub[WALLET_SUBSCRIPTION_STATUS_FIELD] !== SUBSCRIPTION_STATUS_CODES.CANCELLED
                )) ||
              []

            const card = {
              id: '' + c.identifier,
              cardHolderName: c.owner,
              addressId: c.addressId,
              cardNumber: c.creditCardBin + c.creditCardPan,
              expiryDate: c.expireDate,
              isDefault: c.isDefault,
              hasSubscriptions: !isEmpty(activeSubscriptionList),
            }
            return (
              <PaymentMethodBox
                key={c.identifier + c.creditCardBin + c.creditCardPan}
                creditCard={card as SimplifiedCard}
                updateCreditCard={updateCreditCard}
              />
            )
          })}
        </PaymentMethodBoxContainer>
        {isAddingNewCreditCard && (
          <CreditCardDialog
            payloadBase={payloadBase}
            onEditEnd={() => setIsAddingNewCreditCard(false)}
            handleResponse={handleResponse}
          />
        )}
      </>
      <Snackbar open={!!snackBar.name}>
        <StyledAlert
          onClose={() => setSnackBar({ name: '', severity: snackBar.severity })}
          severity={snackBar.severity}
          icon={<SVGIcon library="validation" name="validate" />}
        >
          <StyledAlertHeader>{t(`PaymentMethod.PaymentMethod${snackBar.name}Header`)}</StyledAlertHeader>
          <StyledAlertMessage>{t(`PaymentMethod.PaymentMethod${snackBar.name}Msg`)}</StyledAlertMessage>
        </StyledAlert>
      </Snackbar>
    </PaymentMethodWrapper>
  )
}

export default PaymentMethods
