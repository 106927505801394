import styled from '@mui/material/styles/styled'

const StyledOrderRecapItemWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'RecapItemWrapper',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.custom.white,
  flexWrap: 'nowrap',
  gap: theme.spacing(0.625),
  border: `1px solid ${theme.palette.custom.alto}`,
  marginBottom: theme.spacing(4),
  '.item-name': {
    fontSize: theme.spacing(1),
    textTransform: 'uppercase',
  },
}))

const OrderRecapContentWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'ContentWrapper',
})(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
}))

const OrderRecapImageWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'ImageWrapper',
})(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    minWidth: '330px',
  },
}))

const OrderRecapInfoSectionWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'InfoSectionWrapper',
})(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  marginLeft: theme.spacing(2),
}))

const OrderRecapSection = styled('div', {
  name: 'OrderRecap',
  slot: 'Section',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  borderBottom: `2px solid ${theme.palette.custom.alto}`,
  width: '100%',
  fontSize: 14,
  '&:last-child': {
    borderBottom: 0,
    paddingBottom: 0,
  },
}))

const OrderRecapDateSection = styled('div', {
  name: 'OrderRecap',
  slot: 'DateSection',
})(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.custom.alto,
  alignItems: 'baseline',
  padding: theme.spacing(1, 2),
  fontSize: theme.typography.body2.fontSize,
  '>span:nth-of-type(2)': {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
}))

const OrderRecapPriceSection = styled(OrderRecapSection, {
  name: 'OrderRecap',
  slot: 'PriceSection',
})(() => ({
  justifyContent: 'space-between',
}))

const OrderRecapSectionItem = styled('div', {
  name: 'OrderRecap',
  slot: 'SectionItem',
})(() => ({
  display: 'flex',
  flex: '1 0 auto',
  alignItems: 'baseline',
}))

const ClOrderRecapSection = styled('div', {
  name: 'OrderRecap',
  slot: 'ClOrderRecapSection',
})(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  width: '100%',
}))

const OrderRecapSectionLabel = styled('span', {
  name: 'OrderRecap',
  slot: 'SectionLabel',
})(({ theme }) => ({
  fontSize: 'inherit',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  marginRight: theme.spacing(2),
}))

const OrderRecapPriceLabel = styled(OrderRecapSectionLabel, {
  name: 'OrderRecap',
  slot: 'PriceLabel',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
}))

const OrderRecapSectionValue = styled('span', {
  name: 'OrderRecap',
  slot: 'SectionValue',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.light.secondary,
  textAlign: 'right',
}))

const PriceWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'PriceWrapper',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 700,
}))

const OrderRecapClItemDisclaimer = styled('div', {
  name: 'OrderRecap',
  slot: 'ClItemDisclaimer',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  backgroundColor: theme.palette.background.dark.quaternary,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  alignItems: 'center',
}))

const OrderRecapClItemDisclaimerIconWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'ClItemDisclaimerIconWrapper',
})(() => ({
  alignSelf: 'flex-start',
}))

const OrderRecapClItemDisclaimerContentWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'ClItemDisclaimerContentWrapper',
})(() => ({
  '& > *': {
    lineHeight: '1',
  },
}))

const OrderRecapClItemDisclaimerSubtitle = styled('span', {
  name: 'OrderRecap',
  slot: 'ClItemDisclaimerSubtitle',
})(() => ({
  fontSize: 12,
}))

const OrderRecapClItemPerBoxWrapper = styled('span', {
  name: 'OrderRecap',
  slot: 'ClItemPerBoxWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: 12,
  '& > span': {
    marginRight: theme.spacing(1),
  },
}))

const OrderAdjustmentAmount = styled('span', {
  name: 'OrderRecap',
  slot: 'AdjustmentAmount',
})(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
}))

const OrderRecapItemStatus = styled('div', {
  name: 'OrderRecap',
  slot: 'ItemStatus',
})(({ theme }) => ({
  width: '100%',
  height: '40px',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  borderBottom: `2px solid ${theme.palette.custom.alto}`,
  span: {
    marginLeft: theme.spacing(2),
    ':nth-of-type(1)': {
      fontWeight: 700,
    },
  },
}))

const OrderRecapContent = styled('div', {
  name: 'OrderRecap',
  slot: 'Content',
  shouldForwardProp: propName => propName !== 'enforceFlexRow',
})<{ enforceFlexRow?: boolean }>(({ theme, enforceFlexRow }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: enforceFlexRow ? 'row' : 'column',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  '> div:first-of-type': {
    maxWidth: enforceFlexRow ? '330px' : 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'auto',
    },
  },
}))

export {
  OrderRecapImageWrapper,
  OrderRecapInfoSectionWrapper,
  OrderRecapContent,
  OrderRecapItemStatus,
  OrderRecapSection,
  OrderRecapDateSection,
  OrderRecapPriceSection,
  StyledOrderRecapItemWrapper,
  OrderRecapSectionLabel,
  OrderRecapPriceLabel,
  OrderRecapSectionValue,
  OrderRecapSectionItem,
  ClOrderRecapSection,
  PriceWrapper,
  OrderRecapClItemDisclaimer,
  OrderRecapContentWrapper,
  OrderRecapClItemDisclaimerSubtitle,
  OrderRecapClItemDisclaimerIconWrapper,
  OrderRecapClItemDisclaimerContentWrapper,
  OrderRecapClItemPerBoxWrapper,
  OrderAdjustmentAmount,
}
