import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { StyledAnchor, StyledButtonAsLink } from '../../UI'

import styled from '@mui/material/styles/styled'

// TODO: need to migrate to styled MUI and refactoring ProductTile component

export const StyledColorVariantsButtons = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}))

export const StyledSuggestedProductSubWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductSubWrapper',
})(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {
    img: {
      maxHeight: theme.spacing(37.5),
    },
  },
  '&::before': {
    content: '""',
    paddingTop: '50%',

    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}))

export const StyledSuggestedProductAnchor = styled(StyledAnchor)(({}) => ({
  justifyContent: 'center',
  '&::after': {
    height: '0 !important',
  },
}))

export const StyledSquare = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledSquare',
})(({ theme }) => ({
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export const StyledSuggestedProductTileWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductTileWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: '0.75rem',
  fontWeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',

    '&:hover': {
      '& > div': {
        visibility: 'visible',
      },
    },
  },

  '&.arn-product-tile-ymal': {
    position: 'relative',
    aspectRatio: '1/1',

    '@supports not (aspect-ratio: 1 / 1)': {
      minHeight: 356,
    },
  },
}))

export const StyledSuggestedProductTileHeader = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductTileHeader',
})(({}) => ({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledSuggestedProductTilePopularLabel = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductTilePopularLabel',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.spacing(3.5),
  fontWeight: 600,
  lineHeight: 1.43,
  alignItems: 'center',
}))

export const StyledSuggestedProductTilePopularLabel2 = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductTilePopularLabel2',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'normal',
}))

export const StyledSuggestedProductTileAttributes = styled('div')(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,
  [theme.breakpoints.up('sm')]: {
    textAlign: 'center',
  },
}))

export const StyledSuggestedProductTileBadgeIcons = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > svg:not(:last-child)': {
    [theme.breakpoints.up('sm')]: {
      marginRight: '0.625rem',
    },
    marginRight: 0,
  },

  svg: {
    color: theme.palette.custom.black,
  },
}))

export const StyledSuggestedProductTileImageContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledLoadingWrapper',
})(({}) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
}))

export const StyledColorVariantsBtn = styled(StyledButtonAsLink)({
  fontSize: 0,
  '&:disabled': {
    opacity: 0.5,
  },
})

export const StyledColorVariantName = styled('div')(({ theme }) => ({
  flex: 1,
  fontSize: '1rem',
  color: theme.palette.text.primary,
  textAlign: 'center',
}))

export const StyledColorVariantsSlide = styled(Slide)``

export const StyledColorVariantsProvider = styled(CarouselProvider)``

export const StyledColorVariantsSlider = styled(Slider)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const StyledSuggestedProductTileFooterWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  position: 'relative',
}))

export const StyledSuggestedProductTileFooter = styled('div')(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  padding: `${theme.spacing(3)} 0 0 0`,

  [theme.breakpoints.up('sm')]: {
    '& > div': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      flex: 0,
    },
  },
}))

export const StyledSuggestedProductTileDescription = styled('div', {
  shouldForwardProp: prop => prop !== 'isPDP',
})<{ isPDP?: boolean | null }>(({ theme, isPDP }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: 0,
  alignItems: isPDP ? 'center' : 'flex-start',
  textAlign: 'center',
}))

export const StyledSuggestedProductName = styled('div', {
  name: 'SuggestedProductTile',
  slot: 'ProductName',
  shouldForwardProp: prop => prop !== 'productType',
})<{
  productType: string | null
}>(({ productType, theme }) => ({
  alignSelf: 'center',
  fontSize: theme.typography.body2.fontSize,
  lineHeight: productType !== 'contact-lenses' ? 1.6 : 1.33,
  textTransform: 'capitalize',
}))

export const StyledSuggestedProductBrandName = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 600,
  lineHeight: '1rem',
}))

export const StyledSuggestedProductVariants = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductVariants',
})(({ theme }) => ({
  width: '100%',
  height: 100,
  marginBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  textAlign: 'center',
}))

export const StyledLoadingWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledLoadingWrapper',
})(({}) => ({
  flex: 1,
  display: 'flex',
  aspectRatio: '1 / 1',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StyledSkeletonWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledSkeletonWrapper',
})(() => ({
  flex: 0,
  minHeight: 100,
  width: '100%',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const StyledImageWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'StyledImageWrapper',
  shouldForwardProp: prop => prop !== 'shouldScaleOnHover',
})<{ shouldScaleOnHover?: boolean | null }>(({ shouldScaleOnHover }) => ({
  overflow: 'hidden',
  position: 'relative',
  float: 'left',
  display: 'inline-block',

  div: {
    height: '100%',
    transition: 'transform .5s',
  },

  '&:hover div, &:focus div': {
    transform: shouldScaleOnHover ? 'scale(1.2)' : 'none',
  },
}))
