import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'next-i18next'
import { OrderItem } from '@typesApp/order'
import { toNumber } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFormattedSubscriptionRecurrency,
  getDescriptionFromAdjustment,
  getDescriptionFromMarketingSpot,
  isSubscriptionAllowedForItem,
} from '../../helpers/subscriptionHelpers'
import { SubscriptionInfo, SubscriptionRecurrency } from '@typesApp/subscription'
import {
  useDeleteSubscriptionInfoMutation,
  useGetSubscriptionsTermsAndConditionsQuery,
  useUpdateSubscriptionInfoMutation,
} from '@features/subscription/query'
import { useSite } from '@foundation/hooks/useSite'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { CartPayload } from '@typesApp/cart'
import { useLazyGetCartQuery } from '@features/order/query'
import { RecurrencySelect } from './RecurrencySelect'
import { SUBSCRIPTION_DISCOUNT_PERCENT_STRING, SUBSCRIPTION_RECURRENCY_STRING } from '@views/Subscription/constants'
import { PreLoader } from '@components/UI'
import { getInsuranceEventModule } from '@components/DirectBilling'
import { SET_SUBSCRIPTION_CONFIG_ACTION } from '@redux/actions/subscription'
import clsx from 'clsx'
import styles from './styles/CartSubscription.module.scss'
import { StyledFormControlLabel, StyledSwitch } from '@components/UI'
import styled from '@mui/material/styles/styled'

const SubscriptionSwitch = styled(StyledSwitch)(({ theme }) => ({
  margin: theme.spacing(3),
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.custom.green,
      },
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.custom.white,
  },
  '& .MuiSwitch-switchBase:hover + .MuiSwitch-track': {
    backgroundColor: theme.palette.custom.light1.grey,
  },
  '& .MuiSwitch-switchBase:hover.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.custom.green,
  },
  '& .MuiSwitch-switchBase:focus + .MuiSwitch-track': {
    backgroundColor: theme.palette.custom.green,
  },
}))

export const ItemSubscriptionHeader: React.FC<{
  orderItem: OrderItem
  payloadBase?: CartPayload
}> = ({ orderItem, payloadBase }) => {
  const { mySite } = useSite()
  const { t } = useTranslation()
  const timestampRef = useRef(Date.now()).current
  const [getCart] = useLazyGetCartQuery()
  const [itemSubscriptionInfo, setItemSubscriptionInfo] = useState<SubscriptionInfo | null>(null)
  const [isSubscriptionActive, setSubscriptionActive] = useState(
    !!getFormattedSubscriptionRecurrency(orderItem, false).interval
  )
  const [updateSubscriptionInfo] = useUpdateSubscriptionInfoMutation()
  const [deleteSubscriptionInfo] = useDeleteSubscriptionInfoMutation()
  const subscriptionConfig = useSelector(subscriptionConfigSelector)
  const showSubscriptionSection = isSubscriptionAllowedForItem(orderItem, mySite, subscriptionConfig)
  const [isChangingSubscription, setChangingSubscription] = useState(false)
  const { data: eSpotData } = useGetSubscriptionsTermsAndConditionsQuery({ storeId: mySite.storeID })
  const subscriptionTermsAndCondition = getDescriptionFromMarketingSpot(eSpotData)
  const { interceptApplySubscription } = getInsuranceEventModule()
  const dispatch = useDispatch()
  const onSubscriptionValueChange = (active: boolean, interval?: string) => {
    const { SEPARATOR, VALUE_INDEX, RECURRENCY_INDEX } = SUBSCRIPTION_RECURRENCY_STRING
    const recurrency: SubscriptionRecurrency = interval
      ? {
          value: toNumber(interval.split(SEPARATOR)[VALUE_INDEX]),
          interval: interval.split(SEPARATOR)[RECURRENCY_INDEX],
        }
      : getFormattedSubscriptionRecurrency(orderItem, true)

    const updatedInfo = {
      identifier: orderItem.orderItemId,
      active: active,
      recurrency: recurrency,
    }

    if (updatedInfo.active) {
      interceptApplySubscription(() => {
        setSubscriptionActive(false)
        updateOrDeleteSubscription(updatedInfo)
      })
    } else {
      updateOrDeleteSubscription(updatedInfo)
    }
  }

  /**
   * The BE API can now receive only one item and automatically update the grouped items with the same information
   * @param item The left or right eye item to be updated (both eyes will be affected regardless of the selection)
   */
  const updateOrDeleteSubscription = async (item: SubscriptionInfo) => {
    setChangingSubscription(true)
    if (item.active) {
      await updateSubscriptionInfo({
        storeId: mySite.storeID,
        RecurringFrequency: `${item.recurrency?.value}|${item.recurrency?.interval}`,
        orderItemId: item.identifier,
      })
    } else {
      await deleteSubscriptionInfo({
        storeId: mySite.storeID,
        orderItemId: item.identifier,
      })
    }

    dispatch(
      SET_SUBSCRIPTION_CONFIG_ACTION({
        item: {
          identifier: orderItem.partNumber,
          active: item.active,
          recurrency: item.recurrency,
        },
      })
    )

    await getCart({
      ...payloadBase,
      storeId: mySite.storeID,
      fetchCatentries: true,
      fetchShippingInfo: true,
      refetch: false,
      sessionId: timestampRef,
    })

    setChangingSubscription(false)
  }

  useEffect(() => {
    const subscriptionRecurrency = getFormattedSubscriptionRecurrency(orderItem, false)
    const isSubscriptionSelected = !!subscriptionRecurrency.interval
    setSubscriptionActive(isSubscriptionSelected)

    setItemSubscriptionInfo({
      identifier: orderItem.orderItemId,
      active: isSubscriptionSelected,
      recurrency: subscriptionRecurrency,
    })
  }, [orderItem])

  return (
    <>
      {showSubscriptionSection && (
        <>
          <div className={styles.cartActiveSubscriptionSection}>
            <div className={styles.switchContainer}>
              <SubscriptionSwitch
                data-element-id="Prods_Subscription"
                checked={isSubscriptionActive}
                setChecked={() => onSubscriptionValueChange(!itemSubscriptionInfo?.active)}
                label={
                  itemSubscriptionInfo?.active
                    ? t('Subscriptions.Msgs.Active.Header')
                    : t('Subscriptions.Msgs.Inactive.Header')
                }
              />
              {isChangingSubscription && <PreLoader fill={'dark'} />}
            </div>
            <div className={styles.recurrencyContainer}>
              {(itemSubscriptionInfo?.active && (
                <RecurrencySelect
                  defaultValue={`${itemSubscriptionInfo.recurrency?.value}|${itemSubscriptionInfo.recurrency?.interval}`}
                  onChange={value => onSubscriptionValueChange(itemSubscriptionInfo.active, value)}
                  customLabel={getDescriptionFromAdjustment(orderItem)}
                />
              )) || (
                <span>
                  {subscriptionTermsAndCondition ||
                    t('Subscriptions.Msgs.Inactive.Description', {
                      discountAmount: SUBSCRIPTION_DISCOUNT_PERCENT_STRING,
                    })}
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
