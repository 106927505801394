import Axios, { Canceler } from 'axios'
import {
  CHECKOUT_NAMES,
  CheckoutName,
  PAYMENT_METHODS,
  PAYMENT_STEPS,
  SECURE_PAYMENT_SUCCESS_CODES,
} from '../../../constants/paymentMethods'
import { CheckoutPayload, CreditCardFormDataType, PaymentInfoType, SecurePaymentData } from '../../../types/checkout'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { availablePaymentMethodsSelector, paymentMethodsSelector } from '../../../redux/selectors/site'
import { cartSelector, payMethodsSelector, selectedPayMethodsSelector } from '../../../features/order/selector'
import { useDispatch, useSelector } from 'react-redux'

import { ACCOUNT, IS_REORDER_SUCCESS, REORDER_BILLING_ADDRESS_ID } from '../../../foundation/constants/common'
import { BILLING_ADDRESS_ID } from '../../../constants/checkout'
import Log from '../../../services/Log'
import { PO_NUMBER } from '../../../constants/order'
import { canUseApplePay, getPaymentMethodByCheckoutName } from '../../../utils/payMethods'
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import { orderApi } from '../../../features/order/query'
import { setPaymentMethod } from '../../../features/order/slice'
import { getCheckoutPaths } from '../../../utils/routeUtils'
import { usePaymentCCardInfo } from './usePaymentCCardInfo'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { FETCH_USER_WALLET_REQUESTED_ACTION } from '../../../redux/actions/user'
import { useRouter } from 'next/navigation'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import { RESET_ERROR_ACTION } from '@redux/actions/error'

const ACCOUNT_FOR_VIEW_CC = 'accountForView'
const currentPaymentNumber = 0
const poNumber = ''
const CancelToken = Axios.CancelToken
const CREDITCARDFORMDATA_INIT: CreditCardFormDataType = {
  cc_account: '',
  expiration_date: '',
  cc_cvc: '',
  cc_nameoncard: '',
}
let cancels: Canceler[] = []

export const usePayment = webId => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const checkoutPaths = getCheckoutPaths(langCode)
  const { getUserPaymentCCardInfo } = usePaymentCCardInfo()
  const { completeCheckoutStep } = useCheckoutSteps()

  const cart = useSelector(cartSelector)
  const contractId = useSelector(currentContractIdSelector)
  const payMethods = useSelector(payMethodsSelector)
  const storeConfigPaymentMethods = useSelector(paymentMethodsSelector)
  const availablePaymentMethods = useSelector(availablePaymentMethodsSelector)
  const is3dsEnabled = false // TODO: Use useSelector(is3dsEnabledSelector)
  const selectedPaymentInfoList = useSelector(selectedPayMethodsSelector)
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false)
  const [securePaymentStatus, setSecurePaymentStatus] = useState<SecurePaymentData>({})
  const cartTotal = parseFloat(cart?.grandTotal)

  const [deleteAllPaymentInstructions] = orderApi.endpoints.deleteAllPaymentInstructions.useLazyQuery()
  const [addPaymentInstruction] = orderApi.endpoints.addPaymentInstruction.useLazyQuery()
  const [setupPayerAuthentication] = orderApi.endpoints.setupPayerAuthentication.useLazyQuery()

  const onSecurePaymentSetup = event => {
    try {
      let data = typeof event.data === 'object' ? event.data : JSON.parse(event.data)
      if (!!data && data.Status) {
        setSecurePaymentStatus({
          ...securePaymentStatus,
          step: PAYMENT_STEPS.ENROLLMENT,
        })
      }
    } catch (e) {}
  }

  const paymentMethodAdditionalCheck = useCallback(
    (checkoutPaymentMethodName: string) => {
      const paymentMethodName = getPaymentMethodByCheckoutName(checkoutPaymentMethodName as CheckoutName)
      if (paymentMethodName && !storeConfigPaymentMethods?.some(m => m === paymentMethodName)) {
        return false
      }

      switch (checkoutPaymentMethodName) {
        case PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY:
          return cartTotal !== 0 && canUseApplePay()
        case PAYMENT_METHODS.CHECKOUT_NAMES.PAYPAL:
          return cartTotal !== 0
        case 'Zero':
          return false
        default:
          return true
      }
    },
    [cartTotal, storeConfigPaymentMethods]
  )

  const sortedPaymentMethods = useMemo(() => {
    if (!availablePaymentMethods?.length) {
      return payMethods
    }

    return payMethods
      .map(paymentMethod => {
        const availablePaymentMethod = availablePaymentMethods.find(m => m.name === paymentMethod.paymentMethodName)

        return {
          ...paymentMethod,
          sequence: availablePaymentMethod?.sequence || 100,
        }
      })
      .filter(paymentMethod => {
        return paymentMethodAdditionalCheck(paymentMethod.paymentMethodName)
      })
      .sort((a, b) => a.sequence - b.sequence)
  }, [availablePaymentMethods, payMethods, paymentMethodAdditionalCheck])

  const isPONumberRequired = React.useMemo(() => cart?.x_isPurchaseOrderNumberRequired === 'true', [cart])
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const payloadBase: CheckoutPayload = {
    currency: defaultCurrencyID,
    contractId: contractId,
    storeId: mySite.storeID,
    responseFormat: 'application/json',
    widget: 'usePayment',
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
    webId: webId ? webId : undefined,
  }

  const updateOrderPaymentMethodList = useCallback((paymentInfoList: PaymentInfoType[]) => {
    dispatch(setPaymentMethod(paymentInfoList))
  }, [])

  /**
   * Handle pay option change
   * @param option The selected pay option
   */
  const togglePayOption = async (paymentMethodName: string, walletId?: string) => {
    const payment = sortedPaymentMethods.find(x => x.paymentMethodName === paymentMethodName)
    if (!payment) return

    const paymentTCId = payment?.paymentTermConditionId
    const creditCardFormData = Object.assign({}, CREDITCARDFORMDATA_INIT)

    if (paymentTCId && paymentTCId !== '' && payment.payMethodId !== PAYMENT_METHODS.CHECKOUT_NAMES.COD) {
      creditCardFormData.expiration_date = ''
      creditCardFormData.cc_holder_name = ''
      creditCardFormData.walletId = walletId
    }

    const newPaymentInfo: PaymentInfoType = {
      ...selectedPaymentInfoList[currentPaymentNumber],
      payMethodId: parseFloat(cart?.grandTotal) === 0 ? CHECKOUT_NAMES.ZERO : payment?.paymentMethodName,
      creditCardFormData,
      piAmount: cart?.grandTotal,
      paymentTermConditionId: paymentTCId || '',
      addressId:
        localStorageUtil.get(IS_REORDER_SUCCESS) === true
          ? localStorageUtil.get(REORDER_BILLING_ADDRESS_ID) ?? localStorageUtil.get(BILLING_ADDRESS_ID)
          : localStorageUtil.get(BILLING_ADDRESS_ID) || '',
      policyId: payment?.xumet_policyId,
      title: payment?.description,
    }

    const newPaymentInfoList: PaymentInfoType[] = selectedPaymentInfoList.slice()
    newPaymentInfoList.splice(currentPaymentNumber, 1, newPaymentInfo)

    updateOrderPaymentMethodList(newPaymentInfoList)
  }

  /**
   * Validate billing address id for a payment
   * @returns Whether or not the billing address is valid
   */
  const isValidBillingAddress = (paymentNumber: number): boolean => {
    if (paymentNumber >= selectedPaymentInfoList.length || selectedPaymentInfoList[paymentNumber].addressId === '') {
      return false
    }

    return true
  }

  /**
   * Validate billing address id list and payment options/credit card inputs for all payments
   * @returns Whether or not all payment selections is valid
   */
  const isValidPaymentList = () => {
    if (selectedPaymentInfoList.length < 1) {
      return false
    }

    for (let i = 0; i < selectedPaymentInfoList.length; i++) {
      if (!isValidBillingAddress(i)) {
        return false
      }
    }

    return true
  }

  /**
   * Validate whether po number is required and specified
   * @returns Whether po number is valid or needed
   */
  function isValidPO() {
    return !(isPONumberRequired && poNumber.trim() === '')
  }

  /**
   * Validate whether shopper can proceed to next step
   * @returns Whether next step is allowed
   */
  function canContinue() {
    return isValidPaymentList() && isValidPO()
  }

  /**
   * Submit the selected payment method and billing address information
   */
  const submit = async (formData: CreditCardFormDataType | null) => {
    if (!canContinue()) {
      return
    }

    const updatedPaymentInfoList = !!formData
      ? generatePaymentInfoList({
          ...formData,
          [ACCOUNT_FOR_VIEW_CC]: formData.cc_account,
        })
      : null

    if (isPONumberRequired && cart) {
      localStorageUtil.set(`${ACCOUNT}-${PO_NUMBER}-${cart.orderId}`, poNumber)
    }

    await deleteAllPaymentInstructions(payloadBase)

    const payMethodInfo = !!updatedPaymentInfoList
      ? getUserPaymentCCardInfo(updatedPaymentInfoList, cartTotal === 0, formData?.createWallet)
      : {}

    const payload: CheckoutPayload = {
      ...payloadBase,
      body: payMethodInfo,
    }

    dispatch(RESET_ERROR_ACTION())
    setPaymentLoading(true)

    try {
      await addPaymentInstruction(payload).catch(e => {
        throw e
      })
      dispatch(FETCH_USER_WALLET_REQUESTED_ACTION({ widget: payloadBase.widget || '' }))
      if (cartTotal === 0 || !is3dsEnabled) {
        completeCheckoutStep('payment')
        router.push(checkoutPaths['order-confirmation'])
      } else {
        const paSetupRest = await setupPayerAuthentication(payload).catch(e => {
          throw e
        })
        setSecurePaymentStatus({
          step: PAYMENT_STEPS.SETUP,
          data: paSetupRest.data,
          error:
            (paSetupRest.data?.reasonCode && !SECURE_PAYMENT_SUCCESS_CODES.includes(paSetupRest.data?.reasonCode)) ||
            paSetupRest.data?.decision === 'REJECT',
        })
      }
    } catch (e: any) {
      setPaymentLoading(false)
      Log.error('payment add error: ' + e.message, window.location.href)
    }
  }

  const generatePaymentInfoList = (formData: PaymentInfoType['creditCardFormData']): PaymentInfoType[] => {
    return selectedPaymentInfoList.map(paymentInfo => ({
      ...paymentInfo,
      creditCardFormData: formData,
      piAmount: cart.grandTotal,
    }))
  }

  useEffect(() => {
    const isZeroTotal = parseFloat(cart.grandTotal) === 0
    const updatedPaymentInfoList: PaymentInfoType[] =
      cart?.paymentInstruction?.length && sortedPaymentMethods.length
        ? cart.paymentInstruction.map(pi => {
            const payMethodId = pi.payMethodId as CheckoutName
            const paymentFromList = sortedPaymentMethods[0]

            const newPaymentInfo: PaymentInfoType = {
              payMethodId: isZeroTotal ? CHECKOUT_NAMES.ZERO : payMethodId,
              creditCardFormData: CREDITCARDFORMDATA_INIT,
              addressId: pi.billing_address_id,
              piAmount: cart.grandTotal,
              paymentTermConditionId: paymentFromList?.paymentTermConditionId,
              policyId: pi.xpaym_policyId,
              title: isZeroTotal ? CHECKOUT_NAMES.ZERO : paymentFromList?.description,
            }

            return newPaymentInfo
          })
        : generatePaymentInfoList(CREDITCARDFORMDATA_INIT)

    if (!selectedPaymentInfoList.length) {
      updateOrderPaymentMethodList(updatedPaymentInfoList)
    }
  }, [cart?.paymentInstruction, sortedPaymentMethods])

  return {
    paymentInfo: selectedPaymentInfoList[currentPaymentNumber],
    paymentLoading,
    paymentMethods: sortedPaymentMethods,
    isValidPO,
    togglePayOption,
    canContinue,
    submit,
    setPaymentLoading,
    onSecurePaymentSetup,
    securePaymentStatus,
    setSecurePaymentStatus,
  }
}
