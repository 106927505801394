import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import {
  ADDRESSLINE1,
  ADDRESSLINE2,
  ADDRESS_BILLING,
  ADDRESS_LINE,
  ADDRESS_SHIPPING,
  ADDRESS_SHIPPING_BILLING,
  APARTMENT_CHECK,
  BUZZER_CODE,
  EMPTY_STRING,
  NEW_SHIPPINNG_ADDRESS,
} from '../../../constants/common'
import Axios, { Canceler } from 'axios'
import {
  billingFormStatusSelector,
  cartSelector,
  isFetchingShippingInfoSelector,
  isShippingAsBillingSelector,
  orderItemsSelector,
  shipInfosSelector,
  shippingFormStatusSelector,
  subscriptionItemsCountSelector,
} from '../../../features/order/selector'
import { useDispatch, useSelector } from 'react-redux'
import { getCheckoutPaths } from '../../../utils/routeUtils'

import { AddressFormData } from '../../../types/form'
import { BILLING_ADDRESS_ID } from '../../../constants/checkout'
import Log from '../../../services/Log'
import { ORDER_CONFIGS } from '../../../configs/order'
import { SHIPMODE } from '../../../constants/order'
import Shipping from './Shipping'
import { CheckoutPayload, UsableAddress } from '../../../types/checkout'
import { VALIDATION_ERROR_ACTION } from '../../../redux/actions/error'
import addressUtil from '../../../utils/addressUtil'
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import getDisplayName from 'react-display-name'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'
import personContactService from '../../../foundation/apis/transaction/personContact.service'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import {
  isNewPasswordValidSelector,
  loginStatusSelector,
  pendingRegistrationStatusSelector,
  registrationStatusSelector,
  userDetailsSelector,
} from '../../../redux/selectors/user'
import { orderApi } from '../../../features/order/query'
import { flatMap } from 'lodash-es'
import { fetchShipInfo } from '../../../features/order/thunks'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../../redux/actions/user'
import { Contact, PasswordFormData } from '../../../types/user'
import { AddressLine } from '../../../types/common'
import { useRouter } from 'next/navigation'
import { useRegisterNewUser } from './components/CreatePassword/useRegisterNewUser'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { REORDER_BILLING_ADDRESS_ID } from '@foundation/constants/common'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'

export const useShipping = () => {
  const widgetName = getDisplayName(Shipping)
  const router = useRouter()
  const dispatch = useDispatch()
  const userDetails = useSelector(userDetailsSelector)
  const orderItems = useSelector(orderItemsSelector)
  const contractId = useSelector(currentContractIdSelector)
  const usableShipInfos = useSelector(shipInfosSelector)
  const isShippingUsedAsBilling = useSelector(isShippingAsBillingSelector)
  const [selectedShipAddressIds, setSelectedShipAddressIds] = useState<string[]>([])
  const [selectedShipModeIds, setSelectedShipModeIds] = useState<string[]>([])
  const { langCode } = useStoreIdentity()
  const cart = useSelector(cartSelector)
  const [addressId, setAddressId] = useState<string>()
  const { mySite } = useSite()
  const [newShippingAddress, setNewShippingAddress] = useState<boolean>(false)
  const [billingAddressData, setBillingAddressData] = useState<AddressFormData | null>(null)
  const { country: storeCountry } = useStoreIdentity()
  const registerUser = useRegisterNewUser()

  const contactListFiltered: Contact[] = useMemo(() => {
    return (userDetails?.contact || []).filter(
      contact =>
        contact.country?.toLowerCase() === storeCountry?.toLowerCase() &&
        contact.addressType?.toLowerCase() !== 'billing'
    )
  }, [userDetails?.contact])

  const [shippingAddressData, setShippingAddressData] = useState<AddressFormData>()
  const billingAddressStatus = useSelector(billingFormStatusSelector)
  const shippingAddressStatus = useSelector(shippingFormStatusSelector)
  const isFetchingShippingInfo = useSelector(isFetchingShippingInfoSelector) || false
  const isNewPasswordValid = useSelector(isNewPasswordValidSelector)
  const isLoggedIn = useSelector(loginStatusSelector)
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const subscriptionItemCount = useSelector(subscriptionItemsCountSelector)
  const [userHasSavedAddresses, setuserHasSavedAddresses] = useState<boolean>(false)
  const [shippingLoading, setShippingLoading] = useState<boolean>(false)
  const [addressChanging, setAddressChanging] = useState<boolean>(false)

  const [registrationTriggered, setRegistrationTriggered] = useState(false)
  const [hasSubscribedToNewsletter, setHasSubscribedToNewsletter] = useState(false)
  const isRegistrationSuccessful = useSelector(registrationStatusSelector)
  const isRegistrationPending = useSelector(pendingRegistrationStatusSelector)

  const { completeCheckoutStep } = useCheckoutSteps()

  //TODO this is temporary until the new API is able to give us the proper information

  const usableShipAddresses: UsableAddress[] = useMemo(() => {
    return contactListFiltered.map(contact => {
      return {
        addressId: contact.addressId,
        nickName: contact.nickName || '',
      }
    })
  }, [contactListFiltered])

  const [updateShippingInfos] = orderApi.endpoints.updateOrderShippingInfo.useMutation()

  const checkoutPaths = getCheckoutPaths(langCode)

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : EMPTY_STRING
  const payloadBase: CheckoutPayload = {
    currency: defaultCurrencyID,
    contractId: contractId,
    widget: widgetName,
    storeId: mySite.storeID,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  /**
   * ShipInfo request body base.
   */
  const shipInfoBody = {
    x_calculateOrder: ORDER_CONFIGS.calculateOrder,
    x_calculationUsage: ORDER_CONFIGS.calculationUsage,
    x_allocate: ORDER_CONFIGS.allocate,
    x_backorder: ORDER_CONFIGS.backOrder,
    x_remerge: ORDER_CONFIGS.remerge,
    x_check: ORDER_CONFIGS.check,
    orderId: '.',
  }

  /**
   * Sets addressId to local state and update the current order, so that available shipping
   * methods can be populated according to selected address.
   * @param index The order item index in order
   * @param addressId
   */
  const setOrderAddressId = async (index, addressId) => {
    let addressIds = [...selectedShipAddressIds]
    addressIds.splice(index, 1, addressId)
    const body = {
      ...shipInfoBody,
      addressId: addressIds[0],
    }
    const payload: CheckoutPayload = {
      ...payloadBase,
      body,
    }

    try {
      setAddressChanging(true)
      const updateShippingInfoResponse = await updateShippingInfos(payload)

      /* redirect back to cart if PUT /shipping_info returns an OOS error */
      if ((updateShippingInfoResponse as { error: any })?.error) {
        const { data, status } = (updateShippingInfoResponse as { error: any })?.error?.response
        if (
          status === 400 &&
          data?.errors?.find(error => error?.errorKey === '_CHECKOUT_UNDISPLAYED_OOS_ERROR_MESSAGE')
        ) {
          router.push(checkoutPaths.cart)
          throw (updateShippingInfoResponse as { error: any })?.error
        }
      }

      await paymentInstructionService
        .calculateTax({
          ...payloadBase,
        })
        .catch(e => {
          throw e
        })
      await dispatch(
        orderApi.endpoints.getCart.initiate({
          ...payloadBase,
          storeId: mySite.storeID,
          fetchCatentries: true,
          fetchShippingInfo: false,
          refetch: true,
          sessionId: Date.now(),
        })
      )
      setAddressChanging(false)
    } catch (e) {
      setAddressChanging(false)
    } finally {
      setAddressChanging(false)
    }
  }

  const isAddressSelected = () => {
    return selectedShipModeIds.length > 0 && selectedShipAddressIds.length > 0 && !newShippingAddress
  }

  function billingAddressIsValid() {
    return isShippingUsedAsBilling || (!isShippingUsedAsBilling && billingAddressStatus.isValid)
  }

  function shippingAddressIsValid() {
    return (newShippingAddress && shippingAddressStatus.isValid) || shippingAddressStatus.isValid
  }

  const isProceedButtonEnabled = () => {
    if (isAddressSelected()) {
      return billingAddressIsValid()
    } else {
      return billingAddressIsValid() && shippingAddressIsValid()
    }
  }

  /**
   * Check and verify the existing shipMode is changed by shopper.
   */
  function shipModeUpdated(): boolean {
    return !orderItems[0] || selectedShipModeIds[0] !== orderItems[0].shipModeId
  }

  /**
   * Submit the selected address or new address form
   */
  async function validateShipping() {
    setShippingLoading(true)
    const addressIds = orderItems.map(o => o.addressId)
    let isSingleShipModeId = true

    // if (!useMultipleShipment && orderItems?.length > 1) ...
    if (orderItems?.length > 1) {
      const addressIds = orderItems.map(o => o.addressId)
      const isSingleAddressId = addressIds.every((addressId, array) => addressId === array[0])
      if (!isSingleAddressId) {
        const body = {
          ...shipInfoBody,
          // addressId: {
          //   ...addressIds[0],
          //   addressType: isShippingUsedAsBilling ? ADDRESS_SHIPPING_BILLING : ADDRESS_SHIPPING,
          // },
          addressId: addressIds[0],
          addressType: isShippingUsedAsBilling ? ADDRESS_SHIPPING_BILLING : ADDRESS_SHIPPING,
        }
        const payload: CheckoutPayload = {
          ...payloadBase,
          body,
        }
        await updateShippingInfos(payload).catch(e => {
          throw e
        })
      }

      const shipModeIds = orderItems.map(o => o.shipModeId)
      isSingleShipModeId = shipModeIds.every((shipModeId, _, array) => shipModeId === array[0])
    }

    if (shipModeUpdated() || !isSingleShipModeId) {
      const body = {
        ...shipInfoBody,
        addressId: addressIds[0],
        addressType: isShippingUsedAsBilling ? ADDRESS_SHIPPING_BILLING : ADDRESS_SHIPPING,
        shipModeId: selectedShipModeIds[0],
        orderItem: [], //bypass defect HC-2784
      }
      const shipInfoPayload: CheckoutPayload = {
        ...payloadBase,
        body,
      }

      await updateShippingInfos(shipInfoPayload).catch(e => {
        throw e
      })
    }

    await finalizeShippingData().catch(e => {
      throw e
    })
  }

  const submit = async (
    shouldSubscribeToNewsletter: boolean,
    userForm?: AddressFormData | null,
    passwordForm?: PasswordFormData
  ) => {
    if (!isProceedButtonEnabled()) {
      return
    }
    if (isSubscriptionEnabled && subscriptionItemCount > 0 && !isLoggedIn && !isNewPasswordValid && !userForm) {
      return
    }

    try {
      validateShipping().then(() => {
        if (passwordForm && isSubscriptionEnabled && subscriptionItemCount > 0 && !isLoggedIn && isNewPasswordValid) {
          setRegistrationTriggered(true)
          setHasSubscribedToNewsletter(shouldSubscribeToNewsletter)
          registerUser(userForm, passwordForm)
          return
        }

        completeCheckoutStep('shipping')

        const paymentUrl = `${checkoutPaths.payment}?acceptedNewsletterFlag=${shouldSubscribeToNewsletter ? 1 : 0}`

        localStorage.setItem('acceptedNewsletterFlagUrl', paymentUrl)
        router.push(paymentUrl)
      })
    } catch (e: any) {
      setShippingLoading(false)
      Log.error('order shipping info error: ' + e.message, window.location.href)
      dispatch(
        VALIDATION_ERROR_ACTION({
          errorMessage: e.message,
        })
      )
    }
  }

  const updateAddress = async (addressFormData: AddressFormData, addressType: AddressFormData['addressType']) => {
    const formattedAddressData = formatAddressLines(addressFormData)
    try {
      // update address
      const personServiceResponse = await personContactService.updatePersonContact({
        body: formattedAddressData,
        ...payloadBase,
      })
      dispatch(fetchShipInfo({ orderId: cart.orderId }))
      dispatch(
        FETCH_USER_DETAILS_REQUESTED_ACTION({
          widget: getDisplayName(Shipping),
        })
      )
      const personServiceResData = personServiceResponse.data
      const updatedAddressId = personServiceResData?.addressId
      if (updatedAddressId && (addressType === ADDRESS_SHIPPING || addressType === ADDRESS_SHIPPING_BILLING)) {
        setAddressId(updatedAddressId)
        await setOrderAddressId(0, updatedAddressId)
      }

      if (updatedAddressId && (addressType === ADDRESS_BILLING || addressType === ADDRESS_SHIPPING_BILLING)) {
        localStorageUtil.set(BILLING_ADDRESS_ID, updatedAddressId)
        localStorageUtil.remove(REORDER_BILLING_ADDRESS_ID)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  /**
   * Create new shipping or billing address
   * @param addressFormData the form data object
   * @param addressType the type of address to create
   */
  const createAddress = async (addressFormData: AddressFormData, addressType: AddressFormData['addressType']) => {
    const formattedAddressData = formatAddressLines(addressFormData)
    formattedAddressData.addressType = addressType
    const personServiceResponse = await personContactService.addPersonContact({
      body: formattedAddressData,
      ...payloadBase,
    })
    dispatch(fetchShipInfo({ orderId: cart.orderId }))
    dispatch(
      FETCH_USER_DETAILS_REQUESTED_ACTION({
        widget: getDisplayName(Shipping),
      })
    )
    const personServiceResData = personServiceResponse.data
    const addedAddressId = personServiceResData?.addressId
    if (addedAddressId && (addressType === ADDRESS_SHIPPING || addressType === ADDRESS_SHIPPING_BILLING)) {
      await setOrderAddressId(0, addedAddressId)
    }

    if (addedAddressId && (addressType === ADDRESS_BILLING || addressType === ADDRESS_SHIPPING_BILLING)) {
      localStorageUtil.set(BILLING_ADDRESS_ID, addedAddressId)
      localStorageUtil.remove(REORDER_BILLING_ADDRESS_ID)
    }

    setNewShippingAddress(false)

    return personServiceResponse
  }

  const formatAddressLines = (addressFormData: AddressFormData): AddressFormData => {
    // Format the address data
    const newAddressData = addressUtil.removeLeadingTrailingWhiteSpace(addressFormData)
    newAddressData[ADDRESS_LINE] = [newAddressData[ADDRESSLINE1]]

    // Concat address line 2, buzzer code, and apartment check value with delimiter
    const newAddressLine2Data: AddressLine = {
      addressLine2: newAddressData[ADDRESSLINE2]?.trim() ?? '',
      buzzerCode: newAddressData[BUZZER_CODE]?.trim() ?? '',
      apartmentCheck: newAddressData[APARTMENT_CHECK],
    }
    newAddressData[ADDRESS_LINE].push(addressUtil.concatAddressWithDelimiter(newAddressLine2Data))

    delete newAddressData[ADDRESSLINE1]
    delete newAddressData[ADDRESSLINE2]

    return newAddressData as AddressFormData
  }

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedAddressId = event.target.value
    selectedAddressId && setAddressId(selectedAddressId)
  }

  const finalizeShippingData = async () => {
    if (newShippingAddress && isShippingUsedAsBilling) {
      await createAddress(shippingAddressData || {}, ADDRESS_SHIPPING_BILLING).catch(e => {
        throw e
      })
    } else if (newShippingAddress && !isShippingUsedAsBilling) {
      await createAddress(shippingAddressData || {}, ADDRESS_SHIPPING).catch(e => {
        throw e
      })

      await createAddress(billingAddressData || {}, ADDRESS_BILLING).catch(e => {
        throw e
      })
    } else if (!newShippingAddress && !isShippingUsedAsBilling) {
      await createAddress(billingAddressData || {}, ADDRESS_BILLING).catch(e => {
        throw e
      })
    } else {
      const billingAddressId = !!addressId ? addressId : selectedShipAddressIds[0]
      localStorageUtil.set(BILLING_ADDRESS_ID, billingAddressId)
      localStorageUtil.remove(REORDER_BILLING_ADDRESS_ID)
    }
    await paymentInstructionService.deleteAllPaymentInstructions().catch(e => {
      throw e
    })
    await paymentInstructionService
      .calculateTax({
        ...payloadBase,
      })
      .catch(e => {
        throw e
      })
  }

  useEffect(() => {
    localStorage.removeItem('acceptedNewsletterFlagUrl')

    if (orderItems.length > 0) {
      if (
        (selectedShipAddressIds.length === 0 || !selectedShipAddressIds[0]) &&
        usableShipAddresses &&
        usableShipAddresses.length > 0
      ) {
        const orderItemsAddressIds = flatMap(orderItems.map(o => o.addressId))
        setSelectedShipAddressIds(
          orderItemsAddressIds.filter(addressId => {
            return usableShipAddresses?.map(address => address.addressId).includes(addressId)
          })
        )
      }
      if (selectedShipModeIds.length === 0 || !selectedShipModeIds[0]) {
        setSelectedShipModeIds(
          orderItems
            .filter(i => i !== undefined && i.shipModeCode !== SHIPMODE.shipModeCode.PickUp)
            .map(o => o.shipModeId)
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usableShipAddresses, orderItems])

  useEffect(() => {
    const savedAddresses: boolean =
      !!usableShipAddresses && !!usableShipAddresses[0] && !!userDetails && !!userDetails.contact?.length
    setuserHasSavedAddresses(savedAddresses)
    setNewShippingAddress(!savedAddresses && !isAddressSelected())
  }, [usableShipAddresses])

  useEffect(() => {
    !!addressId && addressId !== EMPTY_STRING && addressId !== NEW_SHIPPINNG_ADDRESS && setOrderAddressId(0, addressId)
    userHasSavedAddresses && setNewShippingAddress(addressId === NEW_SHIPPINNG_ADDRESS)

    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [addressId])

  useEffect(() => {
    dispatch(fetchShipInfo({ orderId: cart.orderId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (registrationTriggered && !isRegistrationPending && !isRegistrationSuccessful) {
      setShippingLoading(false)
    }
    if (registrationTriggered && isRegistrationSuccessful) {
      setShippingLoading(false)

      completeCheckoutStep('shipping')

      const paymentUrl = `${checkoutPaths.payment}?acceptedNewsletterFlag=${hasSubscribedToNewsletter ? 1 : 0}`

      localStorage.setItem('acceptedNewsletterFlagUrl', paymentUrl)
      router.push(paymentUrl)
    }
  }, [
    checkoutPaths.payment,
    hasSubscribedToNewsletter,
    isRegistrationPending,
    isRegistrationSuccessful,
    registrationTriggered,
    router,
  ])

  return {
    usableShipAddresses,
    submit,
    newShippingAddress,
    addressId,
    setAddressId,
    updateAddress,
    selectedShipAddressIds,
    handleAddressChange,
    isShippingUsedAsBilling,
    billingAddressData,
    shippingAddressData,
    setBillingAddressData,
    setShippingAddressData,
    usableShipInfos,
    userHasSavedAddresses,
    isFetchingShippingInfo,
    shippingLoading,
    setShippingLoading,
    addressChanging,
    setSelectedShipModeIds,
  }
}
