import React, { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Modal } from '@mui/material'
import { RemoveCreditCardDialog } from '../../../components/RemoveCreditCardDialog'

import {
  PaymentMethodContent,
  PaymentMethodButtonWrapper,
  PaymentMethodHeaderLabel,
  PaymentMethodLineBreak,
  RemoveButtonWrapper,
  StyledSecondaryText,
  PaymentMethodBoxBox,
  PaymentMethodBoxWrapper,
  RemovePaymentMethodModalContainer,
  RemoveLinkButton,
  PaymentMethodFormWrapper,
  CreditCardDisplayWrapper,
  StyledErrorMessage,
  StyledAddress,
} from '../PaymentMethod.style'
import { CreditCardFormData } from '../../../types/form'
import { StyledFormControlLabel } from '../../../components/UI/Checkbox/StyledFormControlLabel'
import { StyledRadio } from '../../../components/UI/Checkbox/StyledRadio'
import { Contact, SimplifiedCard } from '../../../types/user'
import { CardProviderIcon } from '../../../components/UI/CreditCardField'
import { userDetailsSelector } from '@redux/selectors/user'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash-es'
import { findByCountryCode } from '@components/UI/PhoneNumberField/CountryCodeMap'
import DateService from '@services/DateService'
import { useSite } from '@foundation/hooks/useSite'
import { StyledTooltip } from '@components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface IProps {
  creditCard: SimplifiedCard
  updateCreditCard: (creditCard: CreditCardFormData) => void
}

/**
 * Helper function that converts address object to an array of address lines
 * @param address {Contact} address object
 * @returns array of formatted strings
 */
export function getAddressArray(address?: Contact): string[] {
  if (!address || isEmpty(address)) return []
  const { country, state, city, zipCode, addressLine } = address
  const countryObj = findByCountryCode(country)
  const addressLineStr = addressLine?.filter(item => !!item).join(' ') || ''
  return [addressLineStr, `${city}, ${state}, ${countryObj?.label || country} ${zipCode}`]
}

const PaymentMethodBox: FC<IProps> = ({ creditCard, updateCreditCard }) => {
  const [isRemoveCreditCardDialogOpen, setIsRemoveCreditCardDialogOpen] = useState<boolean>(false)
  const handleRemoveCreditCardOpen = () => setIsRemoveCreditCardDialogOpen(true)
  const handleRemoveCreditCardClose = () => setIsRemoveCreditCardDialogOpen(false)
  const userDetails = useSelector(userDetailsSelector)
  const { t: translate } = useTranslation()
  const contactList = userDetails?.contact ?? []
  const { mySite } = useSite()
  const billingAddress = contactList.find(cl => cl.addressId == creditCard['addressId'])

  return (
    <PaymentMethodContent key={creditCard.cardNumber}>
      <Modal
        open={isRemoveCreditCardDialogOpen}
        onClose={handleRemoveCreditCardClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RemovePaymentMethodModalContainer>
          <RemoveCreditCardDialog userCreditCard={creditCard} handleClose={handleRemoveCreditCardClose} />
        </RemovePaymentMethodModalContainer>
      </Modal>
      <PaymentMethodFormWrapper>
        {(!creditCard['hasSubscriptions'] && (
          <RemoveButtonWrapper>
            <StyledSecondaryText>{translate('PaymentMethod.CreditCard')}</StyledSecondaryText>
            <PaymentMethodButtonWrapper>
              <RemoveLinkButton
                data-element-id={creditCard && 'X_X_CreditCard_Remove'}
                onClick={handleRemoveCreditCardOpen}
              >
                {translate('PaymentMethod.RemoveButton')}
              </RemoveLinkButton>
            </PaymentMethodButtonWrapper>
          </RemoveButtonWrapper>
        )) || (
          <StyledTooltip
            contentVariant={{
              type: 'textonly',
              body: `${translate('RemoveCreditCardDialog.HasSubscriptionsWarning')}`,
            }}
            colorVariant={'white'}
            placement={'right'}
          >
            <span>
              <SVGIcon library="validation" name="info" color={'#ff6700'} />
            </span>
          </StyledTooltip>
        )}
        <PaymentMethodLineBreak />
        <PaymentMethodBoxWrapper>
          {creditCard && (
            <PaymentMethodBoxBox>
              <div>
                <PaymentMethodHeaderLabel>
                  <span>{creditCard?.cardHolderName}</span>
                </PaymentMethodHeaderLabel>
                <CreditCardDisplayWrapper>
                  <CardProviderIcon cardNumber={creditCard?.cardNumber} />
                  <StyledSecondaryText>
                    {translate('PaymentMethod.EndsWith')}
                    {creditCard?.cardNumber.substring(creditCard?.cardNumber.length - 4)}
                  </StyledSecondaryText>
                </CreditCardDisplayWrapper>
                <StyledSecondaryText>
                  {translate('PaymentMethod.ExpiresIn')}{' '}
                  {DateService.format(creditCard?.expiryDate, 'MM/yy', mySite.locale)}
                </StyledSecondaryText>
              </div>

              <div>
                <PaymentMethodHeaderLabel>{translate('PaymentMethod.BillingAddress')}</PaymentMethodHeaderLabel>

                {(!!billingAddress && (
                  <StyledAddress>
                    {getAddressArray(billingAddress)?.map(item => <div key={item}>{item}</div>)}
                  </StyledAddress>
                )) || <StyledErrorMessage>{translate('PaymentMethod.MissingAddress')}</StyledErrorMessage>}
              </div>
            </PaymentMethodBoxBox>
          )}
        </PaymentMethodBoxWrapper>
        {!!billingAddress && (
          <StyledFormControlLabel
            control={<StyledRadio />}
            label={
              creditCard.isDefault
                ? translate('PaymentMethod.DefaultCreditCardMsg')
                : translate('PaymentMethod.SetAsDefaultCreditCardMsg')
            }
            checked={creditCard.isDefault}
            onChange={() => {
              const defaultCreditCard = { ...creditCard, isDefault: true }
              updateCreditCard(defaultCreditCard)
            }}
          />
        )}
      </PaymentMethodFormWrapper>
    </PaymentMethodContent>
  )
}

export default PaymentMethodBox
