import React, { useCallback, useRef } from 'react'
import PrescriptionLenses from './PrescriptionLenses'
import { useDispatch, useSelector } from 'react-redux'
import { usePathname, useRouter } from 'next/navigation'
import { CART } from '../../constants/routes'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { startEditingCartItem } from '../../features/cartui/cartuiSlice'
import { useEditCartItemParams } from '../../hooks/useEditCartItemParams'
import { currentProductBundleSelector } from '../../features/product/selector'
import { useAddOrderItem } from '@views/ProductDetails/hooks/useAddOrderItem'
import { virtualMirrorUtils } from '@utils/virtualMirrorUtils'
import { setPrescriptionLensFormOpen } from '@features/rox/roxSlice'
import { RX_CONF_SELECTOR } from '@constants/common'

export const Prescription: React.FC<{
  setSelectLensesBusy?: React.Dispatch<boolean>
}> = ({ setSelectLensesBusy }) => {
  const { langCode } = useStoreIdentity()
  const router = useRouter()
  const pathname = usePathname()
  const dispatch = useDispatch()
  const { isEditLens } = useEditCartItemParams()

  const { product, orderItem } = useSelector(currentProductBundleSelector)

  const { handleAddToCartLenses } = useAddOrderItem(orderItem, product, product?.partNumber, ['1'])

  const hasAddedToCartRef = useRef(false)

  const addToBag = useCallback(
    async (_, lensObject, _reviewObject, ...args) => {
      // Prevent multiple cart additions

      if (hasAddedToCartRef.current) return

      hasAddedToCartRef.current = true

      try {
        if (isEditLens) {
          dispatch(startEditingCartItem())
        }
        await handleAddToCartLenses(_, lensObject, args)
        virtualMirrorUtils.unmountVirtualMirror()
      } catch (error) {
        // Reset the flag if there's an error
        hasAddedToCartRef.current = false
        console.error('Error adding to cart:', error)
      } finally {
        if (window.RXC) {
          dispatch(setPrescriptionLensFormOpen(false))
          window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
        }
      }
    },
    [isEditLens, handleAddToCartLenses, dispatch]
  )

  const onClose = () => {
    setSelectLensesBusy && setSelectLensesBusy(false)
    dispatch(setPrescriptionLensFormOpen(false))
    if (window.RXC) {
      window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
    }
    if (isEditLens) {
      router.push(`/${langCode}/${CART}`)
    } else {
      router.replace(pathname)
    }
  }

  if (!product) return null

  return (
    <PrescriptionLenses
      isFromCart={isEditLens}
      product={product}
      onClose={onClose}
      onAddToBag={addToBag}
      categoryHref={product?.seo?.href}
    />
  )
}
