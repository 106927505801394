import { Order } from '@typesApp/order'
import OrderService from '../../foundation/apis/transaction/order.service'
import Log from '@services/Log'
import { ORDER_ITEM_STATUS, ORDER_RETURN_STATUS, ORDER_STATUS } from '@constants/order'

export const downloadInvoice = async (storeId: string, orderId: string) => {
  try {
    // NOTE: opening a new window right after customer clicks to prevent browser popup blocking
    const invoiceWindow = window.open('', '_blank')
    // TODO: add static page to tell customer that their invoice is loading
    const file = await OrderService.requestInvoice({
      storeId,
      orderId,
    })
    const fileURL = URL.createObjectURL(file)
    if (invoiceWindow != null) {
      invoiceWindow.location.href = fileURL
    }
  } catch (e) {
    Log.error(`Failed to download invoice: ${e}`)
  }
}

/**
 * Check if order status indicates an invoice is available
 * @param order the order
 * @returns true if `order.orderStatus` is one of these values: `1, D, K, CAN, SNT, O, S, Z`
 */
export const isOrderInvoiceAvailable = (order: Order): boolean => {
  return [
    ORDER_STATUS.Delivered,
    ORDER_STATUS.Settled,
    ORDER_STATUS.ReturnAssociated,
    ORDER_RETURN_STATUS.ReturnCancelled,
    ORDER_RETURN_STATUS.ReturnDelivered,
    ORDER_ITEM_STATUS.Shipped_Billed,
    ORDER_ITEM_STATUS.Shipped_InTransit,
    ORDER_ITEM_STATUS.Shipped_FailedStatement,
  ].includes(order.orderStatus)
}
