import React from 'react'
import { useTranslation } from 'next-i18next'
import { CartRecapEDD } from '../CartRecap.style'
import ProductSummary from '../ProductSummary'
import { OrderRecapItemProps } from '@typesApp/order'
import { catentriesSelector } from '../../../../../features/order/selector'
import { useAppSelector } from '../../../../../hooks/redux'
import { CART_PRODUCT_IMAGE_WIDTH } from './constants'
import { addProductAttributesToCatentry } from '@utils/order'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { getInsuranceEventModule } from '@components/DirectBilling'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'

const CartRecapItem: React.FC<OrderRecapItemProps> = ({ orderItem, onDelete, soldOutStatus }) => {
  const { productId } = orderItem
  const { t } = useTranslation()
  const catentries = useAppSelector(catentriesSelector)

  const handleDelete = () => {
    const { interceptRemoveCartItem } = getInsuranceEventModule()
    interceptRemoveCartItem(() => onDelete(orderItem))
  }
  const estimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)

  const product = catentries?.[productId]
    ? addProductAttributesToCatentry(catentries?.[productId])
    : catentries?.[productId]
  return (
    <>
      {product && (
        <ProductContextProvider productData={{ product }}>
          <ProductSummary
            product={product}
            productQuantity={orderItem.quantity.split('')}
            soldOutStatus={soldOutStatus}
            topChildren={
              !!estimatedDeliveryDate && (
                <CartRecapEDD>
                  {t('CartRecap.Labels.EstimatedDeliveryDate')}
                  <span> &nbsp;{estimatedDeliveryDate}</span>
                </CartRecapEDD>
              )
            }
            orderItem={orderItem}
            onRemoveLabelClick={handleDelete}
            imageWidth={CART_PRODUCT_IMAGE_WIDTH}
          />
        </ProductContextProvider>
      )}
    </>
  )
}

export default CartRecapItem
