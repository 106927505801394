import React from 'react'

import { OrderItemWithRoxProps } from '../../types/order'
import { getLensReviewObject } from '@components/PrescriptionLenses/RxUtils'
import { isEmpty } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import styles from './styles/OrderRecapItem.module.scss'
import { getSite } from '@foundation/hooks/useSite'

export const OrderRecapRxItemLensDetails: React.FC<{
  orderRxItem: OrderItemWithRoxProps
}> = ({ orderRxItem }) => {
  const mySite = getSite()
  const lensesReview = getLensReviewObject(orderRxItem, mySite)

  return (
    (!isEmpty(lensesReview) && (
      <div className={styles.attributesListContainer}>
        {lensesReview.map((reviewItem, i) => {
          return (
            <div className={styles.attributesListItem} key={`${uuid()}_${lensesReview[reviewItem.key]}`}>
              <div> {reviewItem.key}</div>
              <div> {reviewItem.description}</div>
            </div>
          )
        })}
      </div>
    )) ||
    (!!orderRxItem.roxableAttributes && (
      <div className={styles.attributesList}>
        {Object.values(orderRxItem.roxableAttributes).map((value, i) => {
          return (
            <div className={styles.attributesListItem} key={`${i}_${value}`}>
              <span>{value}</span>
            </div>
          )
        })}
      </div>
    ))
  )
}
