import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { Box, Hidden, useMediaQuery } from '@mui/material'
import { PreLoader } from '../../../../../components/UI'
import {
  CartRecapBottom,
  CartRecapData,
  CartRecapDataContent,
  CartRecapItemWrapper,
  CartRecapTop,
  CartRecapImageContainer,
  CartRecapEDD,
  CartRecapContentContainer,
  CartRecapModelName,
  CartRecapRightColumn,
  StyledCartRecapDivider,
  CartRecapModelPriceDataRow,
  CartRecapTotalDataRow,
  CartRecapRxInitialTotalPrice,
  CartRecapDataRowProductModel,
  StyledRemoveButtonContainer,
  StyledRemoveButtonAsLink,
  CartRecapBrand,
  TotalWrapperUnit,
} from '../CartRecap.style'
import {
  ContactLensPricePerBoxContainer,
  ContactLensQuantityRecap,
  ContactLensQuantitySelectLabel,
  ContactLensQuantitySelectWrapper,
  ContactLensQuantitySelectContainer,
} from './ContactLensDetails.style'
import { getCLBrand, getModelCode, getModelName } from '../../../../../utils/productAttributes'
import { OrderRecapItemProps } from '@typesApp/order'
import ProductImage from '../../../../../components/ProductImage/ProductImage'
import { catentriesSelector, orderItemsSelector } from '../../../../../features/order/selector'
import theme from '../../../../../themes'
import { useAppSelector } from '../../../../../hooks/redux'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import FormattedPriceDisplay from '../../../../../components/formatted-price-display'
import {
  formatOrderRecapItemPrices,
  isClAccessoriesOrderItem,
  generateQuantityOptions,
} from '../../../../../utils/order'
import useBreakpoints from '../../../../../hooks/useBreakpoints'
import { removingCartItemSelector, removeCartItemIdSelector } from '../../../../../features/cartui/cartuiSlice'
import { CART_PRODUCT_IMAGE_WIDTH } from './constants'
import { useRouter } from 'next/router'
import CurrencyService from '@services/CurrencyService'
import { getTotalAdjustments, isItemSubscribed } from '@views/Subscription/helpers/subscriptionHelpers'
import { useSite } from '@foundation/hooks/useSite'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { usePathname } from 'next/navigation'
import { isReOrderSummary } from '@utils/routeUtils'
import { ItemSubscriptionHeader } from '@views/Subscription/cart/components/ItemSubscriptionHeader'
import { ContactLensesSelect } from '@views/ProductDetails/components/ContactLensesSelect'
import { getInsuranceEventModule } from '@components/DirectBilling/utils/insuranceModule'
import styles from './styles/CartRecapCLAccessoriesItem.module.scss'
import { MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY } from '@constants/order'

type CLAccessoryWrapperProps = {
  isOutOfStock: boolean
  children: React.ReactNode
}

const ClAccessoryOutOfStockWrapper = ({ isOutOfStock, children }: CLAccessoryWrapperProps) => (
  <div
    className={clsx({
      [styles.cartRecapOutOfStockWrapper]: !!isOutOfStock,
    })}
  >
    {children}
  </div>
)

const CartRecapClItem: React.FC<OrderRecapItemProps> = ({
  orderItem,
  onDelete,
  onItemUpdated,
  outOfStockCLAccessories,
  liveStockCLAccessories,
}) => {
  const { productId } = orderItem
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()

  const availableQuantity = liveStockCLAccessories
    ? Number(liveStockCLAccessories.find(item => item.UPC === orderItem.partNumber)?.Available)
    : MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY
  const maxQuantity = Math.min(availableQuantity, MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY) + 1
  const totalBoxes = parseInt(orderItem?.quantity)

  const quantityOptions = generateQuantityOptions(
    isClAccessoriesOrderItem(orderItem.attributes) ? Math.max(totalBoxes + 1, maxQuantity) : maxQuantity,
    1,
    availableQuantity
  )
  const router = useRouter()
  const { basePath } = useStoreIdentity()
  const pathname = usePathname()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const catentries = useAppSelector(catentriesSelector)

  const isRemovingCartItem = useSelector(removingCartItemSelector)
  const removeOrderItemId = useSelector(removeCartItemIdSelector)

  const [isRemoveDisabled, setIsRemoveDisabled] = useState<boolean>(false)

  const productBrandName = getCLBrand(orderItem) || 'BRAND NAME'
  const productModelName = getModelName(orderItem)
  const productBaseModelCode = getModelCode(orderItem)
  const { productOrderItemPrice, productUnitPrice } = formatOrderRecapItemPrices(orderItem)
  const { mySite } = useSite()
  const attachments = catentries?.[productId] ? catentries[productId].attachments ?? [] : []
  const estimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)
  const { href } = orderItem.seo || { href: '' }
  const isRemovingCurrentItem = isRemovingCartItem && removeOrderItemId === orderItem?.orderItemId
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const orderItems = useSelector(orderItemsSelector)
  const subscriptionDiscount = getTotalAdjustments(orderItem, orderItems, true)
  const showSubscriptionDiscountSection = isSubscriptionEnabled && isItemSubscribed(orderItem) && !!subscriptionDiscount

  const product = catentries && catentries[productId]

  const discountAdjustments = Number(orderItem.totalAdjustment?.value)
  const discountedPrice = Math.max(
    Number(productOrderItemPrice) + (discountAdjustments < 0 ? discountAdjustments : 0),
    0
  )
  const isTotalDiscounted = discountedPrice > 0 && Number(productOrderItemPrice) > discountedPrice

  const isOutOfStock = !!(product?.partNumber && outOfStockCLAccessories?.includes(product?.partNumber))

  const onImageClicked = () => {
    const url = `${basePath}${href}`
    router.push(url)
  }

  const handleDelete = () => {
    const { interceptRemoveCartItem } = getInsuranceEventModule()
    interceptRemoveCartItem(() => {
      onDelete(orderItem)
      setIsRemoveDisabled(true)
    })
  }

  const handleUpdate = (quantity: string, orderItemId: string) => {
    const { interceptChangeQuantity } = getInsuranceEventModule()
    interceptChangeQuantity(() => {
      onItemUpdated && onItemUpdated(quantity, orderItemId)
    })
  }

  const showError = totalBoxes > availableQuantity && !isOutOfStock

  return (
    <CartRecapItemWrapper>
      <ClAccessoryOutOfStockWrapper isOutOfStock={isOutOfStock}>
        {!!estimatedDeliveryDate && (
          <CartRecapEDD>
            {t('CartRecap.Labels.EstimatedDeliveryDate')}
            <span> &nbsp;{estimatedDeliveryDate}</span>
          </CartRecapEDD>
        )}
        {isSubscriptionEnabled && !isReOrderSummary(pathname) && <ItemSubscriptionHeader orderItem={orderItem} />}
      </ClAccessoryOutOfStockWrapper>

      <CartRecapContentContainer>
        <CartRecapImageContainer>
          <Box>
            {isMobile && (
              <StyledRemoveButtonContainer>
                <StyledRemoveButtonAsLink
                  data-element-id="X_X_Prods_"
                  onClick={handleDelete}
                  disabled={isRemoveDisabled || isRemovingCurrentItem}
                >
                  {isRemovingCurrentItem && <PreLoader fill={'dark'} withButton />}
                  {t('CartRecap.Actions.Delete')}
                </StyledRemoveButtonAsLink>
              </StyledRemoveButtonContainer>
            )}

            <ClAccessoryOutOfStockWrapper isOutOfStock={isOutOfStock}>
              <ProductImage
                attachments={attachments}
                data-element-id="X_X_Prods_ProdLink"
                width={CART_PRODUCT_IMAGE_WIDTH}
                usage={'PDP'}
                onClick={onImageClicked}
              />
            </ClAccessoryOutOfStockWrapper>
          </Box>
        </CartRecapImageContainer>
        <CartRecapRightColumn>
          <CartRecapData>
            <Hidden smDown>
              <CartRecapBottom>
                <StyledRemoveButtonAsLink
                  data-element-id="X_X_Prods_Remove"
                  onClick={handleDelete}
                  disabled={isRemoveDisabled || isRemovingCurrentItem}
                >
                  {isRemovingCurrentItem && <PreLoader fill={'dark'} withButton />}
                  {t('CartRecap.Actions.Delete')}
                </StyledRemoveButtonAsLink>
              </CartRecapBottom>
            </Hidden>
            <ClAccessoryOutOfStockWrapper isOutOfStock={isOutOfStock}>
              <CartRecapTop>
                {!isMobile && <CartRecapBrand>{productBrandName}</CartRecapBrand>}
                <CartRecapModelName>{productModelName}</CartRecapModelName>
              </CartRecapTop>
              <CartRecapDataContent isClAccessories>
                {orderItem && (
                  <>
                    <ContactLensQuantitySelectContainer>
                      <StyledCartRecapDivider></StyledCartRecapDivider>
                      <ContactLensQuantitySelectWrapper>
                        <ContactLensQuantitySelectLabel>{t('CartRecap.Labels.Boxes')}</ContactLensQuantitySelectLabel>
                        <ContactLensesSelect
                          id={'quantity'}
                          eye={'default'}
                          error={showError}
                          value={`${totalBoxes}`}
                          onSelectValueChange={(_eye, _id, val) => {
                            handleUpdate(val || '', orderItem.orderItemId || '')
                          }}
                          key={'cl-acc-quantity'}
                          options={quantityOptions}
                        />
                      </ContactLensQuantitySelectWrapper>
                      {productUnitPrice && (
                        <ContactLensPricePerBoxContainer>
                          <span>{isDesktop ? t('CartRecap.Labels.PerBox') : t('CartRecap.Labels.PricePerBox')}</span>
                          <FormattedPriceDisplay min={productUnitPrice} currency={orderItem.currency} />
                        </ContactLensPricePerBoxContainer>
                      )}
                    </ContactLensQuantitySelectContainer>
                    {showError && (
                      <span className={clsx(styles.cartRecapQuantityError)}>
                        {t('CartRecap.Labels.UpdateQuantity')}
                      </span>
                    )}
                  </>
                )}

                {showSubscriptionDiscountSection && (
                  <>
                    <div className={styles.clSubscribedMsg}>
                      {t('Subscriptions.Msgs.Active.SavingMessage', {
                        savingsAmount: CurrencyService.getFormattedPrice(
                          mySite.locale,
                          mySite.defaultCurrencyID,
                          subscriptionDiscount
                        ),
                      })}
                    </div>
                    <StyledCartRecapDivider />
                  </>
                )}
                <CartRecapModelPriceDataRow>
                  <CartRecapDataRowProductModel>{productBaseModelCode}</CartRecapDataRowProductModel>
                </CartRecapModelPriceDataRow>
                <CartRecapTotalDataRow uppercase>
                  <div>
                    <span> {t(['CartRecap.Labels.Total', 'TOTAL'])} </span>{' '}
                    <ContactLensQuantityRecap>
                      {!!totalBoxes && t('CartRecap.Labels.BoxCount', { count: totalBoxes })}
                    </ContactLensQuantityRecap>
                  </div>
                  <TotalWrapperUnit>
                    {isTotalDiscounted && !!productOrderItemPrice && (
                      <CartRecapRxInitialTotalPrice>
                        <FormattedPriceDisplay min={productOrderItemPrice} currency={orderItem.currency} />
                      </CartRecapRxInitialTotalPrice>
                    )}
                    {!!productOrderItemPrice && (
                      <FormattedPriceDisplay min={discountedPrice} currency={orderItem.currency} />
                    )}
                  </TotalWrapperUnit>
                </CartRecapTotalDataRow>
              </CartRecapDataContent>
            </ClAccessoryOutOfStockWrapper>
          </CartRecapData>
        </CartRecapRightColumn>
      </CartRecapContentContainer>
    </CartRecapItemWrapper>
  )
}

export default CartRecapClItem
